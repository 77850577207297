import React from "react";

import { Dialog, styled, Typography} from "@material-ui/core"
import { SuccessModalProps } from "./types";
import { checkMark } from "../../blocks/accountgroups/src/assets";
import { I18n } from "./languageJson/i18n" 
import { getValueBasedOnLanguage } from "./constant";


const SuccessModal : React.FC<SuccessModalProps> = ({ open, handleClose, msg_ar, msg_en}) => {

    const messageInfo = localStorage.getItem("GET_SELECTED_LANGUAGE") === "ar" ? msg_ar : msg_en 

    return (
        <SuccessDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
        >
            <div>
                <div style={{ textAlign: 'center' }}>
                    <img src={checkMark} alt="check_mark" />
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px', }}>
                    <Typography className="setEmpText">{messageInfo}</Typography>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleClose} className="viewEmpButton">{I18n.t("cancel")}</button>
                </div>
            </div>
        </SuccessDialog>

    )
}

const SuccessDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none'
    }
  },
  '& .setEmpText':{
    fontWeight:700,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  },
  '& .viewEmpButton': {
    padding: '10px 200px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  }
});


export default SuccessModal

