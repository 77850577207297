// Customizable Area Start
import React from "react";
import AppNavigation from "../../../components/src/AppNavigation.web";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { I18n } from "../../../components/src/languageJson/i18n";
import { Box, styled, Typography, Card,Button, Grid, InputAdornment, OutlinedInput } from "@material-ui/core"
import { grayDepartMentIcon, redDepartMentIcon } from "./assets";
import { InputField, TextAreaField, getValueBasedOnLanguage, StyledFormControl,SelectMain, MenuItemSet, getCommonMenuProps, CommonBoxStyles } from "../../../components/src/constant";
import { InputTypesFields } from "../../../components/src/types";
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import CustomDropdownIcon from "../../../components/src/CustomDropdownIcon";



import EditDepartmentController, {
    Props,
} from "./EditDepartmentController";


class EditDepartment extends EditDepartmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderErrorMsg = (messageText : string) => {
      return (
          <Typography
              style={{
                  textAlign: this.setStylesBasedOnLanguage("right", "left"),
                  marginTop: "2px", fontSize: "14px", color: "#DC2626", 
                  fontFamily: this.setStylesBasedOnLanguage("Noto Sans Arabic", "Poppins")
              }}
          >
              {messageText}
          </Typography>
      )
    }

  renderDynamicInputFields = (inputFields : InputTypesFields) => {
    const { name, value, errorMsg, errorKey, placeholder } = inputFields
      return (
          <Box>
              <InputField
                  data-test-id={name}
                  fullWidth
                  variant="outlined"
                  placeholder={placeholder}
                  value={value}
                  name={name}
                  error={errorKey}
                  onChange={this.handleEditDepartmentChange}
                  style={{
                      direction: this.setStylesBasedOnLanguage("rtl", "ltr"),
                      textAlign: this.setStylesBasedOnLanguage("right", "left"),
                  }}
                  InputProps={{
                      startAdornment: <InputAdornment position="start">
                          <img
                              src={errorKey ? redDepartMentIcon : grayDepartMentIcon}
                              alt="dep_icon"
                          />

                      </InputAdornment>,
                  }}
              />
              {errorKey && this.renderErrorMsg(errorMsg)}
          </Box>
      )
  }

    renderDynamicTextAreaFields = (fields: InputTypesFields) => {
        const { name, placeholder, value, errorKey, errorMsg } = fields
        return (
            <Box>
                <TextAreaField
                    data-test-id={name}
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={4}
                    onChange={this.handleEditDepartmentChange}
                    value={value}
                    name={name}
                    error={errorKey}
                    style={{
                        textAlign: this.setStylesBasedOnLanguage("right", "left"),
                        direction: this.setStylesBasedOnLanguage("rtl", "ltr"),
                    }}
                />
                {errorKey && this.renderErrorMsg(errorMsg)}
            </Box>
        )
    }

    renderDynamicSelectField = () => {
        return (
            <StyledFormControl variant="outlined" fullWidth
            >
                <SelectMain
                    style={{ borderRadius: '10px', fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")}}
                    MenuProps={getCommonMenuProps}
                    data-test-id="parent_department"
                    name={"parentDepartmentId"}
                    value={this.state.parentDepartmentId}
                    onChange={this.handleSelectDepartmentChange}
                    displayEmpty
                    IconComponent={CustomDropdownIcon}
                    startAdornment={
                        <InputAdornment position="start">
                            <img
                                src={grayDepartMentIcon}
                                alt="dep_icon"
                            />

                        </InputAdornment>
                    }
                    renderValue={(value) => {
                        const selectedParentDepartment = this.state.optionsArray.find((option) => option.id === value);
                        return (
                            <Box
                                style={{
                                    color: selectedParentDepartment ? 'currentColor' : '#94A3B8',
                                    ...CommonBoxStyles
                                }}
                            >
                                {selectedParentDepartment ? selectedParentDepartment.name : I18n.t('parentDepartment')}
                            </Box>
                        );
                    }}
                    input={<OutlinedInput />}
                >
                    {this.state.optionsArray.map((optionValue, index) => (
                        <MenuItemSet
                           className="setMenuItem"
                            style={{
                                borderBottom: '1px solid #E2E8F0',
                                fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                            }}
                            key={index}
                            value={optionValue.id}
                        >
                            {optionValue.name}
                        </MenuItemSet>
                    ))}
                </SelectMain>
            </StyledFormControl>
        )
    }

  render() {
    // Customizable Area Start
    
    return (
      <div style={{display:'flex',  flexDirection: this.setStylesBasedOnLanguage('row-reverse', "row")}}>
        <AppNavigation />
         <EditDepartmentWrapper className={this.setStylesBasedOnLanguage("arabic-text", "default-text")}
            style={{
                marginRight:this.setStylesBasedOnLanguage("325px", undefined)
              }}
         >
                 {/* BreadCrumbs */}
                <Box style={{ paddingRight: "20px", display: 'flex', flexDirection: this.setStylesBasedOnLanguage("row-reverse", "row") }}>
                    <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("organizationManagement"), I18n.t("Edit Department")]} navigation={this.props.navigation} pathToNavigate="OrganisationHierarchy" />
                </Box>

                {/* Edit Department Form */}
                <Box style={{ maxWidth: "100vw", display: "flex", flexDirection: "column", gap: "1.5rem", justifyContent: "center", padding: "1.5rem 1.5rem 1.5rem 0" }}>
                    <Card style={{
                        maxWidth: "100vw",
                        borderRadius: this.setStylesBasedOnLanguage("0 24px 24px 0", "24px 0 0 24px"),
                        gap: "1.5rem",
                        justifyContent: "center",
                        padding: "1.5rem 1.5rem",
                        flexDirection: "column",
                        display: "flex",
                        border: "solid 1px #E2E8F0",
                    }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                color: "#1a1a1a",
                                marginBottom: "16px",
                                fontWeight: 600,
                                fontSize: "20px",
                                alignSelf: this.setStylesBasedOnLanguage("flex-end", "flex-start"),
                                textAlign: this.setStylesBasedOnLanguage("right", "left"),
                                fontFamily: this.setStylesBasedOnLanguage("Noto Sans Arabic", "Poppins")

                            }}
                        >
                            {I18n.t("Edit Department")}
                        </Typography>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderDynamicInputFields({
                                name: "department_name_en",
                                value: this.state.editDepartmentFormFields.department_name_en,
                                errorKey: this.state.editDepartmentErrorFields.department_name_en,
                                placeholder: I18n.t("Department name (EN)*"),
                                errorMsg: I18n.t("Please enter a department name in english")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderDynamicInputFields({
                                name: "department_name_ar",
                                value: this.state.editDepartmentFormFields.department_name_ar,
                                errorKey: this.state.editDepartmentErrorFields.department_name_ar,
                                placeholder: I18n.t("Department name (AR)*"),
                                errorMsg: I18n.t("Please enter a department name in arabic")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderDynamicTextAreaFields({
                                name: "department_desc_en",
                                value: this.state.editDepartmentFormFields.department_desc_en,
                                errorKey: this.state.editDepartmentErrorFields.department_desc_en,
                                placeholder: I18n.t("Description (EN)*"),
                                errorMsg:  I18n.t("Please enter department description in english")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}   >
                            {this.renderDynamicTextAreaFields({
                                name: "department_desc_ar",
                                value: this.state.editDepartmentFormFields.department_desc_ar,
                                errorKey: this.state.editDepartmentErrorFields.department_desc_ar,
                                placeholder: I18n.t("Description (AR)*"),
                                errorMsg: I18n.t("Please enter department description in arabic")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}   >
                            {this.renderDynamicSelectField()}
                        </Grid>
                    </Card>
                </Box>

                {/* Edit Button Section */}
                <Box style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
                    <EditButton data-test-id="editBtn" onClick={this.updateDepartmentInfo}> {I18n.t("Save")}</EditButton>
                </Box>

                 {/* Success Modal */}
                 <SuccessModal 
                    open={this.state.successUpdateModal}
                    handleClose={this.closeSuccessUpdateModal}
                    msg_en={this.state.msg_update_en}
                    msg_ar={this.state.msg_update_ar}
                    data-test-id="successModal"
                />

                {/* Error Modal */}
                <ErrorModal 
                    data-test-id="errorModal"
                    open={this.state.errorUpdateModal}
                    handleClose={this.closeErrorUpdatedModal}
                />
         </EditDepartmentWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default EditDepartment;

const EditDepartmentWrapper = styled(Box)({
    flex:1, 
    padding:'50px 0 50px 20px',
    minHeight: "calc(100vh - 100px)",
    backgroundColor:"#F1F5FC", 
    marginRight: getValueBasedOnLanguage("325px", 0),
})

const EditButton = styled(Button)({
    color: "white",
    borderRadius: "8px",
    maxWidth: "306px",
    textTransform: "none",
    width: "306px",
    fontWeight: 700,
    padding: "10px 16px 10px 16px",
    fontSize: "16px",
    backgroundColor: "#527FE2",
    lineHeight: "24px",
    fontFamily: "Poppins",
    height: "44px",
    "&:hover": {
        backgroundColor: "#527FE2",
        color: "white",
    },
    "@media(max-width:600px)": {
        padding: "10px 16px 10px 16px",
        fontSize: "18px",
    },
});

// Customizable Area End