import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Platform,
} from "react-native";

import { styled } from "@material-ui/styles";
import { Box, Typography, Card, Button, TextField, Grid, InputAdornment, IconButton, TextareaAutosize, Modal, createTheme,
  ThemeProvider, OutlinedInput } from "@material-ui/core";
import {branch, card, vat, home, email, taxino, auth, authId,deleteimg, iban, time,branchCodeIcon, categories, location, ophours, attatchfile,addIcon,checked,unchecked,cancleRoundRed,checkedRound, topBackImg, ProfileImg} from "./assets";
import { Autocomplete } from "@material-ui/lab";
import PhoneInput from "react-phone-number-input";
import AppNavigation from '../../../components/src/AppNavigation.web';
import BranchOperatingHoursModal from '../../../components/src/BranchOperatingHoursModal.web'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { I18n } from "../../../components/src/languageJson/i18n";
import { getValueBasedOnLanguage as arabicCSSStyles, SelectMain,getCommonMenuProps, StyledFormControl, CommonBoxStyles, MenuItemSet } from "../../../components/src/constant"
import CustomDropdownIcon from "../../../components/src/CustomDropdownIcon";
import { branchRedIcon, catogoryRedIcon, branch as branchNormalIcon, categories as catogoryNormalIcon } from "../../../blocks/customform/src/assets";

const BranchTYpe: Dropdown[] = [
  { label: I18n.t("multipleBranch"), value: "2", str_key : "multiple_branches" },
  { label: I18n.t("individualBranch"), value: "0", str_key : "individual_branch" },  
];
// Customizable Area End

import UserProfileBasicController, {
  Props,
  Dropdown,
} from "./UserProfileBasicController.web";
import { Close } from "@material-ui/icons";

export default class UserProfileBasicBlock extends UserProfileBasicController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({

    border: `1px solid ${borderColor || "#CBD5E1"}`,
    borderRadius: 8,
    height: 56,
    zIndex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: this.getValueBasedOnLanguage('0', '10px'), 
    "& input": {
      border: "none",
    },
    "& input:focus": {
      border: "none",
      outline: "none",
    },
    "& .PhoneInputCountry": {
      display: "flex",
      alignItems: "center",
      gap: this.getValueBasedOnLanguage('8px', '0'),
      paddingLeft: this.getValueBasedOnLanguage('10px', '0'), 
    },
    "& .PhoneInputInput": {
      color: "#334155",
      fontSize: "16px",
      fontWeight: 400,
      background: "transparent",
      lineHeight: "24px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    "& input:focus-visible": {
      border: "none",
      outline: "none",
    },
    "& input::placeholder": {
      color: "#94A3B8", opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171",
    },
  
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px", borderRadius: "8px",
    },
    "&:focus-within": {
      borderColor: "#527FE2",
    },
    '&:hover': {
      borderColor: "#527FE2",
  },
   
  
  }));

  renderAddBranchBox() {
    if (this.state.branchSelection === "multiple_branches") {
      return (
        <Box style={{...webStyle.branchBox, flexDirection: this.getValueBasedOnLanguage("row-reverse", "row")}} data-test-id="addfield" onClick={this.addBranch}>
          <Typography style={webStyle.addBranch}  
          >
          {I18n.t('addBranch')}
          </Typography>
          <img src={addIcon} alt="addIcon" style={webStyle.addBranchIcon} />
        </Box>
      );
    }
    return null;
  }

  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  };  

  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? trueStmt : falseStmt;
  }

  getOptionLabelFunction = (option: any) => {
  return option.attributes?.name || "";
};

  selectedCategoryName = this.state.selectedCategory?.attributes?.name;
  shouldShowPhoneNumberLabel = !this.state.phoneNumber;

  theme = createTheme({
    typography: {
      fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
    },
  
    overrides: {
      MuiInputBase: {
        root: {
          fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
        },
      },
      MuiTextField: {
        root: {
          fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
        },
      },
    }
  });

  renderCoverImage = () => {
    if (this.state.coverImgSrc) {
      return (
        <img
          src={this.state.coverImgSrc}
          alt="Cover Image"
          style={{ height: "318px", width: "100%", objectFit: "cover" }}
        />
      );
    }
  
    return (
      <div
        style={{
          height: "318px",
          width: "100%",
          background: "#f0f0f0 center/20px url('https://i.gifer.com/ZKZg.gif') no-repeat",
        }}
      />
    );
  };

  renderProfileImage = () => {
    if (this.state.profileImgSrc) {
      return (
        <ImageProfile
          src={this.state.profileImgSrc}
          alt="profile"  
          onClick={this.handleFileProfileick4}
        />
      );
    }
  
    return (
      <ImageProfile
        style={{
          background: "#f0f0f0 center/20px url('https://i.gifer.com/ZKZg.gif') no-repeat",
        }}
      />
    );
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    // const { navigation } = this.props;
    return (
      //Required for all blocks
      <ThemeProvider theme={this.theme}>
        <div style={{display:'flex', background : "#F1F5FC", flexDirection: this.getValueBasedOnLanguage('row-reverse', "row")}}>
          <AppNavigation />
          <div className={this.getValueBasedOnLanguage("arabic-text", "default-text")} 
            style={{flex:1, overflow: 'hidden', marginRight:this.getValueBasedOnLanguage("325px", undefined)}}
          >
            <ToastContainer />
          <Box style={{ maxWidth: "100vw", minHeight: "1250px", background: "#F1F5FC" }}>
            <Box style={{ maxHeight: "318px", width: "100%", overflow: "hidden" }}>
              {this.renderCoverImage()}
            </Box>
            <EditBtn
              style={{
                right: this.getValueBasedOnLanguage('320px', '0px'),
                fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
              }}
              onClick={this.handleFileCoverClick}>{I18n.t("editCover")}</EditBtn>
            <input
              type="file"
              data-test-id="coverimg"
              ref={this.state.uploadCover}
              style={{ display: 'none' }}
              accept=".jpeg, .jpg, .png"
              onChange={this.handleFileCoverPhoto}
            />

            <Card style={webStyle.cardStle}>
              <Box style={{...webStyle.imageBOx, paddingRight:this.getValueBasedOnLanguage('0','1.5rem'), direction: this.getValueBasedOnLanguage('rtl', 'ltr')}}>
                {this.renderProfileImage()}
                <input
                  type="file"
                  data-test-id="profile"
                  ref={this.state.uplodaProfile}
                  style={{ display: 'none' }}
                  accept=".jpeg, .jpg, .png"
                  onChange={this.handleFileChangeProfile4}
                />
                <Box >
                  <ProfileName style={{fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} >{this.state.profileData.attributes?.business_name}</ProfileName>
                  <ProfileCatText style={{fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{this.state.selectedCategory?.attributes?.name}</ProfileCatText>
                </Box>
              </Box>


              <Box
                id="modal-title"
                mb="20px"
                mt="20px"
                fontFamily="Poppins"
                fontWeight="bolder"
              >
                <Typography style={{
                          ...webStyle.formHeading,
                          direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
                          fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                        }}>{I18n.t("generalInfo")}</Typography>
              </Box>

              <form
                onSubmit={this.handleSubmit}
                noValidate autoComplete="off">
                  
                <Grid container spacing={3} direction={this.getValueBasedOnLanguage("row-reverse", "row")}
                >
                  <Grid item sm={6} xs={12} md={6}  >
                    <InputField
                      data-test-id="NationalId"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="National Id *"
                      value={this.state.profileData.attributes?.national_id}
                      onChange={(event) => {
                        const value = event.target.value;
                        const NospaceValue = value?.replace(/\s/g, '');
                        this.onValueChange("national_id", NospaceValue);
                      }}
                      InputProps={{
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(null, (
                          <InputAdornment position="start">
                            <img src={card} alt="icon" style={{
                              width: 20,
                              height: 16,
                            }} />
                          </InputAdornment>
                        )),
                        endAdornment: this.getValueBasedOnLanguage((
                          <InputAdornment position="start" style={{
                            marginRight:'0',
                              marginLeft:'8px'
                          }}>
                            <img src={card} alt="icon" style={{ 
                              width: 20,
                              height: 16,
                            }} />
                          </InputAdornment>
                        ), null),

                      }}
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.nationalError("NationalId")}
                      </span>
                    
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="VatRegNo"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Vat Registration Number *"
                      value={this.state.profileData.attributes?.vat_registration_number}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        const NospaceValue = value?.replace(/\s/g, '');
                        this.onValueChange("vat_registration_number", NospaceValue);
                      }}

                      InputProps={{
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(null, (
                          <InputAdornment position="start">
                            <img src={vat} alt="icon" style={{
                              width: 20,
                              height: 18,
                            }} />
                          </InputAdornment>
                        )),
                        endAdornment: this.getValueBasedOnLanguage((
                          <InputAdornment position="start" style={{
                            marginRight:'0',
                              marginLeft:'8px'
                          }}>
                            <img src={vat} alt="icon" style={{ 
                              width: 20,
                              height: 18,
                            }} />
                          </InputAdornment>
                        ), null),


                      }}
                    />
                     <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.vatRegNo("VatRegNo")}
                      </span>
                    

                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="NationalAddress"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="National Address *"
                      value={this.state.profileData.attributes?.address}
                      onChange={(event: any) => {
                        this.onValueChange(
                          "address",
                          event.target.value
                        );
                      }}
                      InputProps={{
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(null, (
                          <InputAdornment position="start">
                            <img src={home} alt="icon" style={{
                              width: 16,
                              height: 17.5,
                            }} />
                          </InputAdornment>
                        )),
                        endAdornment: this.getValueBasedOnLanguage((
                          <InputAdornment position="start" style={{
                            marginRight:'0',
                              marginLeft:'8px'
                          }}>
                            <img src={home} alt="icon" style={{ 
                              width: 16,
                              height: 17.5,
                            }} />
                          </InputAdornment>
                        ), null),


                      }}
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.nationalAddress("NationalAddress")}
                      </span>
                    
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="email"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Support email (Option)"
                      value={this.state.profileData.attributes?.support_email}
                      onChange={(event: any) => {
                        this.onValueChange("support_email", event.target.value);
                      }}

                      InputProps={{
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },

                        startAdornment: this.getValueBasedOnLanguage(null, (
                          <InputAdornment position="start">
                            <img src={email} alt="icon" style={{
                              width: 20,
                              height: 16,
                            }} />
                          </InputAdornment>
                        )),
                        endAdornment: this.getValueBasedOnLanguage((
                          <InputAdornment position="start" style={{
                            marginRight:'0',
                              marginLeft:'8px'
                          }}>
                            <img src={email} alt="icon" style={{ 
                              width: 20,
                              height: 16,
                            }} />
                          </InputAdornment>
                        ), null),


                      }}
                    />
                    {this.supportEmail("email")}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      id="modal-title"
                      fontWeight="bolder"
                    >
                      <Typography style={{...webStyle.formHeading, 
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                      }}>{I18n.t("companyInfo")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="BusinessName"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Business name *"
                      value={this.state.profileData.attributes?.business_name}

                      onChange={(event: any) => {
                        this.onValueChange(
                          "business_name",
                          event.target.value
                        );
                      }}
                      InputProps={{
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(null, (
                          <InputAdornment position="start">
                            <img src={vat} alt="icon" style={{
                              width: 20,
                              height: 18,
                            }} />
                          </InputAdornment>
                        )),
                        endAdornment: this.getValueBasedOnLanguage((
                          <InputAdornment position="start" style={{
                            marginRight:'0',
                              marginLeft:'8px'
                          }}>
                            <img src={vat} alt="icon" style={{ 
                              width: 20,
                              height: 18,
                            }} />
                          </InputAdornment>
                        ), null),


                      }}
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.businessName("BusinessName")}
                      </span>
                  

                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>

                    <InputField
                      data-test-id="TradingLicense"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Trading License *"
                      value={this.state.profileData.attributes?.trading_license_image?.[0]?.name}

                      onClick={this.handleFileInputClick4}
                      InputProps={{
                        readOnly: true,
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>,
                          <InputAdornment position="start">
                            <img src={card} alt="License" style={{ width: 20, height: 16 }} />
                          </InputAdornment>
                        ),
                        endAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="end">
                            <img src={card} alt="License" style={{ width: 20, height: 16 }} />
                          </InputAdornment>,
                          <InputAdornment position="end">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <input
                      type="file"
                      data-test-id="TradingLicenseFile"
                      ref={this.state.fileInput4}
                      style={{ display: 'none' }}
                      accept=".pdf"
                      onChange={this.handleFileChange4}
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.tradingLicense("TradingLicense")}
                    </span>

                  </Grid>

                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="VATtaxidentifire"
                      fullWidth
                      variant="outlined"
                      onClick={this.handleFileInputClick3}
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Vat Tax Identifier *"
                      value={this.state.profileData.attributes?.vat_tax_identifier_image?.[0]?.name}
                      onChange={(event: any) => {
                        this.onValueChange("VATtaxidentifire", event.target.value);
                      }}
                      InputProps={{
                        readOnly: true,
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>,
                          <InputAdornment position="start">
                            <img src={taxino} alt="License" style={{ width: 14, height: 18 }} />
                          </InputAdornment>
                        ),
                        endAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="end">
                            <img src={taxino} alt="License" style={{ width: 14, height: 18 }} />
                          </InputAdornment>,
                          <InputAdornment position="end">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <input
                      type="file"
                      data-test-id="VATtaxidentifireFile"
                      ref={this.state.fileInput3}
                      style={{ display: 'none' }}
                      onChange={this.handleFileChange3}
                      accept=".pdf"
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.vattaxidentifire("VATtaxidentifire")}
                    </span>
                    

                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="authorizedCertificate"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Authorization Certificate *"
                      value={this.state.profileData.attributes?.authorization_certificate_image?.[0]?.name}
                      onChange={(event: any) => {
                        this.onValueChange("authorizedCertificate", event.target.value);
                      }}
                      onClick={this.handleFileInputClick2}
                      InputProps={{
                        readOnly: true,
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>,
                          <InputAdornment position="start">
                            <img src={auth} alt="License" style={{ width: 24, height: 24 }} />
                          </InputAdornment>
                        ),
                        endAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="end">
                            <img src={auth} alt="License" style={{ width: 24, height: 24 }} />
                          </InputAdornment>,
                          <InputAdornment position="end">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <input
                      type="file"
                      ref={this.state.fileInput2}
                      data-test-id="authorizedCertificateFile"
                      style={{ display: 'none' }}
                      onChange={this.handleFileChange2}
                      accept=".pdf"
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.authorizedCertificate("authorizedCertificate")}
                    </span>
                    
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="authorizedIDcard"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="Authorized Id Card *"
                      value={this.state.profileData.attributes?.authorized_id_card_image?.[0]?.name}
                      onChange={(event: any) => {
                        this.onValueChange("authorizedIDcard", event.target.value);
                      }}
                      onClick={this.handleFileInputClick1}
                      InputProps={{
                        readOnly: true,
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>,
                          <InputAdornment position="start">
                            <img src={authId} alt="License" style={{ width: 18, height: 14 }} />
                          </InputAdornment>
                        ),
                        endAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="end">
                            <img src={authId} alt="License" style={{ width: 18, height: 14 }} />
                          </InputAdornment>,
                          <InputAdornment position="end">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <input
                      type="file"
                      ref={this.state.fileInput1}
                      data-test-id="authorizedIDcardFile"
                      style={{ display: 'none' }}
                      onChange={this.handleFileChange1}
                      accept=".pdf"
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.authorizedIDcard("authorizedIDcard")}
                    </span>
                    

                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="ibanCertificate"
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        },
                      }}
                      placeholder="IBAN Certificate *"
                      value={this.state.profileData.attributes?.iban_certificate_image?.[0]?.name}

                      onClick={this.handleFileInputClick}
                      InputProps={{
                        readOnly: true,
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        },
                        startAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>,
                          <InputAdornment position="start">
                            <img src={iban} alt="License" style={{ width: 22, height: 18 }} />
                          </InputAdornment>
                        ),
                        endAdornment: this.getValueBasedOnLanguage(
                          <InputAdornment position="end">
                            <img src={iban} alt="License" style={{ width: 22, height: 18 }} />
                          </InputAdornment>,
                          <InputAdornment position="end">
                            <IconButton>
                              <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <input
                      type="file"
                      data-test-id="ibanCertificateFile"
                      ref={this.state.fileInput}
                      style={{ display: 'none' }}
                      onChange={this.handleFileChange}
                      accept=".pdf"
                    />
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.ibanCertificate("ibanCertificate")}
                    </span>
                  
                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>
                    <InputField
                      data-test-id="OtherCertificate"
                      fullWidth
                      variant="outlined"

                      inputProps={{
                        style: {
                          height: "16px",
                          textAlign:this.getValueBasedOnLanguage('right', 'left'),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                          paddingRight: this.getValueBasedOnLanguage('0', '8px')
                        },
                      }}
                      placeholder="Add Other Document (Optional)"
                      value={this.state.profileData.attributes?.other_document_image?.[0]?.name}

                      onClick={this.handleOtherFileInputClick}
                      InputProps={{
                        readOnly: true,
                        style:{
                          paddingLeft: this.getValueBasedOnLanguage("4px", "14px"),
                          paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr")
                        },
                        startAdornment: this.getValueBasedOnLanguage(null, (
                          <InputAdornment position="start">
                            <img src={attatchfile} alt="user icon" style={{
                              width: 11,
                              height: 22,
                            }} />
                          </InputAdornment>
                        )),
                        endAdornment: this.getValueBasedOnLanguage((
                          <InputAdornment position="start" style={{
                            marginRight:'0',
                              marginLeft:'8px'
                          }}>
                            <img src={attatchfile} alt="user icon" style={{ 
                              width: 11,
                              height: 22,
                            }} />
                          </InputAdornment>
                        ), null),
                      }}
                    />
                    <input
                      type="file"
                      data-test-id="OtherCertificateFile"
                      ref={this.state.otherFileInput}
                      style={{ display: 'none' }}
                      onChange={this.OtherhandleFileChange}
                      accept=".pdf"
                    />

                      <StyledFormControl variant="outlined" fullWidth
                        style={{ marginTop: "20px" }}
                      >
                        <SelectMain
                          data-test-id="industryCat"
                          MenuProps={getCommonMenuProps}
                          name={"industryCat"}
                          value={this.state.industryCat}
                          error={this.state.errors.industryCat}
                          onChange={(event) => {
                            this.handleCategoryChange(event.target.value as string);
                          }}
                          displayEmpty
                          IconComponent={CustomDropdownIcon}
                          startAdornment={
                            <InputAdornment position="start">
                              <img
                                src={this.state.errors.industryCat ? catogoryRedIcon : catogoryNormalIcon}
                                alt="dep_icon"
                              />

                            </InputAdornment>
                          }
                          renderValue={(value) => {
                            const selectedCatogoryoptions = this.state.categoryData.find((option: any) => option.attributes?.id === value);
                            return (
                              <Box
                                style={{
                                  color: selectedCatogoryoptions ? 'currentColor' : '#94A3B8',
                                  ...CommonBoxStyles
                                }}
                              >
                                {selectedCatogoryoptions ? selectedCatogoryoptions.attributes?.name : I18n.t('categories')}
                              </Box>
                            );
                          }}
                          input={<OutlinedInput />}
                        >
                          {this.state.categoryData.map((optionVal: any, index: number) => (
                            <MenuItemSet
                              style={{
                                borderBottom: '1px solid #E2E8F0',
                                fontFamily: arabicCSSStyles("Noto Sans Arabic", "Poppins"),
                              }}
                              className="setMenuItem"
                              key={index}
                              value={optionVal.attributes?.id}
                            >
                              {optionVal.attributes?.name}
                            </MenuItemSet>
                          ))}
                        </SelectMain>
                      </StyledFormControl>
                    <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.industryCat("industryCat")}
                      </span>
                    
                  </Grid>
                  

                  <Grid item sm={6} xs={12} md={6}>
                    <StyledTextarea minRows={4}
                    style={{
                      textAlign:this.getValueBasedOnLanguage('right', 'left'),
                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                    }}
                      value={this.state.profileData.attributes?.business_description}
                      data-test-id="businessDescription"
                      onChange={(event: any) => {
                        this.onValueChange("business_description", event.target.value);
                      }}
                      placeholder="Business Description *" />
                      <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.businessDescription("businessDescription")}
                      </span>
                    


                  </Grid>
                  <Grid item sm={6} xs={12} md={6}>

                      <StyledFormControl variant="outlined" fullWidth
                      >
                        <SelectMain
                          data-test-id="branchSelection"
                          MenuProps={getCommonMenuProps}
                          name={"branchSelection"}
                          value={this.state.branchSelection}
                          error={this.state.errors.branchSelection}
                          onChange={(event) => {
                            this.handleBranchSelectionChange(event.target.value as string);
                          }}
                          displayEmpty
                          IconComponent={CustomDropdownIcon}
                          startAdornment={
                            <InputAdornment position="start">
                              <img
                                src={this.state.errors.branchSelection ? branchRedIcon : branchNormalIcon}
                                alt="dep_icon"
                              />

                            </InputAdornment>
                          }
                          renderValue={(value) => {
                            const selectedOpt = BranchTYpe.find((option) => option.str_key === value);
                            return (
                              <Box
                                style={{
                                  color: selectedOpt ? 'currentColor' : '#94A3B8',
                                  ...CommonBoxStyles
                                }}
                              >
                                {selectedOpt ? selectedOpt.label : I18n.t('branchSelection')}
                              </Box>
                            );
                          }}
                          input={<OutlinedInput />}
                        >
                          {BranchTYpe.map((optionVal, index) => (
                            <MenuItemSet
                              style={{
                                borderBottom: '1px solid #E2E8F0',
                                fontFamily: arabicCSSStyles("Noto Sans Arabic", "Poppins"),
                              }}
                              className="setMenuItem"
                              key={index}
                              value={optionVal.str_key}
                            >
                              {optionVal.label}
                            </MenuItemSet>
                          ))}
                        </SelectMain>
                      </StyledFormControl>

                      <span
                        style={{
                          textAlign: this.getValueBasedOnLanguage("right", "left"),
                          direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        }}
                      >
                        {this.branchSelection("branchSelection")}
                      </span>

                  </Grid>
                  {this.state.branchSelection ?
                    <>
                      <Grid item xs={12}>
                        <Box
                          id="modal-title"
                          fontFamily="Poppins"
                          fontWeight="bolder"
                          style={{ 
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection:this.getValueBasedOnLanguage("row-reverse", "row")
                          }}
                        >
                          <Typography style={{...webStyle.formHeading, 
                            fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                          }}>{I18n.t('branchDetails')}</Typography>
                            {this.renderAddBranchBox()}
                        </Box>
                      </Grid>

                      <>
                        {this.state.profileData?.attributes?.branches?.map((branch: any, index: any) => (
                          <Box key={branch.id} mb={4} width={'100%'} padding={'12px'}>
                            <Box mb={2} style={{ display: "flex", justifyContent: "space-between", flexDirection:this.getValueBasedOnLanguage('row-reverse', 'row') }}>
                              <strong style={{ fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"), fontSize: "18px" }}>{I18n.t('branch')} {index + 1}</strong>
                              {index > 0 ?
                                <Button
                                  data-test-id={`delete-${index}`}
                                  onClick={() => this.deleteBranch(index)}
                                >
                                  <img
                                    src={deleteimg}
                                    alt="deleteimg"
                                    style={{ height: "24px", width: "24px", cursor: "pointer" }} />
                                </Button>
                                :
                                null
                              }
                            </Box>
                            <Grid container spacing={3} direction={this.getValueBasedOnLanguage("row-reverse", "row")}
                            >
                              <Grid item sm={6} xs={12} md={6}>
                                <InputField
                                  data-test-id={`branchName-${index}`}
                                  fullWidth
                                  variant="outlined"
                                  inputProps={{ style: { 
                                    height: "16px",
                                    textAlign:this.getValueBasedOnLanguage('right', 'left'),
                                    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                  } 
                                  }}
                                  onChange={(event: any) => this.onValueChangeNew(index, "branch_name", event)}
                                  placeholder="Branch Name *"
                                  name={`branches[${index}].branchName`}
                                  error={this.state.errors[`branches[${index}].branch_name`]}
                                  value={this.state.profileData.attributes?.branches[index]?.branch_name}
                                  InputProps={{
                                    style:{
                                      paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                      paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                                    },
                                    startAdornment: this.getValueBasedOnLanguage(null, (
                                      <InputAdornment position="start">
                                        <img src={card} alt="icon" style={{
                                          width: 20,
                                          height: 16,
                                        }} />
                                      </InputAdornment>
                                    )),
                                    endAdornment: this.getValueBasedOnLanguage((
                                      <InputAdornment position="start" style={{
                                        marginRight:'0',
                                          marginLeft:'8px'
                                      }}>
                                        <img src={card} alt="icon" style={{ 
                                          width: 20,
                                          height: 16,
                                        }} />
                                      </InputAdornment>
                                    ), null),
              
                                  }} />
                                  <span
                                    style={{
                                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                    }}
                                  >
                                    {this.branchNameError(`branches[${index}].branch_name`)}
                                  </span>

                              </Grid>
                              <Grid item sm={6} xs={12} md={6}>
                                <InputField
                                  data-test-id={`branchCode${index}`}
                                  fullWidth
                                  variant="outlined"
                                  inputProps={{ style: { height: "16px",
                                    textAlign:this.getValueBasedOnLanguage('right', 'left'),
                                    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                  } }}
                                  placeholder="Branch Code *"
                                  error={this.state.errors[`branches[${index}].branch_code`]}
                                  value={this.state.profileData.attributes?.branches[index]?.branch_code}
                                  onChange={(event: any) => this.onValueChangeNew(index, "branch_code", event.target.value)}
                                  InputProps={{
                                    style:{
                                      paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                      paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                                    },
                                    startAdornment: this.getValueBasedOnLanguage(null, (
                                      <InputAdornment position="start">
                                        <img src={branchCodeIcon} alt="icon" style={{
                                          width: 20,
                                          height: 16,
                                        }} />
                                      </InputAdornment>
                                    )),
                                    endAdornment: this.getValueBasedOnLanguage((
                                      <InputAdornment position="start" style={{
                                        marginRight:'0',
                                          marginLeft:'8px'
                                      }}>
                                        <img src={branchCodeIcon} alt="icon" style={{ 
                                          width: 20,
                                          height: 16,
                                        }} />
                                      </InputAdornment>
                                    ), null),
              
                                  }} />
                                  <span
                                    style={{
                                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                    }}
                                  >
                                    {this.branchCodeError(`branches[${index}].branch_code`)}
                                  </span>
                      
                                  
                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <InputField
                                  data-test-id={`location_link-${index}`}
                                  fullWidth
                                  variant="outlined"
                                  inputProps={{ style: { height: "16px",
                                    textAlign:this.getValueBasedOnLanguage('right', 'left'),
                                    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                  } }}
                                  placeholder="Branch Location *"
                                  value={this.state.profileData.attributes?.branches[index]?.location_link}
                                  error={this.state.errors[`branches[${index}].location_link`]}
                                  onChange={(event: any) => this.onValueChangeNew(index, "location_link", event.target.value)}
                                  InputProps={{
                                    style:{
                                      paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                      paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                                    },
                                    startAdornment: this.getValueBasedOnLanguage(null, (
                                      <InputAdornment position="start">
                                        <img src={location} alt="icon" style={{
                                          width: 16,
                                          height: 17,
                                        }} />
                                      </InputAdornment>
                                    )),
                                    endAdornment: this.getValueBasedOnLanguage((
                                      <InputAdornment position="start" style={{
                                        marginRight:'0',
                                          marginLeft:'8px'
                                      }}>
                                        <img src={location} alt="icon" style={{ 
                                          width: 16,
                                          height: 17,
                                        }} />
                                      </InputAdornment>
                                    ), null),
              
                                  }} />
                                  <span
                                    style={{
                                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                    }}
                                  >
                                    {this.branchLocationLinkError(`branches[${index}].location_link`)}
                                  </span>
                                  
                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <div style={{ position: "relative" }}>
                                  {this.shouldShowPhoneNumberLabel && (
                                    <label
                                      style={{
                                        position: "absolute",
                                        top: 18,
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        lineHeight: "24px",
                                        color: "#94A3B8",
                                      }}
                                      htmlFor=""
                                    >
                                    </label>
                                  )}
                                  <this.PhoneStyle
                                    style={{
                                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                      textAlign: this.getValueBasedOnLanguage("right", "left"), 
                                    }}
                                    placeholder="Branch Contact Number *"
                                    data-test-id={`branchContactNo-${index}`}
                                    value={this.state.profileData.attributes?.branches[index]?.mobile_number}
                                    phoneNumber
                                    error={this.state.errors[`branches[${index}].mobile_number`]}
                                    countries={[]}
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="AE"
                                    onChange={(value: any) => this.onValueChangeNew(index, "mobile_number", value)} />

                                    
                                    <span
                                    style={{
                                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                    }}
                                  >
                                    {this.branchContanctNoError(`branches[${index}].mobile_number`)}
                                  </span>
                                </div>

                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <InputField
                                  data-test-id={`branchOperatingHours-${index}`}
                                  fullWidth
                                  variant="outlined"
                                  inputProps={{ style: { height: "16px", textAlign:this.getValueBasedOnLanguage('right', 'left'),
                                    direction: this.getValueBasedOnLanguage("rtl", "ltr"), } }}
                                  placeholder="Branch Operating Hours *"
                                  value={this.state.profileData.attributes.branches[index]?.business_hours}
                                  onClick={() => this.handleShowTime(index)}
                                  error={this.state.errors[`branches[${index}].business_hours`]}
                                  InputProps={{
                                    style:{
                                      paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                      paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                                    },
                                    readOnly: true,
                                    startAdornment: this.getValueBasedOnLanguage(
                                      <InputAdornment position="start" data-test-id="modalOperatingHours">
                                        <IconButton>
                                          <img src={time} alt="Time Icon" 
                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                            onClick={() => this.handleShowTime(index)}
                                          />
                                        </IconButton>
                                      </InputAdornment>,
                                      <InputAdornment position="start">
                                        <img src={ophours} alt="Operating Hours Icon" style={{ width: 18, height: 21 }} />
                                      </InputAdornment>
                                    ),
                                    endAdornment: this.getValueBasedOnLanguage(
                                      <InputAdornment position="start" style={{
                                        marginRight:'0',
                                        marginLeft:'8px'
                                      }}>
                                        <img src={ophours} alt="Operating Hours Icon" style={{ width: 18, height: 21 }} />
                                      </InputAdornment>,
                                      <InputAdornment position="start" data-test-id="modalOperatingHours">
                                        <IconButton>
                                          <img src={time} alt="Time Icon" 
                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                            onClick={() => this.handleShowTime(index)}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }} />
                                  <span
                                    style={{
                                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                    }}
                                  >
                                    {this.branchOperatingHoursError(`branches[${index}].business_hours`)}
                                  </span>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                        <BranchOperatingHoursModal
                          data-test-id='customBranchOpHours'
                          open={this.state.showMOdaltime}
                          onClose={this.handleHideshowTime}
                          modalBranchIndex={this.state.modalBranchIndex}
                          branchData={this.state.profileData?.attributes?.branches[this.state.modalBranchIndex] || {}}
                          handleCheckboxChange={this.handleCheckboxChange}
                          handleHourChange={this.handleHourChange}
                          handleMinuteChange={this.handleMinuteChange}
                          handlePeriodChange={this.handlePeriodChange}
                          handleHourChangeEnd={this.handleHourChangeEnd}
                          handleMinuteChangeEnd={this.handleMinuteChangeEnd}
                          handlePeriodChangeEnd={this.handlePeriodChangeEnd}
                          handleSubmit={() => this.onHandleSubmitTime(this.state.modalBranchIndex)}
                        />
                      </>
                    </>
                    : null}

                

                </Grid>

                <SaveBtnBox>
                  <SubmitButn 
                    data-test-id="formDatasumit"
                    type="submit" fullWidth
                    style={{
                      fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"), 
                    }}
                    >
                    {I18n.t("saveChanges")}
                  </SubmitButn>
                </SaveBtnBox>

              </form>


            </Card>
            <Modal
              open={this.state.dialogOpen}
              onClose={this.handleDialogClose}
              style={stylesDialog.modal}
            >
              <div style={stylesDialog.modalContent}>
                <div style={stylesDialog.buttonContainer1}>
                  {this.state.successError ? (
                    <>

                      <div style={{ height: "63.75px", width: "63.75px" }}>
                        <img src={cancleRoundRed} alt="Cancle" style={stylesDialog.imgCancle} />
                      </div>
                      <Typography style={stylesDialog.errorTitle}>
                        {I18n.t("reqFieldMissing")} 
                      </Typography>
                    </>
                  ) : (
                    <>
                      <div style={{ height: "70px", width: "70px" }}>
                        <img src={checkedRound} alt="Cancle" style={stylesDialog.imgCancle} />
                      </div>
                      <Typography style={stylesDialog.errorTitle}>
                      {I18n.t("profileDataSaved")}
                      </Typography>
                    </>
                  )}
                </div>
                <div style={stylesDialog.buttonContainer}>
                  {this.state.successError ? (
                    <>
                      <Button variant="contained" style={stylesDialog.tryAgain} onClick={this.handleDialogClose}>
                        {I18n.t("tryAgain")}
                      </Button>
                      <Button variant="outlined" style={stylesDialog.cancle} onClick={this.handleDialogClose}>
                        {I18n.t("cancel")}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="outlined" style={stylesDialog.tryAgain} 
                        data-test-id="backbuttonClick"
                        onClick={this.navigateEducationalUserProfile}
                      >
                        {I18n.t("back")}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Modal>
          </Box>
          </div>
        
        </div>
      </ThemeProvider>
      
     
    );
    // Customizable Area End
  }

 
  
  // Customizable Area Start
  // Customizable Area End
  
}
// Customizable Area Start
const stylesDialog = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    outline: 'none',
    maxWidth: '500px',
    minWidth : "450px",
    textAlign: 'center' as 'center',
    display:"flex",
    flexDirection:"column" as 'column',
    justifyContent:"center" as 'center',
  },
  imgCancle: {
    height:"100%",
    width:"100%",
  },
  buttonContainer: {
    display: 'flex',
    flexDirection:"column" as 'column',
    gap:"10px",
  },
  buttonContainer1: {
    display: 'flex',
    flexDirection:"column" as 'column',
    gap:"30px",
    alignItems:"center" as 'center',
  },
  errorTitle: {
    fontFamily:arabicCSSStyles("Noto Sans Arabic", "Poppins"),
    fontSize:"20px",
    fontWeight: 700,
    color:"#1E293B",
    lineHeight:"20px",
  },
  tryAgain:{
    width: '100%',
    height : "48px",
    background: '#527FE2',
    marginTop: '20px',
    fontSize: '18px',
    padding: '10px',
    border: 'none',
    borderRadius: '8px',
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: "capitalize" as 'capitalize',
    fontFamily: arabicCSSStyles("Noto Sans Arabic", "Poppins")
  },
  cancle:{
    width: '100%',
    padding: '10px',
    height : "48px",
    cursor: 'pointer',
    color: '#527FE2',
    background: '#fff',
    border: '1px solid #527FE2',
    borderRadius: '8px',
    fontSize: '18px',
    fontWeight: 600,
    marginTop:'5px',
    fontFamily: arabicCSSStyles("Noto Sans Arabic", "Poppins"),
    textTransform: "capitalize" as 'capitalize',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
const webStyle:any = {
    imageStyle: {
      width: 100,
    },
    qualificationBlock: {
      marginTop: 40,
    },
  
    cardStle: {
      minHeight:"100vh",
      maxWidth:"100vw",
      padding:"1.5rem",
      margin:"2rem 1.2rem",
      borderRadius:"1.5rem",
      position:"relative",
      top:"-150px"
  
    },
    imageBOx:{
      padding:"0 1.5rem 0 1.5rem",
      borderRadius:"1.5rem",
      display:"flex",
      gap:"1rem",
      alignItems:"center",
      marginBottom:"40px"
    },
    imageStyle1:{
      width:"130px",
      height:"130px",
      borderRadius:"150px"
    },
    profileName:{
      fontFamily:"Poppins",
      fontWeight:700,
      fontSize:"2rem",
      letterSpacing:"-1%"
    },
    profileCat:{
      fontFamily:"Poppins",
      fontWeight:400,
      fontSize:"1rem",
      color:"#334155",
      display:"flex",
      alignItems:"center",
      columnGap:"8px"
    },
    formHeading:{
      fontFamily:"Poppins",
      fontSize:"20px",
      fontWeight:700,
      paragraph:"12px",
      color:"#334155"
    },
    addBranch:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      color:"#527FE2",
      alignSelf:"center"

    },
    addBranchIcon:{
      height: "20px",
      width:"20px",
      alignSelf:"center"
    },
    branchBox:{
      display:"flex",
      gap:"8px",
      textAlign:"center",
      justifyContent:"center",
      cursor:"pointer",
    },
    descText:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:400,
      paragraph:"8px",
      color:"#0F172A",
      marginTop:"8px",
    },
    subFields:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      paragraph:"8px",
      color:"#64748B"
    },
    branchName:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      paragraph:"8px",
      color:"#334155",
      marginTop:"1rem"
    }
};

const Modalstyles: { [key: string]: React.CSSProperties } = {
  modal: {
    position:
      'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px 8px 32px 8px',
    width:"1024px",
    maxWidth:"98vw"

  },
  subModalDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', flexDirection: 'column',maxHeight:"98vh",overflow:"auto"
  },
  topboxstyle: {
    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
    borderBottom: "1px solid #E2E8F0", padding: "24px 0px"
  },
  dailyWorkingHrs: {
    display: "flex", width: "100%",flexDirection:"column"
  },
  topboxstyle2: {
  
    padding: "24px 0px",width:"100%"
  },
};

  const BoxTime = styled(Box)({
    display:"flex",alignItems:"center",width:"50%",gap:"0.5rem",
    "@media (max-width:700px )": {
     width:"100%",
    },
  });
  
  const BoxTimeTop = styled(Box)({
    padding: "1.5rem 40px 1.5rem 40px",display:"flex",justifyContent:"space-between"  ,
    "@media (max-width:700px )": {
     flexDirection:"column"
    },
          
  });
  
  const HeadingTime = styled(Typography)({
    fontFamily:"Poppins",
                fontWeight:"bold",
                fontSize:"16px"
                
  });

  const TimeRange123 = styled(TextField)({
 
    "& input::placeholder": {
      color: "#94A3B8",
      opacity: 1,
      fontFamily: "Poppins",
      fontSize:"20px",fontWeight:700
    },
  
    "& .MuiInputBase-input[type='date']": {
      lineHeight: "19.2px",
      textTransform: "uppercase",
      color: "#334155", fontSize: "16px",fontWeight:400
    },
  
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "18px",
    },
  
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', 
      },
      '&:hover fieldset': {
        border: 'none', 
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  
    "& .MuiInputBase-root": {
      color: "black",fontFamily:"Poppins",fontSize:"1rem",paddingLeft:"5px"
    },
  
    "& .MuiOutlinedInput-input": {
      fontSize:"16px",fontWeight:400,padding:"0px"
    },
  
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  
    "@media (max-width: 990px)": {
      minWidth: "unset",
      maxWidth: "unset",
    },
  });

  const HeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "20px ",
    },
    "@media (max-width:899px )": {
      fontSize: "18",
    },
  
    fontFamily: "Poppins", lineHeight: "25px",
    fontSize: "24px", fontWeight: 700,
     padding: "0 40px"
  
  });
  
  const SubHeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "18px ",
    },
    "@media (max-width:899px )": {
      fontSize: "16",
    },
  
    fontFamily: "Poppins",
    fontSize: "20px", fontWeight: 700, 
    padding: "0 40px"
  
  });

  const AcceptBtn = styled(Button)({
    maxWidth: "42.5%",
    marginRight:"24px",
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
  
    background:
      "#527FE2",
    width: "100%",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    fontFamily: "Poppins ",
    fontSize: "1em",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    }
  
  });
  
  const HeadBox = styled(Box)({
    display:"flex",
    "@media (max-width:990px )": {
      flexDirection:"column"
    },
                
  });
  const SubHeadBox = styled(Box)({
    width:"50%",
    "@media (max-width:990px )": {
      width:"100%",
    },
                
  });
  
  const SubFieldRows = styled(Box)({
    marginTop:"1rem",display:"flex",alignItems:"center",gap:"5px",flexWrap:"wrap"
                
  });
  
  const EditBtn = styled(Button)({
    maxWidth: "42.5%",
    marginRight:"24px",
    position:"absolute",
    height:"44px",
    padding:"10px 16px",
    backgroundColor:"#60A5FA",
    top:"40px",
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
  
    gap: "8px",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "Poppins ",
    fontSize: "1em",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#60A5FA",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#60A5FA",
      boxShadow: 'none',
    }
  
  });
  
  const DescrText = styled(Typography)({
    fontFamily:"Poppins",
    fontSize:"16px",
    fontWeight:400,
    paragraph:"8px",
    color:"#0F172A",
    marginTop:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
  });
  
  const SubFieldsText = styled(Typography)({
    fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      paragraph:"8px",
      color:"#64748B",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
  });
  
  const ProfileCatText = styled(Typography)({
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"1rem",
    color:"#334155",
    display:"flex",
    alignItems:"center",
    columnGap:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },})
  
    const ProfileName = styled(Typography)({
      fontFamily:"Poppins",
      fontWeight:700,
      fontSize:"2rem",
      letterSpacing:"-1%",
      "@media (max-width:500px )": {
        fontSize:"1.5rem",
      },
    });
  
    const ImageProfile = styled('img')({
      width:"130px",
      height:"130px",
      borderRadius:"150px",
      "@media (max-width:500px )": {
        width:"100px",
        height:"100px",
        borderRadius:"50%"
      },
    });
  
    const InputField = styled(TextField)({
      "& input::placeholder": {
        color: "#94A3B8", opacity: 1,fontFamily:"Poppins",fontSize: "16px", fontWeight: 400,
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
      
      "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px", textTransform: "uppercase",
        color: "#334155",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px", fontFamily: "Poppins",
        fontWeight: 400, lineHeight: "18px",
      },
      
      "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline" : {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline" : {
        borderColor: "#F87171", 
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px", 
        borderRadius: "8px",
        borderColor: "#CBD5E1",
      },
      "& .MuiInputBase-root":{
        color: "#334155",
      },
      "& .MuiOutlinedInput-input": {
        padding: "20px 8px",
      },
      "& .MuiOutlinedInput-root" : {
        "&:hover fieldset": {

          borderColor:"#527FE2",
        },
        "&.Mui-focused fieldset":{
          borderWidth: "1px",
          borderColor: "#527FE2",
        },

      },
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "0px", marginRight: "0px",
      },
      "@media (max-width:990px )": {
        minWidth: "unset", 
        maxWidth: "unset",
      },
    });


    const StyledTextarea = styled(TextareaAutosize)({
      width: '100%',
      minHeight:'132px',
      maxHeight: '132px',
      overflowY: 'auto',
      resize: 'none',
      border: '1px solid #CBD5E1',
      borderRadius: '10px',
      padding: '1rem',
      fontFamily: 'Poppins',
      fontSize: '1rem',
      '&::placeholder': {
        color: '#94A3B8', opacity:1
      },

      '&:hover': {
        borderColor: '#527FE2',
      },

      '&:focus': {
        borderColor: '#527FE2 !important',
        borderWidth: "1px",
        outline:'none'
      },

      '&:focus-visible': {
        borderColor: '#527FE2 !important',
        outline:'none'
      },

      '&:active': {
        borderColor: '#527FE2 !important',
        outline:'none'
      },

      '&::-webkit-scrollbar': {
        width: '12px',
        borderRadius: '10px', 
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#527FE2',
        borderRadius: '10px', 
        border: '3px solid transparent',
        backgroundClip: 'padding-box',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent', 
      },
      
    });

    const SubmitButn = styled(Button)({
      color: "white",
      borderRadius: "10px",
      backgroundColor: "#527FE2",
      textTransform: "none",
      fontFamily: "Poppins",
      fontWeight:"bold",
      fontSize: "24px",
      padding: "6px 31px",
      maxWidth: "400px",
      height: "56px",
      "&:hover": {
        color: "white",
        backgroundColor: "#527FE2",
      },
      "@media(max-width:600px)": {
        fontSize:"18px",
        padding: "7px 27px",
      },
    });

    const SaveBtnBox = styled(Box)({
      display: "flex", flexDirection: "column", alignItems: "center",
      justifyContent: "center", gap: "16px",
      marginTop: "4em",
      "@media (max-width:990px )": {
        display: "flex", flexDirection: "column",
        justifyContent: "start",
      },
    });

    
// Customizable Area End
