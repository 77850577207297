import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { apiCall } from "./AccountGroupsController";
import { I18n } from "../../../components/src/languageJson/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  currentLanguage: string;
  selectedImage: any;
  editSelectedImage: string;
  mobile_number: any;
  successDialog:boolean;
  successUpdateDialog: boolean;
  setDepartmentData: any;
  setPositionData: any;
  setPosition: any;
  setDepartment: any;
  setReporting: any;
  setBranchData: any;
  setReportingData:any;
  resetState: ()=> void;
  setParticularUser: any;
  viewUser: boolean;
  initialValues : {
    name: string;
    organization_id: string;
    department: string;
    branch: string;
    designation: string;
    reporting_to: string;
    home_address: string;
    work_email: string;
    mobile_number: string;
    additional_notes: string;
    employee_id: string;
  };
  token: string;
  paramsId: number;
  setEditData: any;
  flag: boolean;
  failDialog: boolean;
  enableAppointment:boolean;
  errorModal : boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  updateEmployeeCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  getDepartmentDataCallId = "";
  getPositionDataCallId = "";
  getBranchDataCallId = "";
  getReportingPersonCallId = "";
  addEmployeeCallId = "";
  getEditEmployeeCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
      id: "0",
      selectedImage: null,
      editSelectedImage: "",
      mobile_number: '',
      successDialog:false,
      successUpdateDialog: false,
      setDepartmentData : [],
      setPositionData: [],
      setPosition: '',
      setDepartment: '',
      setReporting: '',
      setBranchData: [],
      setReportingData: [],
      resetState: () => {},
      setParticularUser: {},
      viewUser: false,
      initialValues : {
        name: '',
        organization_id: '',
        department: '',
        branch: '',
        designation: '',
        reporting_to: '',
        home_address: '',
        work_email: '',
        mobile_number: '',
        additional_notes: '',
        employee_id: ''
      },
      token: "",
      paramsId: 0,
      setEditData: {},
      flag: false,
      failDialog: false, 
      enableAppointment: false, 
      errorModal: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    await this.getBranchData();
    await this.getDepartmentList();
    let token = await getStorageData("token");
    this.setState({ token })
    const id = this.props.navigation.getParam("id")
    if (id) {
      this.setState({ paramsId: id })
      this.editEmployee(id)
    }
  }

  receive = async (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getDepartmentDataCallId) {
      if (responseJson ) {
        this.setState({ setDepartmentData: responseJson.data})
      }
    }
    
    if (apiRequestCallId === this.getPositionDataCallId) {
      if (responseJson && Array.isArray(responseJson)) {
        this.setState({ setPositionData: responseJson}); 
      } else {
        this.setState({ setPositionData: [], setPosition: ""});
        //Check Error Response
      }
    }

    if (apiRequestCallId === this.getBranchDataCallId) {
      if (responseJson ) {
        this.setState({ setBranchData: responseJson.branches})
      }
    }
    this.getReportingPersonResponce(apiRequestCallId,responseJson)
    this.addEmployeeResponce(apiRequestCallId,responseJson)
    this.getEditEmployeeResponce(apiRequestCallId,responseJson)
    this.updateEmployeeResponce(apiRequestCallId,responseJson)
  }

  getReportingPersonResponce = (apiRequestCallId: string,responseJson: any) => {
    if (apiRequestCallId === this.getReportingPersonCallId) {
      if (responseJson ) {
        this.setState({ setReportingData: responseJson.employees.data})
      }
    }
  } 

  handleCHeckPrice=()=>{
    this.setState({enableAppointment:!this.state.enableAppointment})
   } 

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  addEmployeeResponce = (apiRequestCallId: string,responseJson: any) => {
    if (apiRequestCallId === this.addEmployeeCallId) {
      if (responseJson.status === 404 || responseJson.status === 500|| responseJson.status === 503) {
        this.setState({ errorModal: true})
        return;
      }
      if (!responseJson.error && responseJson) {
        this.setState({ selectedImage: null, successDialog: true, setParticularUser: responseJson.employee_details.data.attributes });
        this.state.resetState();
      } else{
        toast.error(responseJson.error.en ?? Array.isArray(responseJson.error) ? responseJson.error[0] : responseJson);
      }
    }
  }

  updateEmployeeResponce = (apiRequestCallId: string,responseJson: any) => {
    if (apiRequestCallId === this.updateEmployeeCallId) {
      if (responseJson.status === 404 || responseJson.status === 500|| responseJson.status === 503) {
        this.setState({ errorModal: true})
        return;
      }
      if (!responseJson.error && responseJson) {
        this.setState({ successUpdateDialog: true, setParticularUser: responseJson.employee.data.attributes, flag: false , setEditData: {}});
      } else {
        const errorMessage = 
          responseJson.error?.en ?? 
          (Array.isArray(responseJson.error) ? responseJson.error[0] : responseJson.error);
        
        toast.error(errorMessage);
      }
    }
  }

  getEditEmployeeResponce = (apiRequestCallId: string,responseJson: any) => {
    if (apiRequestCallId === this.getEditEmployeeCallId) {
      if (!responseJson.error && responseJson) {
        this.setState({ 
          setEditData : responseJson.employee.data.attributes, 
          viewUser: false, 
          editSelectedImage : responseJson.employee.data.attributes.image, 
          enableAppointment: responseJson.employee.data.attributes.is_enable_appointments 
        })
        
        const selectedDepartment = this.state.setDepartmentData && this.state.setDepartmentData.find(
          (item: any) =>  item.attributes.name === responseJson.employee.data.attributes.department
        );
        this.handleChange("department",selectedDepartment.id,"");

        const selectedBranch = this.state.setBranchData && this.state.setBranchData.find(
          (item: any) =>  item.name === responseJson.employee.data.attributes.branch
        );
        this.handleChange("branch",selectedBranch.id,"");
      }  
    }
  }

  handleImageUpload = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ selectedImage: file, flag: true });
    }
  };

  handleClose = () => {
    this.setState({ successDialog: false});
  };

  handleUpdateClose = () => {
    this.setState({ successUpdateDialog: false});
  };

  getDepartmentList = async () => {
    const token = await getStorageData("token");
  
    this.getDepartmentDataCallId = await apiCall({
      endPoint: configJSON.getDepartmentApi,
      method: configJSON.getApiMethod,
      contentType: configJSON.apiContentType,
      token,
    });
  }

  getPositonData = async (id:any) => {
    const token = await getStorageData("token");

    this.getPositionDataCallId = await apiCall({
      endPoint: `/bx_block_fullteamvisibility/departments/${id}/designations`,
      method: configJSON.getApiMethod,
      token,
      contentType: configJSON.apiContentType
    });
  };

  getBranchData = async () => {
    const token = await getStorageData("token");
    const header = { "Content-Type": configJSON.apiContentType, token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBranchDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBranchApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReportingPersonData = async (id:any) => {
    const token = await getStorageData("token");
    const header = { "Content-Type": configJSON.apiContentType, token };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getReportingPersonCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`/bx_block_fullteamvisibility/employees_in_department/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (title:string,value:unknown,setFieldValue:any) => {    
    if(title === 'department'){
      this.setState({ setDepartment : value, setReportingData : []})
      this.getReportingPersonData(value);
      this.getPositonData(value)
      setFieldValue("reporting_to","")
      setFieldValue("designation", "");
    }
    if(title === 'position'){
      this.setState({ setPosition :value})
    }
    if(title === 'reporting'){
      this.setState({ setReporting : value})
    }
    if(title === 'branch'){
      // this.setState({ setReporting : event.target.value as string})
    }
  };

  addEmployee = async (values:any) => {
    const token = await getStorageData("token");
    const header = {
      token
    };

    const formData = new FormData();
    formData.append("employee[name]", values.name);
    formData.append("employee[department_id]", values.department);
    formData.append("employee[designation_id]", values.designation);
    formData.append("employee[mobile_number]", values.mobile_number);
    formData.append("employee[work_email]", values.work_email);
    formData.append("employee[home_address]", values.home_address);
    formData.append("employee[organization_id]", values.organization_id);
    formData.append("employee[branch_id]", values.branch);
    formData.append("employee[additional_notes]", values.additional_notes);
    formData.append("employee[reporting_to]", values.reporting_to);
    formData.append("employee[profile_picture]", this.state.selectedImage);
    formData.append("employee[is_enable_appointments]", this.state.enableAppointment.toString());

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.addEmployeeCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.addEmployeeApi);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.postApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateEmployee = async (values:any) => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEmployeeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_fullteamvisibility/employees/${this.state.setEditData?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    const selectedDepartment = this.state.setDepartmentData?.find(
      (item: any) => item.id === values.department || item.attributes.name === values.department
    );
    const selectedPosition = this.state.setPositionData?.find(
      (item: any) => item.id === values.designation || item.name === values.designation
    );
    const selectedBranch = this.state.setBranchData?.find(
      (item: any) => item.id === values.branch || item.name === values.branch
    );
    const selectedReorting = this.state.setReportingData?.find(
      (item: any) => item.id === values.reporting_to || item.attributes.name === values.reporting_to
    );

    
    const formData = new FormData();
    formData.append("employee[name]", values.name);
    formData.append("employee[department_id]", selectedDepartment.id);
    formData.append("employee[designation_id]", selectedPosition?.id);
    if(values.mobile_number != ''){

      formData.append("employee[mobile_number]", values.mobile_number);
    }
    formData.append("employee[work_email]", values.work_email);
    formData.append("employee[home_address]", values.home_address);
    formData.append("employee[branch_id]", selectedBranch.id);
    formData.append("employee[organization_id]", values.organization_id);
    if(selectedReorting?.id){
     formData.append("employee[reporting_to]", selectedReorting.id);
    }else{
      formData.append("employee[reporting_to]", '');
    }
      
    formData.append("employee[additional_notes]", values.additional_notes);
    if(this.state.selectedImage){
      formData.append("employee[profile_picture]", this.state.selectedImage);
    }
    formData.append("employee[is_enable_appointments]", this.state.enableAppointment.toString());

   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  onSubmit = (values:any, resetForm:()=>void) => {
    const id = this.props.navigation.getParam("id")
    
        if (this.state.selectedImage != null && !id && Object.keys(this.state.setEditData).length === 0) {
      this.addEmployee(values);
    } else if ((id && this.state.flag) || Object.keys(this.state.setEditData).length !== 0) {
      this.updateEmployee(values)
    } else if (id && !this.state.flag) {
      this.setState({ failDialog: true});
    } else {
      toast.error(I18n.t("ProfileImageError").toString())
    }
    this.setState({resetState:resetForm})
  };

  handleNavigation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountGroups");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleState = () => {
    this.setState({ viewUser: true, successDialog: false, successUpdateDialog: false })
  }

  handleStateFail = () => {
    this.setState({ failDialog : false})
  }

  handleUpdateEmpState = () => {
    this.setState({ viewUser: true, successUpdateDialog: false })
  }

  editEmployee = async (id: number) => {
    const token = await getStorageData("token");
    const header = { "Content-Type": configJSON.apiContentType, token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEditEmployeeCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_fullteamvisibility/employees/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigation1 = (id:number) => {
    this.editEmployee(id)
    this.setState({ viewUser: false})
  };

  handleChangeformik = (event:any,formikChange:Function)=>{
    formikChange(event);
    this.setState({ flag: true });
  }

  handleChangeformikDropdown = (event: any, formikChange: Function, setFieldValue: Function) => {
    formikChange(event)
    this.handleChange("department", event.target.value, setFieldValue);
    setFieldValue("department", event.target.value);
    this.setState({ flag: true })
  }

  handleChangeformikBranch = (event: any, formikChange: Function, setFieldValue: Function) => {
    formikChange(event)
    this.handleChange("branch", event.target.value, setFieldValue)
    this.setState({ flag: true })
  }

  handleChangeformikReport = (event: any, formikChange: Function, setFieldValue: Function) => {
    formikChange(event)
    this.handleChange("reporting", event.target.value, setFieldValue)
    this.setState({ flag: true })
  }

  handleChangeformikDesignation = (event: any, formikChange: Function, setFieldValue: Function) => {
    formikChange(event)
    this.handleChange("position", event.target.value, setFieldValue)
    this.setState({ flag: true })
  }

  closeErrorModal = () => {
    this.setState({ errorModal : false})
  }
  // Customizable Area End
}