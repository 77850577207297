import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { NavigateBefore } from '@material-ui/icons';
import { getValueBasedOnLanguage as applyCSS } from "./constant"

interface BreadcrumbProps {
  breadcrumbItems: string[]; // An array of breadcrumb items as props
  pathToNavigate ?: string;
  navigation? : any
}

const currentLanguage = localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en';

class DynamicBreadcrumbs extends React.Component<BreadcrumbProps> {
  
  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return currentLanguage === "ar" ? firstValue : secondValue;
  };

  handlenavigation = (path: string) => {
    if (this.props.pathToNavigate) {
      const pathtoNav = this.props.pathToNavigate || "";
      const selectedItemToNav = this.props.breadcrumbItems.length === 3 ? this.props.breadcrumbItems[1] : ""

      if (selectedItemToNav !== "" && path.toLowerCase() === selectedItemToNav.toLowerCase()) {
        return this.props.navigation.navigate(pathtoNav)
      }
    }
  }

  render() {
    const { breadcrumbItems } = this.props;

    return (
      <div style={{ paddingBottom: '50px' }}>
        <Breadcrumbs style={{
          textAlign: this.getValueBasedOnLanguage('right', 'left'),
          direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
        }} 
        separator={
          this.getValueBasedOnLanguage(
            <NavigateBefore fontSize="small" style={{ color: "black"}} />, // For Arabic (RTL)
            <NavigateNextIcon fontSize="small" style={{ color: "black"}}/>  // For English (LTR)
          )
        } 
        aria-label="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <Typography
              key={index}
              onClick={() => this.handlenavigation(item)}
              style={{
                fontWeight: index === breadcrumbItems.length - 1 ? 700 : 400,
                color: index === breadcrumbItems.length - 1 ? '#527FE2' : 'black',
                cursor: "pointer",
                fontSize: "14px",
                fontFamily: applyCSS("Noto Sans Arabic", "Poppins")
              }}
            >
              {item}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
    );
  }
}

export default DynamicBreadcrumbs;
