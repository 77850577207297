// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { apiCalling, truthyValue } from "../../../components/src/constant";
import { getStorageData } from ".../../../packages/framework/src/Utilities";
import { DepartmentFormFields, DepartmentFormErrorFields, DepartmetOptionTypes } from "../../../components/src/types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  selectedLanguage : string,
  departmentId : string
  editDepartmentFormFields : DepartmentFormFields,
  editDepartmentErrorFields : DepartmentFormErrorFields,
  optionsArray : DepartmetOptionTypes[],
  parentDepartmentId: string,
  successUpdateModal : boolean,
  errorUpdateModal : boolean,
  msg_update_en : string,
  msg_update_ar: string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditDepartmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listDepartmentApiCallId : string = ""
  departmentsOptionsApiCallId : string = ""
  editDepartmentApiCallId : string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        departmentId: "",
        selectedLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
        editDepartmentErrorFields: this.initialErrorFields,
        editDepartmentFormFields: this.initialFormFields,
        optionsArray: [],
        parentDepartmentId : "",
        msg_update_ar : "",
        msg_update_en: "",
        successUpdateModal : false,
        errorUpdateModal : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
      switch (apiRequestCallId) {
        case this.listDepartmentApiCallId:
          return this.filterDepartmentApiResponse(responseJson)
        case this.departmentsOptionsApiCallId:
          return this.handleOptionsDepartmentArrayResponse(responseJson)
        case this.editDepartmentApiCallId:
          return this.handleEditDepartmentApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
      this.getOptionsDepartmentData()
      const departmentId = this.props.navigation.getParam("id")
      this.setState({ departmentId})
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.departmentId !== this.state.departmentId) {
         this.listDepartmentData()
      }
  }

  filterDepartmentApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const receivedArray = truthyValue(responseJson.data)
      const findItem = receivedArray.find((item: any) => item.id === this.state.departmentId)
      if (findItem) {
        const editFormValues = {
          department_name_en: truthyValue(findItem.attributes.name),
          department_name_ar: truthyValue(findItem.attributes.name_ar),
          department_desc_en: truthyValue(findItem.attributes.description_en),
          department_desc_ar: truthyValue(findItem.attributes.description_ar),
        }
        const parentId = findItem.attributes.parent_id !== null ? truthyValue(findItem.attributes.parent_id) : ""
        const selectedParentDepartment = this.state.optionsArray.find((inputVal : any) => inputVal.id === parentId)
        if(selectedParentDepartment) {
            this.setState({ parentDepartmentId : truthyValue(selectedParentDepartment.id)})
        }
        this.setState({ editDepartmentFormFields: editFormValues })
      }

    }
  }

  handleEditDepartmentApiResponse = (responseJson: any) => {
    if(responseJson && responseJson.message) {
      const msg_en = truthyValue(responseJson.message.en)
      const msg_ar = truthyValue(responseJson.message.ar)
      this.setState({ msg_update_ar: msg_ar, msg_update_en: msg_en})
      this.openSuccessUpdateModal()
    } else {
       this.openErrorUpdatedModal()
    }
}

  handleOptionsDepartmentArrayResponse = (responseJson: any) => {
    if (responseJson && responseJson.departments && responseJson.departments.length > 0) {
      const receivedArray = truthyValue(responseJson.departments)
      const modifiedArray = receivedArray.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })

      this.setState({ optionsArray: modifiedArray })

    } else {
      this.setState({ optionsArray: [] })
    }
  }

  initialFormFields: DepartmentFormFields = {
    department_name_en : "",
    department_name_ar : "",
    department_desc_en : "",
    department_desc_ar : "",
  }

  initialErrorFields: DepartmentFormErrorFields = {
    department_name_en : false,
    department_name_ar : false,
    department_desc_en : false,
    department_desc_ar : false,
  }

  listDepartmentData  = async () => {
      const tokenvalue = await getStorageData('token')
      const header = {
          token: tokenvalue
      }

      this.listDepartmentApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.httpGetMethod,
          endPoint: configJSON.getDepartmentsApiEndPoint,
      })
  }

  getOptionsDepartmentData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.departmentsOptionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.dropdownOptionsDepartmentApiEndPoint,
      method: configJSON.httpGetMethod,
    })
  }

  updateDepartmentInfo = async () => {
    if(!this.handleCheckValidation()) {
      return false;
   }

    const tokenvalue = await getStorageData('token')
    const header = {
      'Content-Type': 'application/json',
      token: tokenvalue,
    }

    const httpBody = {
      "department": {
        "name": this.state.editDepartmentFormFields.department_name_en,
        "name_ar": this.state.editDepartmentFormFields.department_desc_ar,
        "description_ar": this.state.editDepartmentFormFields.department_desc_ar,
        "description_en": this.state.editDepartmentFormFields.department_desc_en,
        ...(this.state.parentDepartmentId !== "" && { "parent_id" : this.state.parentDepartmentId})
      }
    }

    this.editDepartmentApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.httpPatchMethod,
      endPoint: `${configJSON.editDepartmentApiEndPoint}/${this.state.departmentId}`,
      body: JSON.stringify(httpBody)
    })
  }
 
  setStylesBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.selectedLanguage === "ar" ? firstValue : secondValue;
  };

  handleEditDepartmentChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    if(value.trim() === "") {
      this.setState({ editDepartmentErrorFields: {...this.state.editDepartmentErrorFields, [name] : true}})
    } else {
      this.setState({ editDepartmentErrorFields: {...this.state.editDepartmentErrorFields, [name] : false}})
    }

    this.setState({ editDepartmentFormFields : {...this.state.editDepartmentFormFields, [name]: value}})
  }

  handleSelectDepartmentChange = (event : React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string
    this.setState({ parentDepartmentId: val})
  }

  handleCheckValidation = () => {
      let checlAllErrorFields = {
        department_name_en : false,
        department_name_ar : false,
        department_desc_en : false,
        department_desc_ar : false,
      }
       
      if (this.state.editDepartmentFormFields.department_name_en.trim() === "") {
        checlAllErrorFields.department_name_en = true;
      }
      if (this.state.editDepartmentFormFields.department_name_ar.trim() === "") {
        checlAllErrorFields.department_name_ar = true;
      }
      if (this.state.editDepartmentFormFields.department_desc_en.trim() === "" ) {
        checlAllErrorFields.department_desc_en = true;
      }
      if (this.state.editDepartmentFormFields.department_desc_ar.trim() === "") {
        checlAllErrorFields.department_desc_ar = true;
      }
  
      this.setState({ editDepartmentErrorFields: checlAllErrorFields})
  
      return Object.values(checlAllErrorFields).every((value) => value === false);
      
    }

  openSuccessUpdateModal = () => {
    this.setState({ successUpdateModal: true })
  }

  closeSuccessUpdateModal = () => {
    this.setState({ successUpdateModal: false }, () => this.navToOrgTablePage())
  }

  navToOrgTablePage = () => {
    this.props.navigation.navigate("OrganisationHierarchy")
  }

  openErrorUpdatedModal = () => {
    this.setState({ errorUpdateModal : true})
  }

  closeErrorUpdatedModal = () => {
    this.setState({ errorUpdateModal : false})
  }

  // Customizable Area End
}

// Customizable Area End