import React from "react";

import { Dialog, styled, Typography} from "@material-ui/core"
import { ErrorModalProps } from "./types";
import { errorIcon } from "../../blocks/organisationhierarchy/src/assets";
import { I18n } from "./languageJson/i18n" 
import { getValueBasedOnLanguage } from "./constant";


const ErrorModal : React.FC<ErrorModalProps> = ({ open, handleClose, msg_ar, msg_en}) => {

   const arabicLayout = localStorage.getItem("GET_SELECTED_LANGUAGE") === "ar" ? true : false
   const messageDispplay = (msg_ar && msg_en)  ? arabicLayout ? msg_ar : msg_en  : I18n.t("errorModalMsg")
    return (
        <ErrorDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
        >
            <div>
                <div style={{ textAlign: 'center' }}>
                    <img src={errorIcon} alt="check_mark" />
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px', }}>
                    <Typography style={{ fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")}} className="setEmpText">{messageDispplay}</Typography>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleClose} className="viewEmpButton">{I18n.t("tryAgain")}</button>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleClose} className="cancelBtn">{I18n.t("cancel")}</button>
                </div>
            </div>
        </ErrorDialog>

    )
}

const ErrorDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      maxWidth: "400px"
    }
  },
  '& .setEmpText':{
    fontWeight:700,
  },
  '& .viewEmpButton': {
    width: '100%',
    padding: '10px',
    marginTop:'20px',
    fontSize: '18px',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 600,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  },
  "& .cancelBtn" : {
    width: '100%',
    padding: '10px',
    marginTop:'10px',
    fontSize: '18px',
    color: '#527FE2',
    background: '#fff',
    border: '1px solid #527FE2',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 600,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  }
});

export default ErrorModal

