import React, {createRef} from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Paper, FormControl, OutlinedInput, IconButton
} from '@material-ui/core';
import { FilterList, AttachFile, Mic, Schedule, Person, Storefront, Check, Close } from '@material-ui/icons';
import { styled } from "@material-ui/styles";
import { pdfIcon, userIcon, profileimg, bro_2x, send_icon} from "./assets";
import AppNavigation from '../../../components/src/AppNavigation.web';
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import DocumentViewerPopup from "../../../components/src/DocumentViewerPopup.web";
import { I18n } from "../../../components/src/languageJson/i18n";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CustomDropdownIcon from '../../../components/src/CustomDropdownIcon';
import { CommonBoxStyles, getCommonMenuProps, getValueBasedOnLanguage as showArabicModeCSS } from '../../../components/src/constant';

import TicketDetailsController, {
    Props,
} from "./TicketDetailsController.web";

const StyledTextField = styled(TextField)({
    "& input": {
        fontSize: "16px", 
        fontFamily: '"Poppins", sans-serif',
      },
    "& .MuiInputBase-input::placeholder": {
        color: "#94A3B8", 
        opacity: 1,
        fontSize: "16px",
        fontFamily: '"Poppins", sans-serif',
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "10px", 
        "& fieldset": {
          borderColor: "#94A3B8", 
        },
        "&:hover fieldset": {
          borderColor: "#527FE2", 
        },
        "&.Mui-focused fieldset": {
          borderColor: "#527FE2", 
        },
      },
  });

const ScrollableBox = styled('div')({
    flex: 1,
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    '&::-webkit-scrollbar': {
      width: '10px', 
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#527FE2', 
      borderRadius: '10px', 
      border: '2px solid #527FE2',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent', 
      borderRadius: '10px', 
    },
});

const SelectMain = styled(Select)({
    '& .MuiSelect-outlined': {
      backgroundColor: 'transparent',
    },
  });  

const StyledFormControl = styled(FormControl)({
    "& .MuiOutlinedInput-root": {
        borderRadius: "10px", 
        "& fieldset": {
          borderColor: "#94A3B8", 
        },
        "&:hover fieldset": {
          borderColor: "#527FE2", 
        },
        "&.Mui-focused fieldset": {
          borderColor: "#527FE2", 
        },
      },
    "& .MuiSelect-outlined.MuiSelect-outlined" : {
        paddingRight : "10px"
    }
});

const MenuItemSet = styled(MenuItem)({
    padding: '10px',
    '&:hover': {
        backgroundColor: '#DBEAFE',
    },
})

const StyledCommentBox = styled(Box)(
    {
        paddingRight:showArabicModeCSS(0,10),
        paddingLeft: showArabicModeCSS(10, 0),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        overflowY: 'auto',
        minHeight:'427px',
        maxHeight:'calc(100vh - 200px)',
        '&::-webkit-scrollbar': {
            width: '6px',
            borderRadius: '10px', 
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#527FE2',
            borderRadius: '10px', 
            border: '2px solid #527FE2',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor:'#f1f1f1',
            borderRadius: '10px',
            width:'6px'
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent', 
          }, 
    }
)

const MarkDoneButton = styled(Button)({
    color: "white",
    borderRadius: "8px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    fontWeight:700,
    fontSize: "16px",
    padding: "10px 16px 10px 16px",
    maxWidth: "306px",
    width:"306px",
    lineHeight:"24px",
    height: "44px",
    "&:hover": {
      color: "white", backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
      fontSize:"18px",padding: "10px 16px 10px 16px",
    },
    
  });

const SubFieldRows = styled(Box)({
    display:"flex",alignItems:"center",gap:"5px",flexWrap:"wrap"
                
  });

const SubFieldsText = styled(Typography)({
      fontSize:"14px",
      fontWeight:700,
      paragraph:"8px",
      color:"#64748B",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
  });

  const ProfileCatTextStatus = styled(Typography)({
    fontWeight:700, 
    borderRadius:"15px", 
    padding:"1px 25px"
  })

  const ProfileCatTextDes = styled(Typography)({
    marginBottom:'20px !important',
    fontWeight:400,
    fontSize:"16px",
    color:"#334155",
    display:"flex",
    alignItems: "flex-start", 
    flexDirection: "column", 
    overflowY: "auto", 
    overflowX:'hidden',
    columnGap:"8px",
    paddingBottom:'2px',
    maxHeight:'75px',
    paddingLeft : showArabicModeCSS(15,0),
    paddingRight: showArabicModeCSS(0,15),
    fontFamily: showArabicModeCSS("Noto Sans Arabic", "Poppins"),
    textAlign: "justify",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
    '&::-webkit-scrollbar': {
    width: '6px',
    borderRadius: '10px', 
    },
    '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#527FE2',
    borderRadius: '10px', 
    border: '2px solid #527FE2',
    },
    '&::-webkit-scrollbar-track': {
    backgroundColor:'#f1f1f1',
    borderRadius: '10px',
    },
    '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent', 
    }, 
})  
  
  const ProfileCatText = styled(Typography)({
    fontWeight:400,
    fontSize:"14px",
    color:"#334155",
    display:"flex",
    alignItems:"center",
    columnGap:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },})  

    const SubFieldsTextTitle = styled(Typography)({
        fontSize:"16px",
        fontWeight:700,
        paragraph:"8px",
        variant:"h6",
        marginBottom:"5px"
  });

class TicketDetails extends TicketDetailsController {
    constructor(props: Props) {
        super(props);
    }

    theme = createTheme({
        typography: {
            fontFamily:  this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins")
        },
    });

    handleCommentKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); 
            this.handleSendComment(); 
        }
    };
    

    getStatusStyles = (status: string | undefined) => {
        switch (status) {
            case "Pending":
                return { color: "#D97706", backgroundColor: "#FEF3C7" }; 
            case "In Progress":
                return { color: "#527FE2", backgroundColor: "#BFDBFE" }; 
            case "Finished":
                return { color: "#059669", backgroundColor: "#D1FAE5" }; 
            case "Reviewing":
                return {color:"#6A1B9A", backgroundColor:"#E1BEE7",}
            default:
                return {}; 
        }
    };

    getDecodedFileName() {
        return decodeURIComponent(this.state.selectedTicket?.attributes?.attachment?.split("/").pop() || '');
    }
    
    render() {

        // Use the function
        const decodedFileName = this.getDecodedFileName();
        
        return (
            <ThemeProvider theme={this.theme}>
                <div style={{ display: 'flex', flexDirection: this.getValueBasedOnLanguage('row-reverse', "row")}}>
                    {/* Sidebar Navigation */}
                    <AppNavigation />

                    {/* Main Content */}
                    <Box className={this.getValueBasedOnLanguage("arabic-text", "default-text")} style={{
                fontFamily:'"Poppins", sans-serif', 
                flex: 1, display: 'flex', 
                flexDirection: 'column', 
                padding: '50px 20px', 
                background: '#F1F5FC',
                marginRight:this.getValueBasedOnLanguage("325px", undefined)
            }}>
            <ToastContainer />
            <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("tickets")]}/>
                <Box style={{ display: 'flex', flexDirection:'column', justifyContent:'center'}}>
                    <Box style={{ 
                        display: 'flex',
                        minWidth:'calc(100vw - 385px',
                        maxHeight:'766px',
                        minHeight:'766px',
                        flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row'),
                    }}>
                
                        {/* Left Section - Ticket Details */}
                        <Paper style={{
                            minWidth:'500px',
                            width:'50%',
                            padding: '24px',
                            borderRadius: this.getValueBasedOnLanguage('0 20px 20px 0', '20px 0 0 20px'),
                            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                            borderRight:this.getValueBasedOnLanguage('none', '1px solid #CBD5E1'),
                            borderLeft:this.getValueBasedOnLanguage('1px solid #CBD5E1', 'none'),
                            textAlign: this.getValueBasedOnLanguage('right', 'left'),
                            direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
                        }}>
                        {/* Ticket Details Header */}
                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom:'16px',textDecoration:"none", color:"inherit" }}>
                            <Box
                                style={{
                                    width: '56px',
                                    height: '56px',
                                    borderRadius: '50%',
                                    overflow: 'hidden', 
                                    margin: this.getValueBasedOnLanguage('0 0 0 15px', '0 15px 0 0'),
                                    backgroundColor: '#f0f0f0',
                                }}
                            >
                                <img
                                    src={this.state.selectedTicket?.attributes?.photo?.trim() || profileimg}
                                    alt="User Profile Img"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        display: 'block',
                                    }}
                                />
                            </Box>
                            <Box>
                                <SubFieldsTextTitle data-test-id="ticketName" variant="h6" style={{fontSize:"20px"}} >{this.state.selectedTicket?.attributes?.name}</SubFieldsTextTitle>
                                <SubFieldRows >
                                    <SubFieldsText > {I18n.t('id')}: </SubFieldsText>
                                    <ProfileCatText data-test-id="ticketId" >#{this.state.selectedTicket?.id}</ProfileCatText>
                                </SubFieldRows>
                            </Box>
                        </Box>
                        <SubFieldsTextTitle variant="h6" style={{fontSize:"20px"}} >{this.state.selectedTicket?.attributes?.title}</SubFieldsTextTitle>
                        <SubFieldRows >
                        <SubFieldsText >{I18n.t('date')}: </SubFieldsText>
                        <ProfileCatText data-test-id="ticketDate">{this.state.selectedTicket?.attributes?.createdAt}</ProfileCatText>
                        </SubFieldRows>
                        <SubFieldRows style={{marginTop:'4px', marginBottom:'4px'}} >
                        <SubFieldsText> {I18n.t('status')}:</SubFieldsText>
                        <ProfileCatTextStatus data-test-id="ticketStatus" style={this.getStatusStyles(this.state.selectedTicket?.attributes?.status)}>{this.state.selectedTicket?.attributes?.status}</ProfileCatTextStatus>
                        </SubFieldRows>
                        <SubFieldRows >
                        <SubFieldsText > {I18n.t('category')}: </SubFieldsText>
                        <ProfileCatText data-test-id="ticketCategory" >{this.capitalizedWord(this.state.selectedTicket?.attributes?.category || '')}</ProfileCatText>
                        </SubFieldRows>

                        {/* Ticket Description */}
                        <SubFieldsTextTitle variant="h6" style={{fontSize:"20px", marginTop:"10px"}} >{I18n.t('description')}</SubFieldsTextTitle>
                        <ProfileCatTextDes data-test-id="ticketDescription" style={{marginBottom:"15px"}}>{this.state.selectedTicket?.attributes?.description}</ProfileCatTextDes>

                        {/* Attachment */}
                        {
                             this.state.selectedTicket?.attributes?.attachment && (
                            <Box 
                                data-test-id="filePopupButton"
                                style={{  
                                    display: 'flex',  
                                    alignItems: 'center',  
                                    gap: '12px',  
                                    background: '#F1F5F9',  
                                    borderRadius: '8px',  
                                    padding: '12px 16px',  
                                    border: '1px solid #CBD5E1',  
                                    marginBottom: '20px',
                                    cursor: "pointer", 
                                }}
                                onClick={() =>
                                    this.showFilePopup(
                                      // @ts-ignore
                                      this.state.selectedTicket?.attributes?.attachment,
                                      this.state.selectedTicket?.attributes?.attachment
                                        ?.split("/")
                                        .pop() || ""
                                    )
                                }
                            >
                                <img  
                                    src={pdfIcon}
                                    alt="PDF Icon"  
                                    style={{ width: '32px', height: '32px' }}  
                                />  
                                   
                                <Box style={{ display: 'flex', flexDirection: 'column', fontFamily:'"Poppins", sans-serif', width:'100%', overflow:'hidden' }}>  
                                    <Typography data-test-id="ticketAttachment"
                                        title={decodedFileName}
                                        style={{
                                            width:'100%',
                                            textOverflow: "ellipsis",
                                            whiteSpace:'nowrap',
                                            overflow:'hidden',
                                            display:'block'
                                        }}
                                    >  
                                    {decodedFileName}
                                    </Typography>  
                                    <Typography data-test-id="ticketAttachmentSize" style={{ fontSize: '12px', color: '#94A3B8' }}>  
                                    2MB  
                                    </Typography>  
                                </Box>
                                
                            </Box>
                            )
                        }

                        {/* Action Fields */}
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        {/* Allocation Field */}
                        <Box style={{ position: 'relative', }}>
                        <StyledTextField
                            style={{
                                color: this.state.selectedUser ? 'currentColor' : '#94A3B8',
                                fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins")
                            }}
                            data-test-id="ticketAllocatedTo"
                            variant="outlined"
                            placeholder={I18n.t('allocateUserPlaceholder')}
                            fullWidth
                            onClick={this.handleDropdownToggle}
                            value={
                                this.state.isAllocationDropdownOpen
                                  ? this.state.searchQuery 
                                  : this.state.selectedUser 
                              }
                            onChange={this.handleSearchChange}
                            InputProps={{
                            style: {
                                borderRadius: '10px', 
                            },
                              
                            startAdornment: (
                                <InputAdornment position="start"
                                    style={{
                                        margin: this.getValueBasedOnLanguage('0 -8px 0 8px', '0 8px 0 0'),
                                    }}
                                >
                                <Person style={{ 
                                    color: '#94A3B8',
                                    }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" style={{
                                    marginRight: '-5px',
                                    margin: this.getValueBasedOnLanguage('0 0 0 -5px', '0 -5px 0 0')
                                }}>
                                <FilterList style={{ cursor: 'pointer', color: '#94A3B8',}} />
                                </InputAdornment>
                            ),

                            }}
                        />

                        {/* Dropdown Menu */}
                        {this.state.isAllocationDropdownOpen && (
                            <div 
                                ref={this.dropdownRef}
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    right: 0,
                                    display: 'flex',
                                    background: '#FFFFFF',
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                    borderRadius: '8px',
                                    border:'none',
                                    zIndex: 10,
                                    marginTop: '4px',
                                    gap:'8px'
                                }}>
                            {/* Left Column: Users */}
                            <ScrollableBox>
                                { this.getFilteredUsers().length > 0 ? (
                                        this.getFilteredUsers().map(user => (
                                        <Box
                                            data-test-id="ticketUsers"
                                            key={user.id}
                                            style={{
                                                borderBottom: '1px solid #E2E8F0', 
                                                padding: '8px 12px',
                                                cursor: 'pointer',
                                                background: this.state.selectedUserId === user.id ? '#E5E7EB' : 'transparent',
                                            }}
                                            onClick={() => this.handleUserSelect(user.id)}
                                            onMouseEnter={(e) => {
                                                const target = e.currentTarget as HTMLElement; 
                                                target.style.background = '#DBEAFE'; 
                                            }}
                                            onMouseLeave={(e) => {
                                                const target = e.currentTarget as HTMLElement; 
                                                target.style.background =
                                                this.state.selectedUserId === user.id ? '#E5E7EB' : 'transparent';
                                            }}
                                            fontSize='16px'
                                        >
                                            {user.name}
                                        </Box>
                                        ))
                                    ) : (
                                        <Box
                                            style={{
                                                padding: '12px',
                                                textAlign: 'center',
                                                color: '#94A3B8',
                                                fontSize: '14px',
                                                fontFamily: '"Poppins", sans-serif',
                                            }}
                                            >
                                            No team members available for assignment
                                        </Box> 
                                    )
                                }
                            </ScrollableBox >

                            {/* Right Column: Departments */}
                            <ScrollableBox >
                            {this.state.departments.map(department => (
                                <Box
                                data-test-id="ticketDepartments"
                                key={department.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    padding: '8px 0 8px 8px',
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                    borderBottom: '1px solid #E2E8F0', 
                                }}
                                onClick={() => this.handleDepartmentSelect(department.id)}
                                >
                                {/* Checkbox */}
                                <input
                                    data-test-id="ticketDepartmentCheckbox"
                                    type="checkbox"
                                    checked={this.state.selectedDepartment === department.id}
                                    readOnly
                                    style={{
                                        visibility: 'hidden', 
                                        position: 'absolute',
                                    }}
                                />
                                 <span
                                    style={{
                                        padding:'1px',
                                        border: '1px solid #CBD5E1', 
                                        borderRadius: '6px', 
                                        display: 'flex', 
                                        width:'20px',
                                        height:'20px',
                                        alignItems: 'center', 
                                        justifyContent: 'center', 
                                        backgroundColor: this.state.selectedDepartment === department.id ? '#527FE2' : 'white',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => this.handleDepartmentSelect(department.id)} 
                                >
                                    {this.state.selectedDepartment === department.id && (
                                        <Check 
                                            style={{
                                                fontWeight:400,
                                                color:'white',
                                            }}
                                        />
                                    )}
                                </span>
                                {/* Department Name */}
                                <Typography data-test-id="ticketDepartmentName" style={{ fontSize: '16px', fontFamily: '"Poppins", sans-serif' }}>
                                    {department.name}
                                </Typography>
                                </Box>
                            ))}
                            </ScrollableBox>

                            </div>
                        )}
                        </Box>
                        <StyledFormControl variant="outlined" fullWidth
                        >
                            <SelectMain
                                style={{ borderRadius: '10px', fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins")}}
                                MenuProps={getCommonMenuProps}
                                data-test-id="ticketBrand"
                                value={this.state.selectedBrand}
                                onChange={this.handleBrandChange}
                                displayEmpty
                                fullWidth
                                open={this.state.isBrandDropdownOpen}
                                onClose={() => this.setState({ isBrandDropdownOpen: false })}
                                onOpen={() => this.setState({ isBrandDropdownOpen: true })}
                                IconComponent={CustomDropdownIcon}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Storefront style={{ color: '#94A3B8' }} />
                                    </InputAdornment>
                                }
                                renderValue={(value) => {
                                    const selectedBrand = this.state.brands.find((brand) => brand.id === value);
                                    return (
                                        <Box
                                            style={{
                                                color: selectedBrand ? 'currentColor' : '#94A3B8',
                                                ...CommonBoxStyles
                                            }}
                                        >
                                            {selectedBrand ? selectedBrand.name : I18n.t('brandPlaceHolder')}
                                        </Box>
                                    );
                                }}
                                input={<OutlinedInput />}
                            >
                                {this.state.brands.map((brand, index) => (
                                    <MenuItemSet
                                        style={{
                                            borderBottom: '1px solid #E2E8F0',
                                            fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins")
                                        }}
                                        className="setMenuItem"
                                        key={index}
                                        value={brand?.id}
                                    >
                                        {brand.name}
                                    </MenuItemSet>
                                ))}
                            </SelectMain>
                        </StyledFormControl>
                        
                        <StyledFormControl variant="outlined" fullWidth>
                            <SelectMain
                                style={{ borderRadius: '10px', fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins") }}
                                MenuProps={getCommonMenuProps}
                                data-test-id="ticketTime"
                                value={this.state.selectedTime}
                                onChange={this.handleTimeChange}
                                fullWidth
                                displayEmpty
                                open={this.state.isTimeDropdownOpen}
                                onClose={() => this.setState({ isTimeDropdownOpen: false })}
                                onOpen={() => this.setState({ isTimeDropdownOpen: true })}
                                IconComponent={CustomDropdownIcon}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Schedule style={{ color: '#94A3B8' }} />
                                    </InputAdornment>
                                }
                                renderValue={(value) => (
                                    <Box
                                        style={{
                                            color: value ? 'currentColor' : '#94A3B8',
                                            ...CommonBoxStyles,
                                        }}
                                    >
                                         {value ? String(value) : I18n.t('timePlaceHolder')}
                                    </Box>
                                )}
                                input={<OutlinedInput />}
                            >
                                {/* Options */}
                                <MenuItemSet style={{ borderBottom: '1px solid #E2E8F0', fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins")}} value="3 Hrs">
                                    3 {I18n.t("hrsText")}
                                </MenuItemSet>
                                <MenuItemSet style={{ borderBottom: '1px solid #E2E8F0', fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins") }} value="12 Hrs">
                                    12 {I18n.t("hrsText")}
                                </MenuItemSet>
                                <MenuItemSet style={{ borderBottom: '1px solid #E2E8F0', fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins") }} value="48 Hrs">
                                    48 {I18n.t("hrsText")}
                                </MenuItemSet>
                                <MenuItemSet style={{ borderBottom: '1px solid #E2E8F0', fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins") }} value="5 days">
                                    5 {I18n.t("daysText")}
                                </MenuItemSet>
                            </SelectMain>
                        </StyledFormControl>

                        
                        </Box>
                        
                        </Paper>

                                {/* Right Section - Comments */}
                                <Paper
                                style={{
                                    minWidth:'500px',
                                    width: '50%',
                                    padding: '24px 24px 24px 24px',
                                    borderRadius: this.getValueBasedOnLanguage('20px 0 0 20px', '0 20px 20px 0'),
                                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                    direction: showArabicModeCSS("rtl","ltr")
                                }}
                                >
                                <Typography variant="h6" style={{ 
                                    fontWeight: 700, 
                                    fontSize:'20px',
                                    lineHeight:'26px',
                                    marginBottom: '16px',
                                    textAlign: this.getValueBasedOnLanguage('right', 'left'),
                                    }}>
                                     {I18n.t('comments')}
                                </Typography>
                                <StyledCommentBox>
                                    {/* Map over messages in state */}
                
                                    { 
                                        !this.state.selectedTicketComments || this.state.selectedTicketComments?.length === 0 
                                            ?   (
                                                <Box
                                                    style={{
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        height:'100%'
                                                    }}
                                                >
                                                    <Box
                                                    style={{
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        gap:'10px'
                                                    }}
                                                >
                                                    <img  
                                                        src={bro_2x}
                                                        alt="placeholder image"  
                                                        style={{ width: '115px', height: '116px' }}  
                                                    />  
                                                    <Typography 
                                                        style={{
                                                            lineHeight:'22px',
                                                            fontSize:'16px',
                                                            color:'#334155'
                                                        }}
                                                    >
                                                        {I18n.t('noCommentsAdded')}
                                                    </Typography>
                                                </Box>
                                                </Box>
                                                
                                            )
                                            :   this.state.selectedTicketComments?.map((comment, index) => (
                                        <Box
                                            data-test-id="commentItem"
                                            key={index}
                                            style={{
                                            display: 'flex',
                                            flexDirection: comment.attributes.account?.role_id === '1' ? 'row-reverse' : 'row',
                                            gap: '8px',
                                            marginLeft: comment.attributes.account?.role_id === "1" ? "45px" : "0",
                                            marginRight: comment.attributes.account?.role_id === "2" ? "45px" : "0",
                                            }}
                                        >
                                            <Box
                                            style={{
                                                width: '38px',
                                                minWidth:'38px',
                                                height: '38px',
                                                minHeight:'38px',
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                backgroundColor: '#F1F5F9',
                                            }}
                                            >
                                            <img
                                                src={comment.attributes.account?.role_id === '2' ? this.state.selectedTicket?.attributes?.photo || profileimg : this.getMerchantProfileImg() || profileimg}
                                                alt="Profile"
                                                style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                }}
                                            />
                                            </Box>
                                            <Box
                                                style={{
                                                    backgroundColor: comment.attributes.account?.role_id === '1' ? 'rgb(224, 231, 255)' : 'rgb(226, 232, 240)'
                                                    ,
                                                    padding: '8px 16px 16px 16px',
                                                    borderRadius: comment.attributes.account?.role_id === '1' ? '8px 1px 8px 8px' : '1px 8px 8px 8px',
                                                    maxWidth: '100%',
                                                    flex:1
                                                }}
                                            >
                                                <span style={{
                                                    display:'flex', 
                                                    justifyContent:'space-between', 
                                                    alignItems:'center', 
                                                    gap:'15px',
                                                    flexDirection: comment.attributes.account?.role_id === '1' ? 'row-reverse' : 'row',
                                                    }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '20px',
                                                            fontWeight: 700,
                                                            marginBottom: '4px',
                                                            lineHeight:'26px'
                                                        }}
                                                    >
                                                    {comment.attributes.account?.role_id === '1' ? this.getMerchantName() : this.state.selectedTicket?.attributes?.name}
                                                    </Typography>
                                                    
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#94A3B8',
                                                            fontWeight: 400,
                                                            lineHeight:'20px', 
                                                            direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
                                                        }}
                                                    >
                                                    {comment.attributes.created_at}
                                                    </Typography>
                                                </span>
                                            
                                                <Box> 
                                                    
                                                    {
                                                    comment.attributes?.attachments?.length > 0 && (
                                                        <Box style={{
                                                                display:'flex',
                                                                flexDirection:'column',
                                                                gap:'10px',
                                                                marginBottom:'6px',
                                                            }}
                                                            data-test-id="commentAttachmentsContainer"
                                                        >
                                                            
                                                            {comment.attributes.attachments &&
                                                                comment.attributes.attachments.map((attachment:any, index) => (
                                                                    <Box 
                                                                        data-test-id="filePopupButton2"
                                                                        style={{  
                                                                                display: 'flex',  
                                                                                alignItems: 'center',  
                                                                                gap: '12px',  
                                                                                background: 'transparent',  
                                                                                borderRadius: '8px',  
                                                                                padding: '12px 16px',  
                                                                                border: '1px solid #94A3B8', 
                                                                                cursor: "pointer", 
                                                                            }}
                                                                        onClick={() =>
                                                                            this.showFilePopup(
                                                                              // @ts-ignore
                                                                              attachment?.url,
                                                                              attachment?.url
                                                                                ? attachment.url
                                                                                    .split("/")
                                                                                    .pop()
                                                                                : ""
                                                                            )
                                                                        }
                                                                    >
                                                                        <img  
                                                                            src={pdfIcon}
                                                                            alt="PDF Icon"  
                                                                            style={{ width: '32px', height: '32px' }}  
                                                                        />  
                                                                        <Box style={{ display: 'flex', flexDirection: 'column', fontFamily:'"Poppins", sans-serif' }}>  
                                                                            <Typography data-test-id="commentAttachment"
                                                                                style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                                                                            >
                                                                                {attachment?.url
                                                                                ? attachment.url
                                                                                    .split("/")
                                                                                    .pop()
                                                                                    .replace(/%20/g, " ")
                                                                                : ""}
                                                                            </Typography>
                                                                            <Typography data-test-id="ticketAttachmentSize" style={{ fontSize: '12px', color: '#94A3B8' }}>  
                                                                            2MB  
                                                                            </Typography>  
                                                                        </Box>
                                                                    </Box>
                                                                ))
                                                            }
                                                            
                                                        </Box>
                                                    )
                                                    }
                                                    
                                                    <Typography style={{ 
                                                            fontSize: '14px',
                                                            color: '#334155',
                                                            textAlign: this.getValueBasedOnLanguage('right', 'left'), 
                                                            direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
                                                        }}>
                                                    {comment.attributes.comment}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                                ))}

                                </StyledCommentBox>

                                {/* Input Field for Adding Comments */}
                                <Box
                                style={{
                                    display: 'flex',
                                    flexDirection:'column',
                                    padding: '8px 22px 8px 10px',
                                    borderRadius: '24px', 
                                    backgroundColor: '#EFF6FF', 
                                    border: '1px solid #CBD5E1',
                                    marginTop: '16px',
                                    minWidth:'470px'
                                }}
                                >
                                    {this.state.attachments.length > 0 && (
                                        <Box
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "8px",
                                            paddingLeft:'12px',
                                            marginBottom:'10px'
                                        }}
                                        >
                                        {this.state.attachments.map((file, index) => (
                                            <Box
                                            key={index}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "5px 6px 5px 4px",
                                                borderRadius: "8px",
                                                border:'1px solid black',
                                                gap:'5px',
                                                width:'100%',
                                                boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
                                                justifyContent:'space-between'
                                            }}
                                            >
                                            <Box style={{
                                                display:'flex',
                                                gap:'5px'
                                            }}
                                            >
                                                <img
                                                    src={pdfIcon} 
                                                    alt="File Icon"
                                                    style={{ width: "24px", height: "24px",}}
                                                />
                                                <Typography style={{ fontSize: "14px", fontWeight: 500, marginRight:'4px' }}>
                                                    {file.name} ({(file.size / 1024).toFixed(1)} KB)
                                                </Typography>
                                            </Box>
                                            
                                            <IconButton
                                                data-test-id="removeAttachmentButton"
                                                onClick={() => this.handleRemoveAttachment(index)}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    backgroundColor: "#F56565",
                                                    color: "#FFFFFF",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                >
                                                <Close style={{ fontSize: "18px" }} />
                                            </IconButton>
                                            </Box>
                                        ))}
                                        </Box>
                                    )} 
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            }}
                                    >
                                        
                                {/* Text Input */}
                                <input
                                    type="text"
                                    placeholder={I18n.t('typeComment')}
                                    value={this.state.newComment}
                                    onChange={(e) => this.setState({ newComment: e.target.value})}
                                    style={{
                                        flex: 1, 
                                        border: 'none',
                                        outline: 'none',
                                        padding: '2px 12px',
                                        fontSize: '14px',
                                        color: '#334155',
                                        backgroundColor: 'transparent', 
                                        fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
                                        textAlign: this.getValueBasedOnLanguage('right', 'left'), 
                                        direction: this.getValueBasedOnLanguage('rtl', 'ltr'),
                                    }}
                                    onKeyDown={this.handleCommentKeyDown}
                                />

                                {/* Attachment Icon */}
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*,video/*,application/pdf"
                                    onChange={this.handleAttachmentChange}
                                    style={{
                                        display: 'none',
                                    }}
                                    id="commentAttachmentInput"
                                    />
                                    <label htmlFor="commentAttachmentInput" style={{ cursor: 'pointer' }}>
                                    <AttachFile
                                        style={{
                                        color: '#64748B',
                                        cursor: 'pointer',
                                        }}
                                    />
                                    </label>

                                {/* Mic Icon */}
                                <div 
                                    style={{
                                        marginRight:'8px',
                                        position: 'relative',
                                        display: 'inline-block',
                                        cursor: 'not-allowed', 
                                    }}
                                    title="WIP" 
                                    >
                                    <Mic
                                        style={{
                                        color: '#64748B',
                                        pointerEvents: 'none', 
                                        opacity: 0.5, 
                                        }}
                                    />
                                </div>

                                {/* Send Button */}
                                <Button
                                    data-test-id="sendCommentButton"
                                    style={{
                                    backgroundColor: '#527FE2',
                                    color: 'white',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    minWidth: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '0',
                                    }}
                                    onClick={this.handleSendComment}
                                >
                                     <img
                                        src={send_icon}
                                        alt="Send"
                                        style={{
                                            width: '32px', 
                                            height: '32px', 
                                        }}
                                    />
                                </Button>
                                    </Box> 
                                
                                </Box>

                                </Paper>

                                {this.state.isFilePopupVisible && (
                                    <DocumentViewerPopup
                                        fileUrl={this.state.selectedFileUrl}
                                        documentName={this.state.selectedDocumentName.replace(
                                        /%20/g,
                                        " "
                                        )}
                                        onClose={this.hideFilePopup}
                                    />
                                )}
                            </Box>
                            {/* Mark as Done Button */}
                            <Box data-test-id="ticketDoneBtn" 
                                style={{display:"flex",justifyContent:"center",margin:"1.3rem 3rem",}}> 
                                <MarkDoneButton
                                    onClick={this.markAsDone}
                                >{I18n.t('markAsDone')}</MarkDoneButton>
                                
                            </Box>

                        </Box>
                    </Box>
                </div>
            </ThemeProvider>  
        
        );
    }
}

export default TicketDetails;