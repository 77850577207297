import React from "react";

import { Dialog, styled, Typography} from "@material-ui/core"
import { DeleteModalProps } from "./types";
import { DeleteOutline } from "@material-ui/icons";
import { I18n } from "./languageJson/i18n" 
import { getValueBasedOnLanguage } from "./constant"


const DeleteModal : React.FC<DeleteModalProps> = ({ open, handleClose, handleDelete, msgTitle}) => {

  const arabicView = localStorage.getItem("GET_SELECTED_LANGUAGE") === "ar" ? true : false

    return (
        <DeleteDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
          >
            <div>
              <div style={{ textAlign: 'center' }}>
              <DeleteOutline style={{ color: 'red', width: '80px', height: '80px' }} />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px', direction: arabicView ? "rtl" : "ltr" }}>
                <Typography className="setEmpText">{msgTitle}</Typography>
                <Typography className="innerText">{I18n.t("deleteWarningMsg")}</Typography>
              </div>
              <div className="flexBtn" style={{ direction: arabicView ? "rtl" : "ltr"}}>
                <button className="deleteEmpButton" onClick={handleDelete}>{I18n.t("Delete")}</button>
                <button onClick={handleClose} className="cancleButton">{I18n.t("Cancel")}</button>
              </div>
            </div>
          </DeleteDialog>
    )
}

const DeleteDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      borderRadius: '10px'
    }
  },
  '& .setEmpText':{
    fontWeight:700,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  },
  '& .innerText':{
    fontWeight:400,
    color: '#94A3B8',
    marginTop: '10px',
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  },
  '& .flexBtn':{
    display: 'flex',
    gap: '10px',
    alignItems: 'end',
    justifyContent: 'end'
  },
  '& .deleteEmpButton':{
    padding: '10px 20px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: 'red',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 700,
    minWidth: "120px",
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  },
  '& .cancleButton': {
    minWidth: "120px",
    padding: '10px 20px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
  }
});


export default DeleteModal

