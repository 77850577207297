import React, { useEffect, useState } from "react";

import { Dialog, styled, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Checkbox, OutlinedInput } from "@material-ui/core"
import { FilterDialogProps, ReqManagementFiltersState } from "./types";
import { I18n } from "./languageJson/i18n" 
import { getValueBasedOnLanguage, CustomScrollbarStyle, CommonBoxStyles, getCommonMenuProps, MenuItemSet, SelectMain, StyledFormControl } from "./constant";
import { cancelIcon } from "../../blocks/accountgroups/src/assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { checkedIcon, unCheckedIcon } from "../../blocks/requestmanagement/src/assets";
import CustomDropdownIcon from "./CustomDropdownIcon";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { calendarIcon } from "../../blocks/catalogue/src/assets";
import moment from "moment";



const FilterDialog : React.FC<FilterDialogProps> = ({ 
    open,
    handleClose,
    currentPage,
    handleApplyFilters,
    reqManagementCatogoriesData = [],
    reqManagemntStatusData = [],
    reqManagemntDepartmentData = [],
    reqManagemntAssigneData = [],
    reqAppliedFiltersState = {}
    }) => {

    const [reqManagementFilters, setReqManagementFilters] = useState<ReqManagementFiltersState>({
        reqCreationDate: new Date(),
        reqSelectedCategory: [],
        reqSelectedStatus: [],
        reqSelectedDepartment: [],
        reqSelectedAssignee: "",
    });

    const [isDateChanged, setIsDateChanged] = useState(false)

    const handleReqManagementFilterChange = (
        key: keyof ReqManagementFiltersState,
        value: any
    ) => {
        setReqManagementFilters((prevFilters) => {
            const prevValue = prevFilters[key];

            if (Array.isArray(prevValue)) {
            
                const newValue = prevValue.includes(value as string)
                    ? prevValue.filter((item) => item !== value) 
                    : [...prevValue, value as string]; 

                return { ...prevFilters, [key]: newValue };
            }

            return { ...prevFilters, [key]: value }; 
        });
    };

    const makeArrayofStrforReqState = (value : any) => {
        let arrVal : string[] = []
        if(value){
          arrVal = value.split(",") 
        }
        return arrVal
      }

    const getDateFormat = (date : Date) => {
        return moment(date).format("YYYY-MM-DD");
    }
    
    useEffect(() => {
        const receivedDate = reqAppliedFiltersState.created_at
            ? getDateFormat(new Date(reqAppliedFiltersState.created_at))
            : null;
        const currentDate = getDateFormat(new Date())
        const dateFlag = receivedDate && receivedDate !== currentDate ? true : false
        const appliedFilters = {
            reqCreationDate: reqAppliedFiltersState.created_at ? new Date(reqAppliedFiltersState.created_at) : new Date(),
            reqSelectedCategory: makeArrayofStrforReqState(reqAppliedFiltersState.request_type),
            reqSelectedStatus: makeArrayofStrforReqState(reqAppliedFiltersState.status),
            reqSelectedDepartment: makeArrayofStrforReqState(reqAppliedFiltersState.department_id),
            reqSelectedAssignee: reqAppliedFiltersState.assigned_to ? reqAppliedFiltersState.assigned_to.toString() : "",
        }

        setReqManagementFilters(appliedFilters)
        setIsDateChanged(dateFlag)
    }, [reqAppliedFiltersState])

    const handleReqFiltersApply = () => {
        const { reqSelectedAssignee, reqSelectedDepartment, reqCreationDate , reqSelectedStatus, reqSelectedCategory} = reqManagementFilters

        const creatiopnDate = moment(reqCreationDate).format("YYYY-MM-DD");

        const appliedFilter = {
            ...reqSelectedCategory.length > 0 && { "request_type": reqSelectedCategory.toString() },
            ...reqSelectedDepartment .length > 0 && { "department_id": reqSelectedDepartment.toString() },
            ...reqSelectedAssignee !== "" && { "assigned_to": reqSelectedAssignee.toString() },
            ...reqSelectedStatus .length > 0 && { "status": reqSelectedStatus.toString() },
            ...isDateChanged && { "created_at": creatiopnDate },
        }

        handleApplyFilters(appliedFilter)
    }

    const handleClearAllFilter = () => {
        const initialReqManagementFiltersValue = {
            reqCreationDate: new Date(),
            reqSelectedCategory: [],
            reqSelectedStatus: [],
            reqSelectedDepartment: [],
            reqSelectedAssignee: "",
        }

        setReqManagementFilters(initialReqManagementFiltersValue)
        setIsDateChanged(false)
    }

    const renderRequestManagementFilters = () => {
        return (
            <AccordionBox>
                <Accordion key={1}  style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ minHeight: '40px', padding: '0' }}
                    >
                        <Typography className="accHeaderText">{I18n.t("catogoryText")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className="verticalFlexBox" >
                            {reqManagementCatogoriesData.map((item, index) =>
                                <Box className="flexBox" key={index}>
                                    <Checkbox
                                        icon={<img src={unCheckedIcon} />}
                                        checkedIcon={<img src={checkedIcon} />}
                                        checked={reqManagementFilters.reqSelectedCategory.includes(item.value)}
                                        value={item.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleReqManagementFilterChange("reqSelectedCategory" ,event.target.value)}
                                        disableFocusRipple
                                        disableRipple
                                        disableTouchRipple
                                    />
                                    <Typography className="subText">{item.key}</Typography>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion key={2} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ minHeight: '40px', padding: '0' }}
                    >
                        <Typography className="accHeaderText">{I18n.t("serviceCreationDate")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                fullWidth
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                value={reqManagementFilters.reqCreationDate}
                                onChange={(date) =>  {
                                    setIsDateChanged(true)
                                    handleReqManagementFilterChange("reqCreationDate",date)
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputAdornmentProps={{
                                    position: 'end',
                                }}
                                InputProps={{
                                    style: { width: '100%' },
                                }}
                                keyboardIcon={<img src={calendarIcon} />}
                            />
                        </MuiPickersUtilsProvider>
                    </AccordionDetails>
                </Accordion>

                <Accordion key={3} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ minHeight: '40px', padding: '0' }}
                    >
                        <Typography className="accHeaderText">{I18n.t("department")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className="verticalFlexBox scrollBox">
                            {reqManagemntDepartmentData.map(option =>
                                <Box className="flexBox" key={option.id}>
                                    <Checkbox
                                        icon={<img src={unCheckedIcon} />}
                                        checkedIcon={<img src={checkedIcon} />}
                                        checked={reqManagementFilters.reqSelectedDepartment.includes(option.id.toString())}
                                        value={option.id.toString()}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleReqManagementFilterChange("reqSelectedDepartment",event.target.value)}
                                        disableFocusRipple
                                        disableRipple
                                        disableTouchRipple
                                    />
                                    <Typography className="subText">{option.name}</Typography>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion key={4} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ minHeight: '40px', padding: '0' }}
                    >
                        <Typography className="accHeaderText">{I18n.t("assignee")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <StyledFormControl variant="outlined" fullWidth
                        >
                            <SelectMain
                                data-test-id="assignee"
                                MenuProps={getCommonMenuProps}
                                name={"assignee"}
                                displayEmpty
                                value={reqManagementFilters.reqSelectedAssignee}
                                onChange={(event) => {
                                    const val = event.target.value as string
                                    handleReqManagementFilterChange( "reqSelectedAssignee",val)}
                                } 
                                IconComponent={CustomDropdownIcon}
                                renderValue={(value) => {
                                    const selectedDepartment = reqManagemntAssigneData.find((option) => option.id.toString() === value);
                                    return (
                                        <Box
                                            style={{
                                                color: selectedDepartment ? 'currentColor' : '#94A3B8',
                                                ...CommonBoxStyles
                                            }}
                                        >
                                            {selectedDepartment ? selectedDepartment.name : I18n.t('assignee')}
                                        </Box>
                                    );
                                }}
                                input={<OutlinedInput />}
                            >
                                {reqManagemntAssigneData.map((optionVal, index) => (
                                    <MenuItemSet
                                        style={{
                                            borderBottom: '1px solid #E2E8F0',
                                            fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                        }}
                                        className="setMenuItem"
                                        key={index}
                                        value={optionVal.id.toString()}
                                    >
                                        {optionVal.name}
                                    </MenuItemSet>
                                ))}
                            </SelectMain>
                        </StyledFormControl>
                    </AccordionDetails>
                </Accordion>

                <Accordion key={5} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ minHeight: '40px', padding: '0' }}
                    >
                        <Typography className="accHeaderText">{I18n.t("status")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                         <Box className="verticalFlexBox">
                         {reqManagemntStatusData.map((status, index) =>
                                <Box className="flexBox" key={index}>
                                    <Checkbox
                                        icon={<img src={unCheckedIcon} />}
                                        checkedIcon={<img src={checkedIcon} />}
                                        checked={reqManagementFilters.reqSelectedStatus.includes(status.value)}
                                        value={status.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleReqManagementFilterChange( "reqSelectedStatus",event.target.value)}
                                        disableFocusRipple
                                        disableRipple
                                        disableTouchRipple
                                    />
                                    <Typography className="subText">{status.key}</Typography>
                                </Box>
                            )}
                         </Box>
                    </AccordionDetails>
                </Accordion>
            </AccordionBox>
        )
    }

    const filtersPages = {
        orgnazationManagement : "orgnazationManagement",
        requestManagement : "requestManagement",
        peopleManagement : "peopleManagement",
        serviceManagement : "serviceManagement",
    }

    const handleFiltersAccordingtoPage = () => {
        switch(currentPage) {
            case filtersPages.requestManagement :
                return renderRequestManagementFilters()
            default :
                return <></>
        }
    }

    const handleApplyBtn = () => {
        switch(currentPage) {
            case filtersPages.requestManagement :
                return handleReqFiltersApply()
            default :
                return false
        }
    }

    return (
        <FilterModal
            open={open}
            onClose={handleClose}
            data-test-id="filterModal"
            aria-labelledby="simple-dialog-title"
            dir={getValueBasedOnLanguage("rtl", "ltr")}
        >
            <Box className="mainBox">
                <Typography className="filterHeaderText">{I18n.t("filtersText")}</Typography>
                <Box className="clearBox">
                    <Typography className="clearHeaderText" onClick={handleClearAllFilter}>{I18n.t("clearAllText")}</Typography>
                    <img src={cancelIcon} onClick={handleClose} style={{ cursor: "pointer" }} alt="cross_icon" />
                </Box>
            </Box>

            {handleFiltersAccordingtoPage()}

            <div className="appyBtnBox">
                <button data-test-id='applyBtn' onClick={handleApplyBtn} className="applyBtn">
                    {I18n.t("applyBtn")}
                </button>
            </div>
        </FilterModal>
    )
}

const FilterModal = styled(Dialog)({
    position: "relative",
    '& .MuiDialog-container': {
        position: 'absolute',
        right: getValueBasedOnLanguage('auto', '0'),
        left: getValueBasedOnLanguage('0', 'auto'),
        '& .MuiPaper-root': {
            margin: '0',
            height: '100%',
            maxHeight: '100%',
            boxShadow: 'none',
            width: '444px',
            '@media (max-width: 500px)': {
                width: '350px',
            },
        },
        "& .mainBox": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "24px 16px 16px 16px",
            borderBottom: "1px solid #dddad6"
        },
        "& .clearBox": {
            display: "flex",
            gap: "15px",
            alignItems: "center"
        },
        "& .filterHeaderText": {
            fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
            fontWeight: 700,
            fontSize: "24px"
        },
        "& .clearHeaderText": {
            fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#527FE2',
            fontWeight: 400,
            fontSize: "16px",
            '&.clickedText': {
                transform: 'scale(0.95)',
                color: '#405ac6',
            },
        },
        '& .applyBtn': {
            color: 'white',
            padding: '10px 30px',
            border: 'none',
            borderRadius: '8px',
            minWidth: "150px",
            fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
            fontWeight: 700,
            background: '#527FE2',
            fontSize: "16px",
        },
        '& .appyBtnBox': {
            padding: '20px',
            bottom: 0,
            right: 0,
            position: 'absolute',
        },
    }
})

const AccordionBox = styled(Box)({
    width: '100%',
    '& .accHeaderText':{
      fontWeight: 700,
      color:'#64748B',
      fontSize: "18px",
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    "& .flexBox": {
        display: "flex",
        gap : "2px",
    },
    "& .verticalFlexBox": {
        display: "flex",
        gap : "10px",
        flexDirection: "column"
    },
    "& .scrollBox": {
        maxHeight: 170,
        width: "100%",
        overflow: "auto",
        ...CustomScrollbarStyle
    },
    "& .subText" : {
        fontWeight: 400,
        color:'#0F172A',
        fontSize: "16px",
        fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    '& .MuiPaper-root':{
      '& .MuiButtonBase-root':{
        padding: '0px 16px !important',
        '& .MuiIconButton-label':{
          color: 'black'
        }
      },
      '& .MuiCollapse-root':{
        background: '#F8FAFC',
        '& .MuiCollapse-wrapper':{
          '& .MuiCollapse-wrapperInner':{
            '& .MuiAccordionDetails-root':{
              padding: '0px 28px',
              background: '#F8FAFC',
              '& .MuiFormControl-root':{
                marginTop: '0px',
                marginBottom: '0px'
              }
            }
          }
        }
      },
    }
  });

const DatePicker = styled(KeyboardDatePicker)({
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        paddingRight: '0px',

        '&:hover fieldset': {
            borderColor: '#527FE2 !important', // Border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: '#527FE2 !important', // Border color on focus
        },
    },
    '& .MuiSvgIcon-root': {
        color: 'gray',
    },
    '& .MuiButtonBase-root': {
        '&:hover': {
            background: 'transparent', // Change to the desired hover color
        },
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#527FE2 !important', // Ensure hover works properly
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#527FE2 !important',
    },
})
  

export default FilterDialog

