import React, { Component } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@material-ui/core/styles";
import { calendarIcon } from "../../blocks/catalogue/src/assets";

const InputField = styled(TextField)({
  color: "#00000",
  fontFamily: '"Poppins", "sans-serif"',
  fontSize: "16px",
  fontWeight: 500,
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    color: "#94A3B8",
    opacity: 1,
    textTransform: "none",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      borderColor: "#527FE2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#527FE2",
    },
  },
  "& .MuiInputBase-input": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-input": {
    padding: "18px 10px",
  },
  "&.Mui-error fieldset": {
    borderColor: "#DC2626", 
  },

  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontFamily: '"Poppins", "sans-serif"',
    fontSize: "12px",
    fontWeight: 400,
    marginLeft: "0px",
    marginRight: "0px",
  },
});

const ModalContainer = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "555px",
  height:'500px',
  background: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
  outline: "none",
});

interface ServiceDetails {
  service: string;
  price: string;
  creationDate: string;
}


interface State extends ServiceDetails {
  errors: {
    service: string;
    price: string;
  };
}

interface EditServiceModalProps {
  open: boolean;
  handleClose: () => void;
  serviceDetails: ServiceDetails;
  onSave: (updatedDetails: ServiceDetails) => void;
}

class EditServiceModal extends Component<EditServiceModalProps, State> {
  constructor(props: EditServiceModalProps) {
    super(props);

    this.state = {
      service: props.serviceDetails.service || "",
      price: props.serviceDetails.price || "",
      creationDate: this.formatDateToYYYYMMDD(props.serviceDetails.creationDate) || "",
      errors: {
        service: "",
        price: "",
      },
    };
  }

  componentDidUpdate(prevProps: EditServiceModalProps) {
    if (prevProps.serviceDetails !== this.props.serviceDetails) {
      this.setState({
        service: this.props.serviceDetails.service || "",
        price: this.props.serviceDetails.price || "",
        creationDate: this.props.serviceDetails.creationDate || "",
      });
    }
  }

  formatDateToYYYYMMDD(date: string): string {
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }

    // Convert from other formats (e.g., DD/MM/YYYY)
    const [day, month, year] = date.split("/");
    if (day && month && year) {
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    return ""; // Return empty string if format is invalid
  }

  validateInputs = () => {
    const { service, price } = this.state;
     const errors: State["errors"] = { service: "", price: "" }; 
     let isValid = true;

    // Validation for service field
    if (!service || service.trim() === "") {
      errors.service = "Please enter a valid service name.";
      isValid = false;
    }

    // Validation for price field
    if (!/^\d+$/.test(price)) {
      errors.price = "Please enter a valid numeric price.";
      isValid = false;
    }

    this.setState({ errors });
    return isValid; 
  };


  handleInputChange = (field: keyof ServiceDetails, value: string) => {
    this.setState({ [field]: value } as Pick<ServiceDetails, keyof ServiceDetails>);
    this.setState({errors: {
      ...this.state.errors,
      [field]: "", // Clear error when user types
    },})
  };

  handleSave = () => {
    if (this.validateInputs()) {
      this.props.onSave(this.state);
      this.props.handleClose();
    }
    
  };

  render() {
    const { open, handleClose } = this.props;
    const { service, price, creationDate, errors } = this.state;

    return (
      <Modal open={open} onClose={handleClose}>
        <ModalContainer>
            <Box
                style={{
                    borderBottom:'1px solid #E2E8F0',
                    padding: "20px 25px 10px 25px",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent:'end',
                        marginBottom: "15px",
                    }}
                >
                    <CloseIcon onClick={handleClose} />
                    
                </Box>
            
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                    variant="h6"
                    style={{
                        fontWeight: 700,
                        fontSize: "20px",
                        lineHeight: "26px",
                    }}
                    >
                    Edit Service
                    </Typography>
                    
                </Box>
            </Box>

            <Box
                style={{
                    padding: "12px 25px 20px 25px", 
                }}
            >
                <Box mb={2} style={{marginBottom:'15px'}}>
            <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", marginBottom:'8px', color:'#0F172A' }}>
              Service
            </Typography>
            <InputField
              fullWidth
              variant="outlined"
              placeholder="Enter Service"
              value={service}
              onChange={(e) => this.handleInputChange("service", e.target.value)}
              error={!!errors.service} // Error state
              helperText={errors.service} // Error message
            />
          </Box>

          <Box mb={2} style={{marginBottom:'15px'}}>
            <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", marginBottom:'8px', color:'#0F172A' }}>
              Service Price
            </Typography>
            <InputField
              fullWidth
              variant="outlined"
              placeholder="Enter Price"
              value={price}
              onChange={(e) => this.handleInputChange("price", e.target.value)}
              error={!!errors.price} // Error state
              helperText={errors.price} // Error message
            />
          </Box>

          <Box mb={2} style={{
              marginBottom:'15px',
            }} >
            <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", marginBottom:'8px', color:'#0F172A'}}>
              Creation Date
            </Typography>
            <InputField
              disabled
              fullWidth
              variant="outlined"
              value={creationDate}
              InputProps={{
                endAdornment : <img src={calendarIcon} alt="icon" />
              }}
            />
          </Box>

          <Box style={{ display: "flex", justifyContent: "flex-end", gap: "8px", 
                marginTop:'30px' }}>
            <Button
              onClick={this.handleSave}
              style={{
                backgroundColor: "#527FE2",
                color: "#FFFFFF",
                fontFamily: '"Poppins", "sans-serif"',
                fontWeight: 600,
                fontSize:'18px',
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "none",
                width:'100%',
                height:'48px',
              }}
            >
              Save
            </Button>
          </Box>
            </Box>

        </ModalContainer>
      </Modal>
    );
  }
}

export default EditServiceModal;
