import React, { Component } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { cancleRoundRed,checkedRound } from "../src/imgsrc";
import { I18n } from "../../components/src/languageJson/i18n";

const InputField = styled(TextField)({
  color: "#00000",
  fontFamily: '"Poppins", "sans-serif"',
  fontSize: "16px",
  fontWeight: 500,
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    color: "#94A3B8",
    opacity: 1,
    textTransform: "none",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      borderColor: "#527FE2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#527FE2",
    },
  },
  "& .MuiInputBase-input": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-input": {
    padding: "18px 10px",
  },
  "&.Mui-error fieldset": {
    borderColor: "#DC2626", 
  },

  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontFamily: '"Poppins", "sans-serif"',
    fontSize: "12px",
    fontWeight: 400,
    marginLeft: "0px",
    marginRight: "0px",
  },
});

const ModalContainer = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "539px",
  height:'234px',
  background: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
  outline: "none",
});

interface EditServiceModalProps {
  open: boolean;
  handleClose: () => void;
}

class EditServiceSuccessModal extends Component<EditServiceModalProps> {

  handleBack = () => {
      this.props.handleClose();
  };

  stylesDialog = {
    errorTitle: {
      fontFamily:"Poppins",
      fontSize:"20px",
      fontWeight: 700,
      color:"#1E293B",
      lineHeight:"20px",
    },
    tryAgain:{
      height:"48px",
      fontFamily:"Poppins",
      fontSize:"18px",
      fontWeight: 600,
      backgroundColor:"#527FE2",
      lineHeight:"22px",
      color:"#FFFFFF",
      borderRadius:"8px",
      textTransform: "capitalize" as 'capitalize',
    },
    cancle:{
      height:"48px",
      fontFamily:"Poppins",
      fontSize:"18px",
      fontWeight: 600,
      backgroundColor:"#527FE2",
      lineHeight:"22px",
      color:"#FFFFFF",
      borderRadius:"8px",
      border:"1px solid #527FE2",
      textTransform: "capitalize" as 'capitalize',
    },

    imgCancle: {
        height:"100%",
        width:"100%",
      },
  };

  render() {
    const { open } = this.props;

    const pathSegments = location.pathname.split("/").filter(Boolean);
    const currentPage = pathSegments[pathSegments.length - 1];

    return (
      <Modal open={open} onClose={this.handleBack}>
        <ModalContainer>

          <Box style={{ display: "flex", justifyContent: "center", gap: "30px", 
             flexDirection:'column', padding:'20px' }}>
            <Box
             style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap:'28px'
             }}
            >
                <div style={{ height: "70px", width: "70px" }}>
                    <img src={checkedRound} alt="Cancle" style={this.stylesDialog.imgCancle} />
                </div>
                <Typography style={this.stylesDialog.errorTitle}>
                  {
                    currentPage === 'Catalogue' ? I18n.t("serviceUpdated") : I18n.t("serviceAdded")
                  }
                </Typography>
            </Box>
            
            <Button
            data-test-id="edit-success-close"
              onClick={this.handleBack}
              style={{
                backgroundColor: "#527FE2",
                color: "#FFFFFF",
                fontFamily: '"Poppins", "sans-serif"',
                fontWeight: 600,
                fontSize:'18px',
                padding: "8px 16px",
                borderRadius: "8px",
                textTransform: "none",
                width:'100%',
                height:'48px',
              }}
            >
              {I18n.t("back")}
            </Button>
          </Box>

        </ModalContainer>
      </Modal>
    );
  }
}

export default EditServiceSuccessModal;
