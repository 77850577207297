import React from "react";

// Customizable Area Start
import {
  Box,
  Breadcrumbs,
  Dialog,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  Grid
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { I18n } from "../../../components/src/languageJson/i18n";
import * as Yup from 'yup';
import { branchIcon, checked, unchecked,cameraImg, checkMark, departmentIcon, emailIcon, empIcon, idIcon, locationIcon, positionIcon, redBranchIcon, redDepartmentIcon, redEmailIcon, redEmpIcon, redPositionIcon, reportIcon,imgEight, imgFive, imgFour, imgOne, imgSeven, imgSix, imgThree, imgTwo, imgError } from "./assets";
import { ErrorMessage, Form, Formik } from "formik";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PhoneInputWithCountrySelect, {isValidPhoneNumber} from "react-phone-number-input";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { getValueBasedOnLanguage } from "../../../components/src/constant"
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
// Customizable Area End

import AddEmployeeController, {
  Props,
} from "./AddEmployeeController";
export default class AddEmployee extends AddEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  MainTableDiv = styled(Box)({
    marginTop: '20px',
    padding: '20px',
    border: '1px solid #c8c8c8',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    background: 'white',
    direction: this.getValueBasedOnLanguage("rtl", "ltr") ,
    textAlign: this.getValueBasedOnLanguage("right", "left") ,
    '& .empInfo':{
      fontWeight: 700,
      fontFamily: 'poppins'
    },
    '& .container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '130px',
      height: '130px',
      borderRadius: '50%',
      border: '2px solid #d3d3d3',
      color: '#8c8c8c',
      cursor: 'pointer',
      textAlign: 'center',
      background:'#E2E8F0',
      marginTop:'30px'
  
    },
    '& .uploadLabel': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '& .iconContainer': {
      fontSize: '24px', 
    },
    '& .text': {
      fontSize: '12px',
      marginTop: '5px',
      fontWeight: 700,
      color: '#64748b',
      fontFamily: 'poppins'
    },
    '& .hiddenInput': {
      display: 'none',
    },
    '& .imagePreview': {
      width: '100%',
      height: '130px',
      objectFit: 'cover',
      borderRadius: '50%'
    },
    '& .mainFlex':{
      display: 'flex',
      alignItems: 'center',
      gap: '20px'
    },
    '& .userName':{
      fontWeight: 700,
      marginTop: '30px'
    },
    '& .userId': {
      color:'#64748B',
      marginTop: '10px'
    },
    '& .information':{
        color: '#64748B',
        textTransform: "capitalize"
      }
  });

  BreadcrumbContainer = styled(Box)({
    display: "flex",
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    paddingBottom: '50px',
    justifyContent: this.getValueBasedOnLanguage("flex-end", "flex-start"),
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins ")
  })

  MenuItemSet = styled(MenuItem)({
    padding: '10px',
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    '&:hover': {
      backgroundColor: '#DBEAFE',
    },
  })

  SelectMain = styled(Select)({
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      backgroundColor: 'transparent',
    },

    "& .MuiSelect-icon": {
      right: this.getValueBasedOnLanguage("auto", "0"),
      left: this.getValueBasedOnLanguage("7", "auto"),
    }
  })

  FormikContainer = styled(Box)({
    marginTop: '20px',
    '& .error-message': {
      color: 'red',
      fontSize: '0.875rem', 
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    '& .phoneInput':{
      border: '1px solid #B9AEAEFC',
      padding: '17px',
      borderRadius: '10px',
      '& .PhoneInputInput':{
        border: 'none',
        outline: 'none', 
        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
        textAlign: this.getValueBasedOnLanguage("right", "left"),
      },
      "& .PhoneInputCountrySelectArrow": {
        marginRight: this.getValueBasedOnLanguage("5px", "auto"),
      }
    },
    '& .phoneInputChange':{
      border: '1px solid red',
      padding: '17px',
      borderRadius: '10px',
      '& .PhoneInputInput':{
        border: 'none',
        outline: 'none', 
        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
        textAlign: this.getValueBasedOnLanguage("right", "left"),
      },
      "& .PhoneInputCountrySelectArrow": {
        marginRight: this.getValueBasedOnLanguage("5px", "auto"),
      }
    },
    '& .setTextField': {
      display: 'flex',
      gap: '15px',
      marginTop: '20px',
      '@media (max-width: 600px)': {
        flexWrap: 'wrap'
      }
    },
    '& .saveButton': {
      padding: '15px 130px',
      marginTop:'90px',
      fontSize: '1rem',
      color: '#fff',
      background: '#527FE2',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 700,
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
    }
  });

  validationSchema = Yup.object({
    name: Yup.string().required(I18n.t('EmpNameValidation')),
    // organization_id: Yup.string().required('Please add Organization ID'),
    department: Yup.string().required(I18n.t('EmpDeptValidation')),
    branch: Yup.string().required(I18n.t('EmpBranchValidation')),
    designation: Yup.string().required(I18n.t('EmpDesiValidation')),
    // reportingToreporting_to: Yup.string().required('Please select a an Employee to report to.'),
    work_email: Yup.string().email(I18n.t('EmpEmailValidation')),
    mobile_number: Yup.string()
        .required(I18n.t('EmpContactValidation'))
        .test(
            'isValidPhoneNumber',
            I18n.t('EmpPhoneValidation'),
            (value) => isValidPhoneNumber(value || '') // Validates using react-phone-number-input
        )
  });

  uploadAndShowImage = () => {
    const {editSelectedImage, selectedImage} = this.state
    if(editSelectedImage && !selectedImage ){
     return <img
        src={this.state.editSelectedImage}
        alt="Selected"
        className='imagePreview'
      />
    }
    else{
     return this.state.selectedImage ? (
        <img
          src={URL.createObjectURL(this.state.selectedImage)}
          alt="Selected"
          className='imagePreview'
        />
      ) : (
        <>
          <div className='iconContainer'><img src={cameraImg} alt="camImg" /></div>
          <div className='text'>{I18n.t("EmpPicture")}</div>
        </>
      )
    }
  }

  showCondtion = (errors: any, touched: any) => {
    return Boolean(errors && touched)
  }

  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }

  renderForm = (condition:boolean,trueStmt:any,falseStmt:any) => {
    return condition ? trueStmt : falseStmt
  }

  renderCheckbox = () => {
    return <Grid item sm={12} xs={12} md={12}>
      <Box data-test-id="checkbox" style={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer", marginTop: "20px" }} onClick={this.handleCHeckPrice}>
        <img style={{ width: "20px", height: "20px" }}
          src={this.state.enableAppointment ? checked : unchecked}
          alt="checkbox" />
        <Typography style={{ fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), fontSize: "1rem", color: "#000000" }}>{I18n.t("EmpAppointments")}</Typography>
      </Box>
    </Grid>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainBox style={{display:'flex', flexDirection: this.getValueBasedOnLanguage('row-reverse', "row")}}>
        <AppNavigation />
        <StyledBox className={this.getValueBasedOnLanguage("arabic-text", "default-text")}
          style={{
            marginRight:this.getValueBasedOnLanguage("325px", undefined)
          }}
        >
          <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"),I18n.t("peopleManagement"),I18n.t("employeeProfile")]} navigation={this.props.navigation} pathToNavigate="AccountGroups" />
          {
            this.renderForm(this.state.viewUser,
              <>
                <this.MainTableDiv>
                  <Typography className="empInfo">{I18n.t("EmpInfo")}</Typography>

                  <div className="mainFlex">
                    <div className='container'>
                      <div>
                        <label htmlFor="upload-button" className='uploadLabel'>
                          <img
                            src={this.state.setParticularUser.image}
                            alt="Selected"
                            className='imagePreview'
                          />
                        </label>
                      </div>
                    </div>
                    <div>
                      <Typography className="userName">{this.state.setParticularUser.name}</Typography>
                      <Typography className="userID">#{this.state.setParticularUser.employee_id}</Typography>
                    </div>
                  </div>


                  <UserDetails>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgTwo} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("OrgId")}</Typography>
                        <Typography className="userDetails">#{this.state.setParticularUser.organization_id}</Typography>
                      </div>
                    </div>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgThree} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpDepartment")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.department}</Typography>
                      </div>
                    </div>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgFour} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpBranch")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.branch}</Typography>
                      </div>
                    </div>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgOne} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpPosition")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.designation}</Typography>
                      </div>
                    </div>
                  </UserDetails>

                  <UserDetails style={{ marginTop: '35px' }}>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgFive} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpReporting")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.reporting_to}</Typography>
                      </div>
                    </div>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgSix} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpAddress")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.home_address}</Typography>
                      </div>
                    </div>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgSeven} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpEmail")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.work_email}</Typography>
                      </div>
                    </div>
                    <div className="mainFlex">
                      <div className="setImg"><img src={imgEight} alt="user_img" /></div>
                      <div>
                        <Typography className="information">{I18n.t("EmpContact")}</Typography>
                        <Typography className="userDetails">{this.state.setParticularUser.mobile_number}</Typography>
                      </div>
                    </div>
                  </UserDetails>

                </this.MainTableDiv>
                <div style={{ textAlign: 'center' }}>
                  <button data-test-id="navigate_one" className="saveButton" onClick={() => this.handleNavigation1(this.state.setParticularUser.id)}> {I18n.t("Edit")}</button>
                </div>
              </>,
              <this.FormikContainer>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: this.state.setEditData?.name || '',
                    organization_id: this.state.setEditData?.organization_id || '',
                    department: this.state.setEditData?.department || '',
                    branch: this.state.setEditData?.branch || '',
                    designation: this.state.setEditData?.designation || '',
                    reporting_to: this.state.setEditData?.reporting_to || '',
                    home_address: this.state.setEditData?.home_address || '',
                    work_email: this.state.setEditData?.work_email || '',
                    mobile_number: this.state.setEditData?.mobile_number || '',
                    additional_notes: this.state.setEditData?.additional_notes || '',
                    employee_id: this.state.setEditData?.employee_id || '',
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={(values, { resetForm }) => {
                    this.onSubmit(values, resetForm)
                  }}
                >
                  {({ errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                      <this.MainTableDiv>
                        <Typography className="empInfo">{I18n.t("EmpInfo")}</Typography>
                        <div className='container'>
                          <label htmlFor="upload-button" className='uploadLabel'>
                            {this.uploadAndShowImage()}
                          </label>
                          <input
                            id="upload-button"
                            type="file"
                            accept="image/"
                            className='hiddenInput'
                            data-test-id='selectImage'
                            onChange={this.handleImageUpload}
                          />
                        </div>
                        <div className="form-group">
                          <div className="setTextField">
                            <div style={{ width: '100%' }}>
                              <TextMain
                                variant="outlined"
                                data-test-id="flag_true"
                                onChange={(event) => this.handleChangeformik(event,handleChange)}
                                name="name"
                                type='text'
                                error={this.showCondtion(errors.name, touched.name)}
                                placeholder={I18n.t("EmpName") + "*"}
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {
                                        this.renderForm(this.showCondtion(errors.name, touched.name),<img src={redEmpIcon} alt="red_emp_icon" />,<img src={empIcon} alt="emp_icon" />)}
                                    </InputAdornment>
                                  ),
                                }}
                                value={values.name}
                              />
                              <ErrorMessage name="name" component="div" className="error-message" />
                            </div>
                            <div style={{ width: '100%' }}>
                              <TextMain
                                variant="outlined"
                                onChange={(eventChange) => this.handleChangeformik(eventChange, handleChange)}
                                data-test-id="falgTrue"
                                name="organization_id"
                                type='text'
                                placeholder={I18n.t("OrgId")}
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={idIcon} alt="emp_icon" />
                                    </InputAdornment>
                                  ),
                                }}
                                value={values.organization_id}
                              />
                            </div>
                          </div>
                          <div className="setTextField">

                            <div style={{ width: '100%' }}>
                              <FormControl className="testClassss" variant="outlined" fullWidth>
                                <this.SelectMain
                                  style={{ borderRadius: '10px' }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        'borderRadius': '10px'
                                      },
                                    },
                                    style: {
                                      inset:  this.getValueBasedOnLanguage('60px 0px 0 0px', '60px 0px 0 -47px')
                                    }
                                  }}
                                  className="helloSelect"
                                  label="Select here"
                                  name="department"
                                  data-test-id='department_id'
                                  displayEmpty
                                  error={this.showCondtion(errors.department, touched.department)}
                                  onChange={(eventChange) => this.handleChangeformikDropdown(eventChange, handleChange, setFieldValue)}
                                  value={values.department}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {
                                        this.renderForm(this.showCondtion(errors.department, touched.department),<img src={redDepartmentIcon} alt="dep_icon" />,<img src={departmentIcon} alt="dep_icon" />)
                                      }
                                    </InputAdornment>
                                  }
                                  renderValue={(value) => {
                                    const selectedDepartment = this.state.setDepartmentData?.find(
                                      (item: any) => item.id === value || item.attributes.name === value
                                    );
                                    return (
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          color: "currentColor",
                                          opacity: this.conditionalRenderObject(selectedDepartment, "1.22", "0.42"),
                                          background: 'transparent', 
                                        }}
                                      >
                                        {selectedDepartment ? selectedDepartment.attributes.name :  I18n.t("EmpDepartment") + "*"}
                                      </Box>
                                    );
                                  }}
                                  input={<OutlinedInput />}
                                >
                                  {
                                    this.state.setDepartmentData && this.state.setDepartmentData.map((item: any, index: number) =>
                                    (
                                      <this.MenuItemSet style={{
                                        borderBottom: this.conditionalRenderObject(index === this.state.setDepartmentData.length - 1, "none", "1px solid #d4d2cd")
                                      }} className="setMenuItem" key={index} value={item?.id}>{item?.attributes?.name}</this.MenuItemSet>
                                    )
                                    )
                                  }
                                </this.SelectMain>
                              </FormControl>
                              <ErrorMessage name="department" component="div" className="error-message" />
                            </div>
                            <div style={{ width: '100%' }}>
                              <FormControl variant="outlined" fullWidth>
                                <this.SelectMain
                                  style={{ borderRadius: '10px' }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        'borderRadius': '10px'
                                      },
                                    },
                                    style: {
                                      inset: '60px 0 0'
                                    }
                                  }}
                                  label="Select here"
                                  name="branch"
                                  data-test-id='branch_id'
                                  displayEmpty
                                  error={this.showCondtion(errors.branch, touched.branch)}
                                  onChange={(eventChange) => this.handleChangeformikBranch(eventChange, handleChange, setFieldValue)}
                                  value={values.branch}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {
                                        this.renderForm(this.showCondtion(errors.branch, touched.branch),<img src={redBranchIcon} alt="branch_icon" />,<img src={branchIcon} alt="branch_icon" />)
                                      }
                                    </InputAdornment>
                                  }

                                  renderValue={(value) => {
                                    const selectedBranch = this.state.setBranchData?.find(
                                      (item: any) => item.id === value || item.name === value
                                    );
                                    return (
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          color: "currentColor",
                                          opacity: this.conditionalRenderObject(selectedBranch, "1.22", "0.42"),
                                        }}
                                      >
                                        {this.conditionalRenderObject(selectedBranch, 'name', I18n.t("EmpBranch") + "*")}
                                      </Box>
                                    );
                                  }}
                                  input={<OutlinedInput />}
                                >
                                  {
                                    this.state.setBranchData && this.state.setBranchData.map((item: any, index: number) =>
                                    (
                                      <this.MenuItemSet style={{
                                        borderBottom: this.conditionalRenderObject(index === this.state.setBranchData.length - 1, "none", "1px solid #d4d2cd"),
                                      }} key={index} value={item?.id}>{item?.name}</this.MenuItemSet>
                                    )
                                    )
                                  }
                                </this.SelectMain>
                              </FormControl>
                              <ErrorMessage name="branch" component="div" className="error-message" />
                            </div>
                          </div>
                          <div className="setTextField">

                            <div style={{ width: '100%' }}>
                              <FormControl variant="outlined" fullWidth>
                                <this.SelectMain
                                  label="Select here"
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        'borderRadius': '10px'
                                      },
                                    },
                                    style: {
                                      inset:  this.getValueBasedOnLanguage('60px 0px 0 0px', '60px 0px 0 -47px')
                                    }
                                  }}
                                  style={{ borderRadius: '10px' }}
                                  name="designation"
                                  data-test-id='position_id'
                                  displayEmpty
                                  error={this.showCondtion(errors.designation, touched.designation)}
                                  onChange={(eventChange) => this.handleChangeformikDesignation(eventChange, handleChange, setFieldValue)}
                                  value={values.designation}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {
                                        this.renderForm(this.showCondtion(errors.designation, touched.designation),<img src={redPositionIcon} alt="pos_icon" /> ,<img src={positionIcon} alt="pos_icon" />)
                                      }
                                    </InputAdornment>
                                  }

                                  renderValue={(value) => {
                                    const selectedPosition = (this.state.setPositionData && this.state.setPositionData.find(
                                      (item: any) => item.id === value || item.name === value
                                    ));
                                    return (
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          color: "currentColor",
                                          opacity: this.conditionalRenderObject(selectedPosition, "1.22", "0.42"),
                                        }}
                                      >
                                        {this.conditionalRenderObject(selectedPosition, 'name', I18n.t("EmpPosition") + "*")}
                                      </Box>
                                    );
                                  }}
                                  input={<OutlinedInput />}
                                >
                                  {
                                    this.state.setPositionData && this.state.setPositionData.map((item: any, index: number) =>
                                    (
                                      <this.MenuItemSet style={{
                                        borderBottom: this.conditionalRenderObject(index === this.state.setPositionData.length - 1, "none", "1px solid #d4d2cd"),
                                      }} key={index} value={item.id} > {item.name} </this.MenuItemSet>
                                    )
                                    )
                                  }
                                </this.SelectMain>
                              </FormControl>
                              <ErrorMessage name="designation" component="div" className="error-message" />
                            </div>
                            <div style={{ width: '100%' }}>
                              <FormControl variant="outlined" fullWidth>
                                <this.SelectMain
                                  label="Select here"
                                  style={{ borderRadius: '10px' }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        'borderRadius': '10px'
                                      },
                                    },
                                    style: {
                                      inset: '60px 0 0',
                                      minWidth: '100px'
                                    }
                                  }}
                                  name="reporting_to"
                                  data-test-id='report_id_change'
                                  displayEmpty
                                  onChange={(eventChange) => {this.handleChangeformikReport( eventChange, handleChange,setFieldValue )}}
                                  value={values.reporting_to}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={reportIcon} alt="report_icon" />
                                    </InputAdornment>
                                  }
                                  renderValue={(value) => {
                                    const selectedBranch = this.state.setReportingData?.find(
                                      (item: any) => item.id === value || item.attributes.name === value
                                    );
                                    return (
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          color: "currentColor",
                                          opacity: this.conditionalRenderObject(selectedBranch, "1.22", "0.42"),
                                        }}
                                      >
                                        {selectedBranch ? selectedBranch.attributes.name : I18n.t("EmpReporting")}
                                      </Box>
                                    );
                                  }}
                                  input={<OutlinedInput />}
                                >
                                  {
                                    this.state.setReportingData && this.state.setReportingData.map((item: any, index: number) =>
                                    (
                                      <this.MenuItemSet style={{
                                        borderBottom: this.conditionalRenderObject(index === this.state.setReportingData.length - 1, "none", "1px solid #d4d2cd"),
                                      }} key={index} value={item?.id}>{item?.attributes?.name}</this.MenuItemSet>
                                    )
                                    )
                                  }
                                </this.SelectMain>
                              </FormControl>
                            </div>
                          </div>
                          <div className="setTextField">

                            <div style={{ width: '100%' }}>
                              <TextMain
                                variant="outlined"
                                onChange={(event) => this.handleChangeformik(event,handleChange)}
                                data-test-id="home_flag"
                                name="home_address"
                                type='text'
                                placeholder={I18n.t("EmpAddress")} 
                                value={values.home_address}
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={locationIcon} alt="location" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div style={{ width: '100%' }}>
                              <TextMain
                                variant="outlined"
                                onChange={(event) => this.handleChangeformik(event,handleChange)}
                                data-test-id='work_flag'
                                name="work_email"
                                type='text'
                                value={values.work_email}
                                error={this.showCondtion(errors.work_email, touched.work_email)}
                                placeholder={I18n.t("EmpEmail")}
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {
                                        this.showCondtion(errors.work_email, touched.work_email) ?
                                          <img src={redEmailIcon} alt="red_emp_icon" /> : <img src={emailIcon} alt="emp_icon" />
                                      }
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <ErrorMessage name="work_email" component="div" className="error-message" />
                            </div>
                          </div>
                          <div className="setTextField">

                            <div style={{ width: '100%' }}>
                              <PhoneInputWithCountrySelect
                                className={this.renderForm(this.showCondtion(errors.mobile_number, touched.mobile_number),"phoneInputChange",'phoneInput')}
                                placeholder={I18n.t("EmpContact") +"*"} 
                                data-test-id="contact_id"
                                value={values.mobile_number}
                                defaultCountry="AE"
                                countries={[]}
                                countryCallingCodeEditable={false}
                                onChange={(value) => {
                                  setFieldValue("mobile_number", value || "");
                                  this.setState({ flag: true });
                                }}
                              />
                              <ErrorMessage name="mobile_number" component="div" className="error-message" />
                            </div>

                            {
                              this.renderForm(values.employee_id != "",
                                <div style={{ width: '100%', background: '#f1f1f0' }}>
                                <TextMain
                                  disabled
                                  variant="outlined"
                                  onChange={(event) => this.handleChangeformik(event,handleChange)}
                                  data-test-id='emp_flag'
                                  name="employee_id"
                                  type='text'
                                  // placeholder="Organization Id"
                                  fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={idIcon} alt="emp_icon" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={values.employee_id}
                                />
                              </div>,<></>
                              )
                              
                            }

                          </div>
                          {this.renderCheckbox()}
                          <StyledTextarea name="additional_notes" onChange={handleChange} value={values.additional_notes} aria-label="minimum height" style={{ width: '100%', marginTop: '20px', borderRadius: '10px', border: '1px solid #B9AEAEFC', padding: '10px' }} minRows={5} placeholder={I18n.t("EmpNotes")} />
                        </div>
                      </this.MainTableDiv>
                      <div style={{ textAlign: 'center' }}>
                        <button className="saveButton" type="submit">{I18n.t("Save")}</button>
                      </div>

                    </Form>

                  )}
                </Formik>
              </this.FormikContainer>)
          }


          <ToastContainer />
        <SuccessDialog
          open={this.state.successDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="adddynamic"
        >
          <div>
            <div style={{ textAlign: 'center'}}>
              <img src={checkMark} alt="check_mark" />
            </div>
            <div style={{ textAlign: 'center',marginTop:'20px',}}>
              <Typography className="setEmpText">{I18n.t("EmpAddSuccess")}</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
                  <button onClick={this.handleState} className="viewEmpButton">{I18n.t("EmpViewBtn")}</button>
            </div>
          </div>
        </SuccessDialog>

        <SuccessDialog
          open={this.state.successUpdateDialog}
          onClose={this.handleUpdateClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="adddynamic"
        >
          <div>
            <div style={{ textAlign: 'center'}}>
              <img src={checkMark} alt="check_mark" />
            </div>
            <div style={{ textAlign: 'center',marginTop:'20px',}}>
              <Typography className="setEmpText">{I18n.t("EmpUpdateSuccess")}</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
                  <button onClick={this.handleUpdateEmpState} className="viewEmpButton">{I18n.t("EmpViewBtn")}</button>
            </div>
          </div>
        </SuccessDialog>

        <SuccessDialog
          open={this.state.failDialog}
          onClose={this.handleStateFail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="adddynamic"
        >
          <div>
            <div style={{ textAlign: 'center'}}>
              <img src={imgError} alt="check_mark" />
            </div>
            <div style={{ textAlign: 'center',marginTop:'20px',}}>
              <Typography className="setEmpText">{I18n.t("NoChangeMsg")}</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
                  <button style={{ padding: '10px 200px'}} onClick={this.handleStateFail} className="viewEmpButton">{I18n.t("back")}</button>
            </div>
          </div>
        </SuccessDialog>
        </StyledBox>
        <ErrorModal 
          open={this.state.errorModal}
          handleClose={this.closeErrorModal}
          msg_ar={I18n.t("unableToSaveChanges")}
          msg_en={I18n.t("unableToSaveChanges")}
          data-test-id="deleteServiceErrorModal"
        />
      </MainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  display:'flex',
  '& .saveButton': {
    // width: '100%',
    padding: '15px 130px',
    marginTop:'90px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  }, 
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#527FE2",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#527FE2",
    },
  },
  "& .phoneInput": {
    "&:hover": {
      borderColor: "#527FE2",
    }, 
    "&:focus": {
      borderWidth: "1px",
      borderColor: "#527FE2",
    }
  }
  // height: '100vh'
});

const StyledBox = styled(Box)({
  flex: 1,
  background: '#F1F5FC',
  padding: '50px 20px',
  minHeight: "100vh",
  boxSizing: "border-box",
  backgroundColor: "rgb(241, 245, 252)",
  maxHeight: "100%",
  marginLeft: "325px",
});

const TextMain = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    "& .Mui-error":{
      borderColor:"red"
    },
  }
});

const SuccessDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none'
    }
  },
  '& .setEmpText':{
    fontWeight:700,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
  },
  '& .viewEmpButton': {
    // width: '100%',
    padding: '10px 110px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
  }
});

const StyledTextarea = styled(TextareaAutosize)({
  width: '100%',
  marginTop: '20px',
  borderRadius: '10px',
  border: '1px solid #B9AEAEFC',
  padding: '10px',
  '&::placeholder': {
    color: '#94A3B8', // Placeholder text color
    fontSize: '15px',
    fontWeight: 700,
  },
  "&:hover": {
    borderColor: "#527FE2 !important",
  }, 
  "&:focus": {
    borderWidth: "1px !important",
    borderColor: "#527FE2 !important",
    outline: "none"
  }
});

const UserDetails = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '60px',
  padding: '0px 20px',
  flexWrap: 'wrap',
  '& .mainFlex': {
    display: 'flex',
    // justifyContent: 'space-between',
    maxWidth: '25%',
    alignItems: 'flex-start',
    width: '100%',
    gap: '10px',
    "@media(max-width:768px)": {
      maxWidth: '50%',
      marginBottom: '20px'
    },
    "@media(max-width:512px)": {
      maxWidth: '100%'
    },
    '& .information':{
      color: '#64748B',
      textTransform: "uppercase",
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    '& .userDetails':{
      fontWeight: 400,
      marginTop: '3px',
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    '& .setImg':{
      display: 'flex',
      alignItems: 'start',
      height: '100%'
    }
  }
})
// Customizable Area End
