import React, { useState, useEffect } from "react";

import { DataTableProps } from "./types";

import { Box, styled,Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { noData } from "../../blocks/accountgroups/src/assets";
import { Pagination } from "@material-ui/lab";
import { I18n } from "./languageJson/i18n" 
import { getValueBasedOnLanguage } from "./constant";

const DataTable : React.FC<DataTableProps> = ({
    tableHeaders = [],
    tableData = [],
    tableKeys = [],
    erorMsg = "No Data",
    onDelete = () => {},
    onEdit = () => {},
}) => {
    
    const ITEMS_PER_PAGE = 12;
    const [page, setPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState(tableData.slice(0, ITEMS_PER_PAGE));
    const [recordRange, setRecordRange] = useState(`1-${ITEMS_PER_PAGE} of ${tableData.length} ${I18n.t("resultsLength")}`);

    const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);
    const isArabicView = localStorage.getItem("GET_SELECTED_LANGUAGE") === "ar" ? true : false

    useEffect(() => {
        const startIndex = (page - 1) * ITEMS_PER_PAGE;
        const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, tableData.length);

        setPaginatedData(tableData.slice(startIndex, endIndex));
        setRecordRange(`${startIndex + 1}-${endIndex} of ${tableData.length} ${I18n.t("resultsLength")}`);
    }, [page, tableData]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Box>
            <CardBox >
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", margin: "1rem", direction: isArabicView ? "rtl" : "ltr" }}>
                    <Table style={{ tableLayout: 'fixed', width: '100%' }} >
                        <TableHead>
                            <TableRow style={useStyles.tableRow}>
                                {tableHeaders.map((item, index) => (
                                    <TableCellHead
                                        key={index}
                                        style={{ borderRadius: index === 0 ? isArabicView ? "0 0 24px 0" : "0 0 0 24px" : 0 }}
                                    >
                                        {item}
                                    </TableCellHead>
                                ))}
                            </TableRow>
                        </TableHead>
                    </Table>

                    <div className={tableData.length> 0 ? "scrollContainer": ""}>
                        <Table style={{ tableLayout: 'fixed', width: '100%' }} >
                            <TableBody>
                                {tableData.length > 0 ? (
                                    paginatedData.map((item, index) => (
                                        <TableRow key={index} style={useStyles.tableRow}>
                                            {tableKeys.map((key) => (
                                                <TableCellBody key={key}>
                                                    {item[key]}
                                                </TableCellBody>
                                            ))}
                                            <TableCellBody align="center">
                                                <DeleteOutline
                                                    style={{ color: "#DC2626", cursor: "pointer", marginRight: getValueBasedOnLanguage(0,15) }}
                                                    onClick={() => onDelete(item)}
                                                />
                                                <Edit style={{ color: "#0F172A", cursor: "pointer", marginRight: getValueBasedOnLanguage(15,0) }} onClick={() => onEdit(item)} />
                                            </TableCellBody>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={tableHeaders.length} style={{ border: "none"}}>
                                            <NoData className="testingClass">
                                                <img src={noData} alt="no_data" />
                                                <Typography className="noEmpText">
                                                    {erorMsg}
                                                </Typography>
                                            </NoData>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </div>
                </div>
                {tableData.length != 0 && (
                <div className="paginationBox">
                    <Pagination
                        count={totalPages}
                        boundaryCount={1}
                        siblingCount={1}
                        data-test-id='pagination_id'
                        page={page} // Bind the current page state
                        onChange={handlePageChange} // Handle page change event
                    />
                    <div style={{ textAlign: 'end' }}>
                        <Typography className={`recordCountText ${isArabicView? "leftAlign" : "rightAlign"} `}>
                            {recordRange}
                        </Typography>
                    </div>
                </div>
            )
            }
            </CardBox>
            
        </Box>
    )
}


const useStyles = {
    tableRow: {
        margin: "5px 0 5px 0",
        height: "100%"
    }
  }

const CardBox = styled(Card)({
    overflowY: "auto",
    border: "1 px solid #CBD5E1",
    backgroundColor: "fffffff", 
    margin: "30px auto", 
    borderRadius: "1.5rem",
    width: "100%", 
    "@media (max-width:560px)": {
        overflow: "scroll",
        display: "flex",
        marginTop: "20px"
    },
    "& .scrollContainer" : {
        maxHeight: 450, 
        overflowY: "auto", 
        width: "100%",
        borderRadius:"1.5rem",
        '&::-webkit-scrollbar': {
           width: '6px',
           borderRadius: '10px', 
         },
         '&::-webkit-scrollbar-thumb': {
           backgroundColor: '#527FE2',
           borderRadius: '10px', 
           border: '2px solid transparent',
         },
         '&::-webkit-scrollbar-track': {
           backgroundColor:'#f1f1f1',
           borderRadius: '10px',
           width:'6px'
         },
         '&::-webkit-scrollbar-corner': {
           backgroundColor: 'transparent', 
         },
    },
    '& .paginationBox': {
        display: 'flex',
        position: 'relative',
        marginBottom: '30px',
        marginTop: '10px',
        justifyContent: 'center',
        direction: getValueBasedOnLanguage("rtl", "ltr"),
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },
        '& .recordCountText': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#64748B',
            '@media (max-width: 600px)': {
                position: 'static',
                textAlign: 'center',
                transform: 'none',
                marginTop: '10px',
            }
        },
        "& .rightAlign": {
            right: "70px"
        },
        "& .leftAlign": {
            left: "70px"
        },
        "& .MuiPagination-ul li:last-child" : {
            transform: getValueBasedOnLanguage("rotate(180deg)", "rotate(0deg)")
        },
        "& .MuiPagination-ul li:first-child" : {
            transform: getValueBasedOnLanguage("rotate(180deg)", "rotate(0deg)")
        },
        "& .MuiPaginationItem-page" : {
            textDecoration : "underline",
            fontWeight: 400,
            fontSize: "12px",
            fontFamily: "Poppins",
            color : "black",
        },
        "& .MuiPaginationItem-page.Mui-selected" : {
            fontWeight: 700,
            fontSize: "12px",
            fontFamily: "Poppins",
            color : "#527FE2",
            textDecoration: "none",
            background: "white"
        }
    },
})

const TableCellHead = styled(TableCell)({
    fontWeight: 700, 
    color: "ffffff",
    backgroundColor: "#527FE2", 
    padding: "20px 24px 20px 24px", 
    fontSize: "16px", 
    fontFamily:getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), 
    minHeight: "64px",
    border: "none", 
})

const TableCellBody = styled(TableCell)({
    border: "none", 
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), 
    height: '44px', 
    padding: "20px 24px 20px 24px",
    fontSize: "16px", 
})

const NoData = styled(Box)({
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& .noEmpText':{
        textAlign: 'center',
        fontWeight: 700,
    }
});

export default DataTable