// Customizable Area Start
import React from "react";
import AppNavigation from "../../../components/src/AppNavigation.web";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { I18n } from "../../../components/src/languageJson/i18n";
import { Box, styled, Typography, Card, Button, Grid, InputAdornment, OutlinedInput } from "@material-ui/core"
import { grayDepartMentIcon, grayPositionIcon, redDepartMentIcon, redPositionIcon } from "./assets";
import { InputTypesFields } from "../../../components/src/types"
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { InputField, TextAreaField, getValueBasedOnLanguage, StyledFormControl,MenuItemSet, SelectMain, getCommonMenuProps, CommonBoxStyles } from "../../../components/src/constant";
import CustomDropdownIcon from "../../../components/src/CustomDropdownIcon";

import EditPositionController, {
    Props,
  } from "./EditPositionController";


class EditPosition extends EditPositionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderAlertDiv = (msg: string) => {
        return (
            <Typography
                style={{
                    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                    marginTop: "2px", fontSize: "14px", color: "#DC2626",
                    textAlign: getValueBasedOnLanguage("right", "left"),
                }}
            >
                {msg}
            </Typography>
        )
    }

    renderEditTextFields = (params: InputTypesFields) => {
        return (
            <Box>
                <InputField
                    data-test-id={params.name}
                    placeholder={params.placeholder}
                    fullWidth
                    variant="outlined"
                    value={params.value}
                    name={params.name}
                    error={params.errorKey}
                    onChange={this.handleEditInputChange}
                    style={{
                        direction: getValueBasedOnLanguage("rtl", "ltr"),
                        textAlign: getValueBasedOnLanguage("right", "left"),
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <img
                                src={params.errorKey ? redPositionIcon : grayPositionIcon}
                                alt="dep_icon"
                            />

                        </InputAdornment>,
                    }}
                />
                {params.errorKey && this.renderAlertDiv(params.errorMsg)}
            </Box>
        )
    }

    renderEditDescriptionSection = (fields: InputTypesFields) => {
        return (
            <Box>
                <TextAreaField
                    data-test-id={fields.name}
                    placeholder={fields.placeholder}
                    variant="outlined"
                    fullWidth
                    minRows={2}
                    multiline
                    maxRows={4}
                    onChange={this.handleEditInputChange}
                    value={fields.value}
                    name={fields.name}
                    error={fields.errorKey}
                    style={{
                        textAlign: getValueBasedOnLanguage("right", "left"),
                        direction: getValueBasedOnLanguage("rtl", "ltr"),
                    }}
                />
                {fields.errorKey && this.renderAlertDiv(fields.errorMsg)}
            </Box>
        )
    }

    renderDepartmentDropdownSection = () => {
        return (
            <Box>
                <StyledFormControl variant="outlined" fullWidth
                >
                    <SelectMain
                        data-test-id={"department_id"}
                        variant="outlined"
                        fullWidth
                        error={this.state.editErrorFormFields.department_id}
                        name={"department_id"}
                        value={this.state.editFormFields.department_id}
                        onChange={this.handleEditSelectDepartmentChange}
                        displayEmpty
                        MenuProps={getCommonMenuProps}
                        IconComponent={CustomDropdownIcon}
                        startAdornment={
                            <InputAdornment position="start">
                                <img
                                    src={this.state.editErrorFormFields.department_id ? redDepartMentIcon : grayDepartMentIcon}
                                    alt="dep_icon"
                                />
                            </InputAdornment>
                        }
                        renderValue={(value) => {
                            const inputVal = this.state.departmentOptions.find((option) => option.id.toString() === value as string);
                            return (
                                <Box
                                    style={{
                                        ...CommonBoxStyles,
                                        color: inputVal ? 'currentColor' : '#94A3B8',
                                    }}
                                >
                                    {inputVal ? inputVal.name : I18n.t('Department *')}
                                </Box>
                            );
                        }}
                        input={<OutlinedInput />}
                    >
                        {this.state.departmentOptions.map((arrayVal, index) => (
                            <MenuItemSet
                                key={index}
                                style={{
                                    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                    borderBottom: '1px solid #E2E8F0',
                                }}
                                className="setMenuItem"
                                value={arrayVal.id.toString()}
                            >
                                {arrayVal.name}
                            </MenuItemSet>
                        ))}
                    </SelectMain>
                </StyledFormControl>
                {this.state.editErrorFormFields.department_id && this.renderAlertDiv(I18n.t("positionErrorDepartmentField"))}
            </Box>
        )
    }

    renderParentPositionDropdownSection = () => {
        return (
            <Box>
                <StyledFormControl variant="outlined" fullWidth
                >
                    <SelectMain
                        style={{ borderRadius: '10px', fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins") }}
                    
                        data-test-id={"parent_position_id"}
                        fullWidth
                        variant="outlined"
                        name={"parent_position_id"}
                        value={this.state.selected_parent_position}
                        onChange={this.handleDropdownParentPositionChange}
                        MenuProps={getCommonMenuProps}
                        IconComponent={CustomDropdownIcon}
                        displayEmpty
                        startAdornment={
                            <InputAdornment position="start">
                                <img
                                    src={grayDepartMentIcon}
                                    alt="dep_icon"
                                />

                            </InputAdornment>
                        }
                        renderValue={(value) => {
                            const selcetedParentDepartmentValue = this.state.positionsOptions.find((option) => option.id.toString() === value as string);
                            return (
                                <Box
                                    style={{
                                        ...CommonBoxStyles,
                                        color: selcetedParentDepartmentValue ? 'currentColor' : '#94A3B8',
                                    }}
                                >
                                    {selcetedParentDepartmentValue ? selcetedParentDepartmentValue.name : I18n.t('Parent position')}
                                </Box>
                            );
                        }}
                        input={<OutlinedInput />}
                    >
                        {this.state.positionsOptions.map((arrayValue, index) => (
                            <MenuItemSet
                                style={{
                                    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                    borderBottom: '1px solid #E2E8F0',
                                }}
                                key={index} 
                                className="setMenuItem"
                                value={arrayValue.id.toString()}
                            >
                                {arrayValue.name}
                            </MenuItemSet>
                        ))}
                    </SelectMain>
                </StyledFormControl>
            </Box>
        )
    }
  
  render() {
    // Customizable Area Start
    return (
      <div style={{display:'flex', flexDirection: getValueBasedOnLanguage('row-reverse', "row")}}>
        <AppNavigation />
         <EditPositionWrapper className={getValueBasedOnLanguage("arabic-text", "default-text")}>
                 {/* BreadCrumbs */}
                <Box style={{ paddingRight: "20px", display: 'flex', flexDirection: getValueBasedOnLanguage("row-reverse", "row") }}>
                    <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("organizationManagement"), I18n.t("ediPosition")]} navigation={this.props.navigation} pathToNavigate="OrganisationHierarchy"/>
                </Box>

                
                {/* Edit Position Form  */}
                <Box className="parentBox">

                    <Card className="formBox">
                        <Typography
                            className="headerText"
                            style={{
                                alignSelf: getValueBasedOnLanguage("flex-end", "flex-start"),
                                textAlign: getValueBasedOnLanguage("right", "left"),
                                fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
                            }}
                        >
                            {I18n.t("ediPosition")}
                        </Typography>

                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderEditTextFields({
                                name: "position_name_en",
                                value: this.state.editFormFields.position_name_en,
                                errorKey: this.state.editErrorFormFields.position_name_en,
                                placeholder: I18n.t("Job Title (EN)*"),
                                errorMsg: I18n.t("positionErrorNameEnglish")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderEditTextFields({
                                name: "position_name_ar",
                                value: this.state.editFormFields.position_name_ar,
                                errorKey: this.state.editErrorFormFields.position_name_ar,
                                placeholder: I18n.t("Job Title (AR)*"),
                                errorMsg: I18n.t("positionErrorNameArabic")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderEditDescriptionSection({
                                name: "position_desc_en",
                                value: this.state.editFormFields.position_desc_en,
                                errorKey: this.state.editErrorFormFields.position_desc_en,
                                placeholder: I18n.t("Job Description (EN)*"),
                                errorMsg:  I18n.t("positionErrorDescriptionEnglish")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderEditDescriptionSection({
                                name: "position_desc_ar",
                                value: this.state.editFormFields.position_desc_ar,
                                errorKey: this.state.editErrorFormFields.position_desc_ar,
                                placeholder: I18n.t("Job Description (AR)*"),
                                errorMsg:I18n.t("positionErrorDescriptionArabic")
                            })}
                        </Grid>

                        <Grid item sm={12} xs={12} md={12}  >
                           {this.renderDepartmentDropdownSection()}
                        </Grid>

                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderParentPositionDropdownSection()}
                        </Grid>
                    </Card>
                </Box>


                {/* Save Button Section */}
                <Box style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
                    <EditPositionButton data-test-id="editDepartmentBtn" onClick={this.updatePositionsData}> {I18n.t("Save")}</EditPositionButton>
                </Box>

                {/* Success Modal */}
                <SuccessModal 
                    open={this.state.editPositonSuccessModal}
                    handleClose={this.closeEditPositionSuccessModal}
                    msg_en={this.state.edit_position_msg_en}
                    msg_ar={this.state.edit_position_msg_ar}
                    data-test-id="successModal"
                />

                {/* Error Modal */}
                <ErrorModal 
                    open={this.state.editPositionErrorModal}
                    handleClose={this.closeEditPositionErrorModal}
                    data-test-id="errorModal"
                />
         </EditPositionWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default EditPosition;

const EditPositionWrapper = styled(Box)({
    flex:1,
    minHeight: "calc(100vh - 100px)",
    backgroundColor:"#F1F5FC", 
    padding:'50px 0 50px 20px',
    marginRight: getValueBasedOnLanguage("325px", 0),
    "& .parentBox": {
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        justifyContent: "center",
        padding: "1.5rem 1.5rem 1.5rem 0"
    },
    "& .formBox" : {
        display: "flex",
        gap: "1.5rem",
        borderRadius: getValueBasedOnLanguage("0 24px 24px 0", "24px 0 0 24px"),
        flexDirection: "column",
        padding: "1.5rem",
        border: "solid 1px #E2E8F0",
    },
    "& .headerText" : {
        fontSize: "20px",
        fontWeight: 600,
        marginBottom: "16px",
        color: "#1a1a1a",
    }
})

const EditPositionButton = styled(Button)({
    color: "white",
    padding: "10px 16px 10px 16px",
    maxWidth: "306px",
    backgroundColor: "#527FE2",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "16px",
    width: "306px",
    borderRadius: "8px",
    height: "44px",
    textTransform: "none",
    "&:hover": {
        color: "white",
        backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
        fontSize: "18px",
        padding: "10px 16px 10px 16px",
    },
});

// Customizable Area End