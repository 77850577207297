import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getValueBasedOnLanguage } from "./constant";


// Reusable Dropdown Icon Component
const CustomDropdownIcon: React.FC = (props) => {
    return (
        <KeyboardArrowDownIcon
            {...props}
            style={{
                position: "absolute",
                left: getValueBasedOnLanguage("10px", "auto"), // Arabic: Left | English: Auto
                right: getValueBasedOnLanguage("auto", "10px"), // Arabic: Auto | English: Right
            }}
        />
    );
};

export default CustomDropdownIcon;
