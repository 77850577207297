import React from "react";
// Customizable Area Start
import { Box, Button, Card,  Grid, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import EditServiceSuccessModal from '../../../components/src/EditServiceSuccessModal.web';
import { getValueBasedOnLanguage as ShowArabicCSS, InputField as StyledInput} from "../../../components/src/constant"

// Customizable Area End

import AddServiceController, { Props } from "./AddserviceController.web";
import { checked, unchecked } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AddService extends AddServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b", direction: ShowArabicCSS("rtl", "ltr") }}
      >
        {value}
      </Typography>

    );
  }

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <div style={{display:'flex', flexDirection: ShowArabicCSS('row-reverse', "row")}}>
        <AppNavigation />
        <div className={ShowArabicCSS("arabic-text", "default-text")} style={{flex:1, minHeight: "calc(100vh - 100px)", backgroundColor:"#F1F5FC", padding:'50px 0 50px 20px', maxHeight:'100%', marginRight: ShowArabicCSS(325, 0)}}>
          <ThemeProvider theme={theme}>
            <Box style={{ paddingRight: "20px" }}>
              <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("serviceManagement"), I18n.t("addNewServiceText")]} navigation={this.props.navigation} pathToNavigate="Catalogue"/>
            </Box>
            <div style={{ maxWidth: "100vw", display: "flex", justifyContent: "center",padding:"0 0 0 0" }}>
              <Box style={{backgroundColor:"#527FE2",padding:"24px 8px 24px 8px",width:"100%",borderRadius:"0 0 0 24px",}}>
                <Typography style={{
                  fontFamily: ShowArabicCSS("Noto Sans Arabic", "Poppins") ,
                  fontWeight: 700,
                  fontSize: "24px",
                  color: "ffffff",
                  textAlign: "center"
                }}>
                  {I18n.t("addNewServiceText")}
              </Typography>
              </Box>
          
            </div>
            <Box style={{ maxWidth: "100vw", display: "flex",flexDirection:"column",gap:"1.5rem", justifyContent: "center",padding:"2.5rem 1.5rem 1.5rem 0" }}>

              <Card style={{ maxWidth: "100vw", height:'454px', boxSizing:'border-box', display: "flex",flexDirection:"column",gap:"1.5rem", 
              justifyContent: "center",padding:"1.5rem 1.5rem" ,borderRadius:"12px",border:"solid 1px #E2E8F0"}}>
                <Grid item sm={12} xs={12} md={12}  >
                  <StyledInput
                    data-test-id="EnglishName"
                    fullWidth
                    style={{
                      textAlign:  ShowArabicCSS("right", "left"),
                      direction: ShowArabicCSS("rtl", "ltr"),
                    }}
                    variant="outlined"
                    placeholder={I18n.t("serviceNameEnglish")}
                    value={this.state.EngServiceName}
                    error={this.state.fieldErrors.EnglishName}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.onValueChange("EngServiceName", value);
                    }}
                
                  />
                  {this.state.fieldErrors.EnglishName && this.getErrorMessageNonFormik(this.state.fieldErrors.EnglishName)}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >
                  <StyledInput
                    data-test-id="ArabicName"
                    fullWidth
                    variant="outlined"
                    style={{
                      direction: ShowArabicCSS("rtl", "ltr"),
                     textAlign:  ShowArabicCSS("right", "left"),
                    }}
                    placeholder={I18n.t("serviceNameArabic")}
                    value={this.state.ArabicServiceName}
                    error={this.state.fieldErrors.ArabicName}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.onValueChange("ArabicServiceName", value);
                    }}
                
                  />
                  {this.state.fieldErrors.ArabicName && this.getErrorMessageNonFormik(this.state.fieldErrors.ArabicName)}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >
                  <StyledInput
                    data-test-id="ServicePrice"
                    fullWidth
                    variant="outlined"
                    placeholder={I18n.t("addServicePrice")}
                    style={{
                      direction: ShowArabicCSS("rtl", "ltr"),
                     textAlign:  ShowArabicCSS("right", "left"),
                    }}
                    value={this.state.SerrvicePrice}
                    error={this.state.fieldErrors.ServicePrice}
                    onChange={(event) => {
                      const value:any = event.target.value;
                      this.onValueChange("SerrvicePrice", value)}}
                
                  />
                  {this.state.fieldErrors.ServicePrice && this.getErrorMessageNonFormik(this.state.fieldErrors.ServicePrice)}
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Box data-test-id="checkbox" style={{ display: "flex", flexDirection: ShowArabicCSS("row-reverse", "row") ,alignItems: "center", gap: "12px", cursor: "pointer" }} onClick={this.handleCHeckPrice}>
                    <img style={{ width: "20px", height: "20px" }} src={this.state.PickupChecked ? checked : unchecked} alt="checkbox" />
                    <Typography style={{ fontFamily: ShowArabicCSS("Noto Sans Arabic", "Poppins"), fontSize: "1rem", color: "#000000" }}>{I18n.t("servicePickupFeesText")}</Typography>
                  </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12}   >
                  <StyledInput
                    data-test-id="PickupFees"
                    disabled={!this.state.PickupChecked}
                    fullWidth
                    variant="outlined"
                    placeholder={I18n.t("servicePickupFees")}
                    value={this.state.PickupFees}
                    error={this.state.fieldErrors.PickupFees}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.onValueChange("PickupFees", value)}}
                      style={{
                        textAlign:  ShowArabicCSS("right", "left"),
                        direction: ShowArabicCSS("rtl", "ltr"),
                      }}
                
                  />
              {this.state.fieldErrors.PickupFees && this.getErrorMessageNonFormik(this.state.fieldErrors.PickupFees)}

                </Grid>
            
              </Card>
            </Box>

            <Box style={{display:"flex",justifyContent:"center",margin:"2rem"}}> 
            <AddServiceBtn data-test-id="addService" onClick={this.validateAddService}>{I18n.t("addServiceBtn")}</AddServiceBtn>
            </Box>
          </ThemeProvider>
        </div>
        {
          this.state.isEditSuccessModalOpen && (
            <EditServiceSuccessModal
              open={this.state.isEditSuccessModalOpen}
              handleClose={this.handleEditSuccessClose}
            />
          )
        }
        
      </div>
      

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start


const AddServiceBtn = styled(Button)({
  color: "white",
  borderRadius: "8px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",
  fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  maxWidth: "306px",
  width:"306px",
  lineHeight:"24px",
  height: "44px",
  "&:hover": {
    color: "white",
    backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",
    padding: "10px 16px 10px 16px",
  },
});

  
  
  

// Customizable Area End
