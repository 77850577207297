import React, { createRef } from "react";
// Customizable Area Start
import {
  Breadcrumbs,
  createTheme,
  ThemeProvider,
  Typography,
  Card,
  Box,
  Modal,
} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { clockIcon2, datePickerIcon, modalErrorIcon, modalSuccessIcon, dropdownPersonIcon, dropdownArrow, attendeesIcon2 } from "./assets";
import DateFnsUtils from '@date-io/date-fns';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { styled } from "@material-ui/core/styles";
import { I18n } from "../../../components/src/languageJson/i18n";

// Customizable Area End

import AddAppoinmentsControllerweb, {
  Props,
} from "./AddAppoinmentControllerweb";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { DatePicker } from "@material-ui/pickers/DatePicker/DatePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
});

export default class EditAppointment extends AddAppoinmentsControllerweb {
  datePickerRef: React.RefObject<unknown>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.datePickerRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  useStyles = {
    ButtonStyles: {
      borderRadius: '8px',
      backgroundColor: '#527FE2',
      fontWeight: 700,
      marginTop: '2rem',
      border: 'none',
      fontSize: '14px',
      fontFamily: "Poppins",
      width: '300px',
      color: '#ffff',
      padding: '12px',

    },
    ModalStyles: {
      transform: "translate(-50%, -50%)",
      position: "absolute",
      backgroundColor: '#ffff',
      top: "50%",
      left: "50%",
      width: 400,
      padding: '16px 24px',
      border: "none",
      boxShadow: 24,
      borderRadius: '8px',
    },
    Cancelbtn: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      marginTop: "16px",
      width: '400px',
      padding: '12px',
      border: "none",
      color: '#ffff',
      backgroundColor: '#527FE2',
      borderRadius: "8px"
    },
    DropdownStyle: {
      borderBottom: "1px solid #E2E8F0",
      transition: "background-color 0.2s ease",
      padding: "10px",
      fontSize: "16px",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    navLinks: {
      fontWeight: 400,
      fontSize: "16px",
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      textDecoration: "none",
    },
  }


  CardBox = styled(Card)({
    backgroundColor: "fffffff",
    border: "1px solid #CBD5E1",
    borderBottomRightRadius: this.state.currentLanguage === "ar" ? "16px" : "0px",
    borderTopRightRadius: this.state.currentLanguage === "ar" ? "16px" : "0px",
    borderTopLeftRadius: this.state.currentLanguage === "ar" ? "0px" : "16px",
    borderBottomLeftRadius: this.state.currentLanguage === "ar" ? "0px" : "16px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width:560px)": {
      display: "flex"
    },
  });
  BreadcrumbsCust = styled(Breadcrumbs)({
    flexWrap: 'nowrap',
    display: 'flex',
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
      display: 'flex',
    },
  });
  nameRenderFunction = () => {
    const customer = this.state.allCustomerDetails.find(
      (item: any) => item.id == this.state.editData.personName
    );
    return customer?.attributes?.full_name;
  }

  addCutomer = () => {
    return (
      <div style={{ marginBottom: "15px", position: "relative", width: "100%" }}>
        <div
          data-test-id="customerDropdownButton"
          className="hoverField"
          style={{
            borderRadius: "8px",
            height: "35px",
            width: "auto",
            display: "flex",
            color: "black",
            fontFamily: "Poppins",
            fontSize: "16px",
            position: "relative",
            backgroundColor: "#FFFFFF",
            padding: "10px 8px",
            flexDirection:
              this.state.currentLanguage === "ar" ? "row-reverse" : "row",
            alignItems: "center",
          }}
          onClick={this.toggleDropdown1}
        >
          <span
            style={{
              position: "absolute",
              [this.state.currentLanguage === "ar" ? "right" : "left"]: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <img
              src={dropdownPersonIcon}
              style={{
                marginRight:
                  this.state.currentLanguage === "ar" ? "0" : "10px",
                marginLeft: this.state.currentLanguage === "ar" ? "10px" : "0",
              }}
            />
          </span>
          <span
            style={{
              flex: 1,
              textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
              fontSize: "16px",
              marginLeft: this.state.currentLanguage === "en" ? "30px" : "0px",
              color: "black",
              marginRight: this.state.currentLanguage === "ar" ? "30px" : "0px",

            }}
          >
            {this.state.displaySelectedCustomer || this.nameRenderFunction()}
          </span>
          <img
            src={dropdownArrow}
            alt="Dropdown Icon"
            style={{
              position: "absolute",
              [this.state.currentLanguage === "ar" ? "left" : "right"]: "20px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
        </div>
        {this.state.allCustomerDetails?.length > 0 && this.state.isDropdownVisible1 && (
         <div
         className="scrol-bar"
         style={{
           borderRadius: "8px",
           position: "absolute",
           width: "284px",
           padding: "0",
           top: "110%",
           backgroundColor: "#FFFFFF",
           zIndex: 10,
           [this.state.currentLanguage === "ar" ? "left" : "right"]: "0",
           maxHeight: "200px",
           overflowY: "scroll",
         }}
       >
         {this.state.allCustomerDetails.map((person: any, index: any) => (
           <div
             key={index}
             style={this.useStyles.DropdownStyle}
             onClick={() => this.selectCustomer(person)}
             data-test-id={`selectCustomer${index + 1}`}
             onMouseEnter={(e) => {
               (e.target as HTMLElement).style.backgroundColor = "#DBEAFE";
             }}
             onMouseLeave={(e) => {
               (e.target as HTMLElement).style.backgroundColor = "transparent";
             }}
           >
             {person.attributes.full_name}
           </div>
         ))}
       </div>
       
        )}
      </div>
    )
  }
  editForm = () => {
    return (
      <form>
        <div style={{ position: "relative", marginBottom: "15px", width: "100%" }}>
          <div
            style={{
              alignItems: "center",
              height: "35px",
              padding: "10px 8px",
              width: "auto",
              color: "black",
              position: "relative",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: "16px",
              flexDirection:
                this.state.currentLanguage === "ar" ? "row-reverse" : "row",
              display: "flex",
            }}
            data-test-id="toggleDropdownButton" 
            className="hoverField3"
            onClick={this.toggleDropdown}
          >
            <span
              style={{
                [this.state.currentLanguage === "ar" ? "right" : "left"]: "10px",
                top: "50%",
                position: "absolute",
                transform: "translateY(-50%)",
              }}
            >
              <img
                style={{
                  marginRight:
                    this.state.currentLanguage === "ar" ? "0" : "10px",
                  marginLeft: this.state.currentLanguage === "ar" ? "10px" : "0",
                }}
                src={dropdownPersonIcon}
              />
            </span>
            <span
              style={{
                color: "black",
                marginLeft: this.state.currentLanguage === "en" ? "30px" : "0px",
                marginRight: this.state.currentLanguage === "ar" ? "30px" : "0px",
                textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                fontSize: "16px",
                flex: 1,
              }}
              className="employee"
            >
              {this.state.displaySelectedPerson || this.state.editData.groupId}
            </span>
            <img
              style={{
                transform: "translateY(-50%)",
                position: "absolute",
                top: "50%",
                [this.state.currentLanguage === "ar" ? "left" : "right"]: "20px",
                cursor: "pointer",
              }}
              alt="Dropdown Icon"
              src={dropdownArrow}
            />
          </div>
          {this.state.allEmployeeDetails?.length > 0 && this.state.isDropdownVisible && (
            <div
              style={{
                top: "110%",
                zIndex: 10,
                position: "absolute",
                width: "284px",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                padding: "0",
                [this.state.currentLanguage === "ar" ? "left" : "right"]: "0",
                maxHeight: "200px",
                overflowY: "scroll",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
              className="scrol-bar"
              >

              {this.state.allEmployeeDetails.map((person: any, index: any) => (
                <div
                  style={this.useStyles.DropdownStyle}
                  key={index}
                  onClick={() => this.selectPerson(person)}
                  onMouseLeave={(e) => {
                    const target = e.target as HTMLElement;
                    target.style.backgroundColor = "transparent";
                  }}
                  data-test-id={`selectPerson${index + 1}`}
                  onMouseEnter={(e) => {
                    const target = e.target as HTMLElement;
                    target.style.backgroundColor = "#DBEAFE";
                  }}
                >
                  {person.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {["date", "time", "attendees", "notes"].map((field, idx) => (
          this.renderEditScreenInputField(field, idx)
        ))}


      </form>
    )
  }
  editBoxModel = () => {
    return (
      <Box
        style={{
          direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr",
        }}
      >

        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row"
          }}
        >
          <div style={{ margin: "2rem 0 3rem 0" }}>
            <button
              style={{
                ...this.useStyles.ButtonStyles,
              }}
              data-test-id="modalopen"
            >
              {I18n.t("AddAppoinmentsaveBtnText")}
            </button>

            <Modal
              onClose={this.handleClose}
              aria-describedby="success-modal-description"
              aria-labelledby="success-modal-title"
              open={this.state.openSuccessModal}
            >
              <Box
                dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                style={{
                  background: "#fff",
                  padding: "16px 24px",
                  top: "50%",
                  textAlign: "center",
                  borderRadius: "8px",
                  position: "absolute",
                  width: "400px",
                  transform: "translate(-50%,-50%)",
                  left: "50%",
                }}
              >
                <img alt="Success Icon" src={modalSuccessIcon} />
                <Typography variant="h6" id="success-modal-description" style={{ fontSize: '18px', fontFamily: "Poppins", fontWeight: 600, marginTop: '10px' }}>
                  {I18n.t("EditApoinmentSuccessmsg")}
                </Typography>
                <button
                  data-test-id="success-modal-close-button"
                  onClick={this.handleClose} style={{
                    ...this.useStyles.Cancelbtn,
                  }} >
                  {I18n.t("AddAppoinmentcancelBtnText")}
                </button>
              </Box>
            </Modal>
            <Modal
              onClose={this.handleClose}
              open={this.state.openErrorModal}
              aria-describedby="error-modal-description"
              aria-labelledby="error-modal-title"
            >
              <Box
                style={{
                  margin: "auto",
                  textAlign: "center",
                  top: "50%",
                  padding: "20px",
                  borderRadius: "8px",
                  background: "#fff",
                  position: "absolute",
                  left: "50%",
                  width: '400px',
                  transform: "translate(-50%, -50%)",
                }}
                dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
              >
                <img src={modalErrorIcon} />
                <Typography variant="h6" id="error-modal-description" style={{ fontFamily: "Poppins", fontWeight: 600, padding: "16px", fontSize: '14px' }}>
                  {I18n.t("AddApoinmentErrormsg")}
                </Typography>
                <div
                  style={{
                    marginTop: "10px",
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <button
                    style={{
                      fontWeight: 600,
                      width: "400px",
                      fontFamily: "Poppins",
                      backgroundColor: "#527FE2",
                      padding: "10px",
                      marginBottom: "10px",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      border: "none",
                    }}
                    data-test-id="error-modal-try-again-button"
                    onClick={this.handleTryAgain}
                  >
                    {I18n.t("AddAppoinmentTryAgain")}
                  </button>
                  <button
                    style={{
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      width: "400px",
                      borderRadius: "8px",
                      border: '1px solid #527FE2',
                      backgroundColor: "#FFFFFF",
                      color: "#527FE2",
                      padding: "10px",
                    }}
                    data-test-id="error-modal-cancel-button"
                    onClick={this.handleClose}
                  >
                    {I18n.t("AddAppoinmentcancelBtnText")}
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </Box>
    )
  }
  openDatePicker = (event:any) => {
    this.setState({ isDatePickerOpen: true,  datePickerAnchorEl: event.currentTarget, }); 
  };
  editScreenDate = () => {
    return (
      <>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
          }}
        >
          <img
            alt="datePickerIcon"
            style={{ margin: "5px 5px 5px 0px", padding: "0px 5px 0px 0px" }}
            src={datePickerIcon}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={this.state.currentLanguage === "ar" ? "ardate" : ""}
              style={{
                marginTop: "5px",
                paddingTop: "2px",
                marginLeft: this.state.currentLanguage === "ar" ? "62%" : "0",
              }}
              inputRef={this.datePickerRef}
              InputProps={{
                disableUnderline: true,
              }}
              open={this.state.isDatePickerOpen}
              onClose={() => this.setState({ isDatePickerOpen: false })}
              PopoverProps={{
                anchorEl: this.state.datePickerAnchorEl,
              }}
              variant="inline"
              format={this.state.currentLanguage === "ar" ? "yyyy MMM dd" : "dd MMM yyyy"}
              id="date-picker-inline"
              value={this.state.editData.start ? new Date(this.state.editData.start) : null}
              onChange={(date) => {
                if (date) {
                  this.setState((prevState: any) => {
                    if (!date || isNaN(new Date(date).getTime())) {
                      return prevState;
                    }

                    const prevDate = new Date(prevState.editData.start);
                    const newDate = new Date(date);

                    prevDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

                    return {
                      editData: { ...prevState.editData, start: prevDate.toISOString() },
                    };
                  });

                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <img
          alt="Clock Icon"
          style={{ marginRight: "5px", cursor: "pointer" }}
          src={datePickerIcon}
          onClick={this.openDatePicker}
        />
      </>
    );
  };

  editScreenTime = (field: any, idx: any) => {
    return (
      <>
        <label
          style={{
            marginLeft: this.state.currentLanguage === "ar" ? "10px" : "0",
            marginRight: this.state.currentLanguage === "ar" ? "0" : "10px",
            alignItems: "center",
            display: "flex",
          }}
          htmlFor={field}
        >
          <img alt="Clock Icon" style={{ marginRight: "5px" }} src={clockIcon2} />
        </label>
        <input
          id={field}
          type="time"
          onChange={(e) => {
            this.setState((prevState) => {
              let prevDate = prevState.editData.start
                ? new Date(prevState.editData.start)
                : new Date();

              const [hours, minutes] = e.target.value.split(":").map(Number);

              prevDate.setHours(hours, minutes, 0, 0);
              return {
                editData: { ...prevState.editData, start: prevDate.toISOString() },
              };
            });
          }}
          value={
            this.state.editData.start
              ? new Date(this.state.editData.start).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
              : ""
          }
          style={{
            appearance: "none",
            outline: "none",
            background: "transparent",
            MozAppearance: "textfield",
            WebkitAppearance: "none",
            flex: 1,
            fontFamily: "poppins",
            fontSize: "16px",
            color: "#000",
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
            border: "none",
          }}
          aria-label="Select appointment time"
          data-test-id="timeinput"
        />
        <img alt="Clock Icon" style={{ marginRight: "5px" }} src={clockIcon2} />
      </>
    );
  };

  editScreenAttendee = () => {
    return (
      <div
        style={{
          flexDirection: this.state.currentLanguage === "en" ? "row" : "row-reverse",
          display: "flex",
          width: "100%",
        }}
      >
        <img alt="Attendees Icon" src={attendeesIcon2} />
        <label
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: this.state.currentLanguage === "en" ? "0" : "10px",
            marginRight: this.state.currentLanguage === "en" ? "10px" : "0",
          }}
          htmlFor="attendees"
        />
        <input
          value={this.state.editData.allow}
          id="attendees"
          type="text"
          onChange={(e) =>
            this.setState((prevState) => ({
              editData: { ...prevState.editData, allow: e.target.value }
            }))
          }
          data-test-id="attendeesInput"
          style={{
            width: "100%",
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
            outline: "none",
            background: "transparent",
            flex: 1,
            fontSize: "16px",
            border: "none",
            fontFamily: "Poppins",
            color: "black",
          }}
          aria-label="Add attendees"
        />

      </div>
    )
  }
  editNotes = () => {
    return (
      <div
        style={{
          flexDirection: this.state.currentLanguage === "en" ? "row" : "row-reverse",
          display: "flex",
          width: "100%",
        }}
      >
        <img alt="Date Icon" src={datePickerIcon} />
        <label
          htmlFor="Notes"
          style={{
            marginLeft: this.state.currentLanguage === "en" ? "0" : "10px",
            marginRight: this.state.currentLanguage === "en" ? "10px" : "0",
            display: "flex",
            alignItems: "center",
          }}
        />
        <input
          id="Notes"
          data-test-id="notesInput"
          value={this.state.editData.title}
          onChange={(e) =>
            this.setState((prevState) => ({
              editData: { ...prevState.editData, title: e.target.value }
            }))
          }
          type="text"
          style={{
            flex: 1,
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
            color: "black",
            fontFamily: "Poppins",
            fontSize: "16px",
            border: "none",
            width: "100%",
            background: "transparent",
            outline: "none",
          }}
          aria-label="Notes"
        />

      </div>
    )
  }

  renderEditScreenInputField = (field: any, idx: any) => {
    return (
      <div style={{ height: field === "date" ? "55px" : "auto", marginBottom: "15px", paddingBottom: field === "date" ? "-10px" : "auto" }} key={idx}>
        <div
          style={{
            padding: "10px 10px",
            height: "35px",
            display: "flex",
            alignItems: "center",
            flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            fontFamily: "Poppins",
            fontWeight: 800,
            justifyContent: "space-between",
          }}
          id="clockIconHide"
          className="hoverField5"
        >
          {field === "date" ? (
            this.editScreenDate()
          ) : field === "time" ? (
            this.editScreenTime(field, idx)
          ) : field === "attendees" ? (
            this.editScreenAttendee()
          ) : (
            this.editNotes()
          )}
        </div>
      </div>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    console.log("attend", this.state.editData);

    return (
      <div style={{ height: "100vh",flexDirection: this.state.currentLanguage === "ar" ? 'row-reverse' : "row", display: 'flex', backgroundColor: '#F1F5FC',  overflow: "auto" }}>
        <AppNavigation />
        <div className={this.state.currentLanguage === "ar" ? "arabic-text" : "default-text"} style={{ flex: 1, marginRight: this.state.currentLanguage === 'ar' ? "325px" : undefined, padding: "1.5rem" }}>
          <ThemeProvider theme={theme}>
            <div style={{ padding: "1rem 0"}}>
              <Box
                style={{
                  margin: "3rem 0",
                  direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr",
                  alignSelf: "center",
                  width: "96%",
                }}
              >
                <this.BreadcrumbsCust
                  separator={this.getValueBasedOnLanguage(
                    this.state.currentLanguage === "ar" ? <NavigateBeforeIcon /> : <NavigateNextIcon />,
                    this.state.currentLanguage === "ar" ? <NavigateNextIcon /> : <NavigateBeforeIcon />
                  )}
                  style={{
                    height: "9px",
                    color: "#000000",
                    textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    whiteSpace: "nowrap",
                    width: "5px",
                  }}
                >
                  <a
                    style={{
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      ...this.useStyles.navLinks,
                    }}
                    href="#"
                  >
                    {I18n.t("home")}
                  </a>
                  <a
                    style={{
                      ...this.useStyles.navLinks,
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                    href="#"
                  >
                    {I18n.t("appointmentmanagementText")}
                  </a>
                  <a
                    style={{
                      ...this.useStyles.navLinks,
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      color: "#3f51b5",
                      fontWeight: 700,
                    }}
                    href="#"
                  >
                    {I18n.t("EditAppointmentText")}
                  </a>
                </this.BreadcrumbsCust>
              </Box>

              <this.CardBox
                style={{
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "white",
                  width: "100%",
                }}
                id="card-box-id"
              >
                <div style={{ width: "96%", alignSelf: "center", padding: "1rem 0" }}>
                  <Typography
                    style={{
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      marginBottom: "20px",
                      fontWeight: 700,
                    }}
                    noWrap
                    variant="h6"
                  >
                    {I18n.t("AppointmentDetailsText")}
                  </Typography>
                  {this.addCutomer()}
                  {this.editForm()}
                </div>
              </this.CardBox>
              {this.editBoxModel()}
            </div>
          </ThemeProvider>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {

  tableBox: {
    borderColor: "#ccc",
    borderWidth: 1,
    padding: 15,
    marginVertical: 10,
    borderRadius: 4,
  },
  appointmentContainer: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    flexWrap: "wrap",
  },
};
// Customizable Area End
