// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  TextField,
  InputAdornment,
  Card,
  TextareaAutosize,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  MenuItem,
  createTheme,
  ThemeProvider,
  OutlinedInput
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {I18n} from "../../../components/src/languageJson/i18n"
import * as Yup from "yup";
import Select, { ValueType } from "react-select";
import { AddCircleOutline, Close } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";
import CustomModal from "../../../components/src/CustomModal.web";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./CustomFormController.web";
import { card, vat, home, email, taxino, auth, authId,uncheckedpng,checkedpng, helpicon,deleteimg, iban, time, branch, categories, location, ophours, topdesign, topdesignArabic, attatchfile, branchCodeIcon, branchRedIcon, catogoryRedIcon } from "./assets";
import { Autocomplete } from "@material-ui/lab";
import { getValueBasedOnLanguage as arabicCss, SelectMain,getCommonMenuProps, StyledFormControl, CommonBoxStyles, MenuItemSet } from "../../../components/src/constant"
import CustomDropdownIcon from "../../../components/src/CustomDropdownIcon";

const InputField = styled(TextField)({
  "& input::placeholder": {
    color: "#94A3B8", opacity: 1,fontFamily:"Poppins",fontSize: "16px", fontWeight: 400,
  },
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: "0px",
  },
  
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px", textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px", fontFamily: "Poppins",
    fontWeight: 400, lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1", borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#527FE2",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px", borderColor: "#527FE2",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px", marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    minWidth: "unset", maxWidth: "unset",
  },
});
//@ts-ignore
import TimeRange from "react-time-range";

const HeadingTime = styled(Typography)({
  fontFamily:"Poppins",
              fontWeight:"bold",
              fontSize:"16px"
              
});
const StyledTextarea = styled(TextareaAutosize)({
  width: '100%',
  border: '1px solid #CBD5E1',
  borderRadius: '10px',
  padding: '1rem',
  fontFamily: 'Poppins',
  fontSize: '1rem',
  '&::placeholder': {
    color: '#94A3B8', opacity:1
  },
  '&:hover': {
    borderColor: '#527FE2', 
  },
  '&:focus': {
    borderColor: '#527FE2', 
    outline: 'none', 
  },
  
});
const TimeRange123 = styled(TextField)({
 
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
    fontFamily: "Poppins",
    fontSize:"20px",fontWeight:700
  },

  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155", fontSize: "16px",fontWeight:400
  },

  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', 
    },
    '&:hover fieldset': {
      border: 'none', 
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },

  "& .MuiInputBase-root": {
    color: "black",fontFamily:"Poppins",fontSize:"1rem",paddingLeft:"5px"
  },

  "& .MuiOutlinedInput-input": {
    fontSize:"16px",fontWeight:400,padding:"0px"
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },

  "@media (max-width: 990px)": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});


// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  theme = createTheme({
    typography: {
      fontFamily: this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
    },
  
    overrides: {
      MuiInputBase: {
        root: {
          fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
        },
      },
      MuiTextField: {
        root: {
          fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
        },
      },
    }
  });

  StyledBox = styled(Box)({
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
  });
  
  BtnBox = styled(Box)({
    display: "flex", flexDirection: "column", alignItems: "center",
    justifyContent: "center", gap: "16px",
    marginTop: "4em",
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
    "@media (max-width:990px )": {
      display: "flex", flexDirection: "column",
      justifyContent: "start",
    },
  });
  
  CardBox = styled(Card)({
    borderRadius: "40px 40px 0px 0px", 
    padding: "72px 40px 72px 40px",
    "@media (max-width:990px )": {
      padding: "48px 20px 48px 20px",
    },
  });
  
  BoxDiv = styled(Box)({
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
    
    position: 'absolute',
    "@media (min-width: 1600px)": {
      top: '515px',
    },
  
    "@media (min-width: 601px) and (max-width: 1600px)": {
      top: '415px', 
    },
  
    "@media (min-width: 375px) and (max-width: 600px)": {
      top: '21%', 
    },
  
    "@media (max-width: 375px)": {
      top: '27%',  
    },
  });
  
  
  SubmitButn = styled(Button)({
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
    color: "white",
    borderRadius: "10px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontWeight:"bold",
    fontSize: "24px",
    padding: "6px 31px",
    maxWidth: "647px",
    height: "56px",
    "&:hover": {
      color: "white",
      backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
      fontSize:"18px",
      padding: "7px 27px",
    },
  });
  
  BranchTYpe: Dropdown[] = [
    { label: I18n.t("multipleBranch"), value: "2" },
    { label: I18n.t("individualBranch"), value: "0" },
  ];
  
  
  HeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "20px ",
    },
    "@media (max-width:899px )": {
      fontSize: "18",
    },
  
    fontFamily: "Poppins", lineHeight: "25px",
    fontSize: "24px", fontWeight: 700,
     padding: "0 40px"
  
  });
  
  AcceptBtn = styled(Button)({
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
    maxWidth: "42.5%",
    marginRight:"24px",
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
  
    background:
      "#527FE2",
    width: "100%",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    fontSize: "1em",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    }
  
  });
  Heading = styled(Typography)({
    fontFamily:"Poppins",
    fontWeight:"bold",
    fontSize:"2rem"
                
  });
  
  BoxTimeTop = styled(Box)({
    padding: "1.5rem 40px 1.5rem 40px",display:"flex",justifyContent:"space-between"  ,
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
    "@media (max-width:700px )": {
     flexDirection:"column"
    },
          
  });

  BoxTime = styled(Box)({
    fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
    display:"flex",alignItems:"center",width:"50%",gap:"0.5rem",
    flexDirection:this.getValueBasedOnLanguage('row-reverse', 'row'),
    "@media (max-width:700px )": {
     width:"100%",
    },
  });

  styles: { [key: string]: React.CSSProperties } = {
    modal: {
      position:
        'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      borderRadius: this.getValueBasedOnLanguage('8px 8px 8px 32px', '8px 8px 32px 8px'),
      width:"1024px",
      maxWidth:"98vw"
  
    },
    subModalDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', flexDirection: 'column',maxHeight:"98vh",overflow:"auto"
    },
    topboxstyle: {
      display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
      borderBottom: "1px solid #E2E8F0", padding: "24px 0px",
      flexDirection: this.getValueBasedOnLanguage('row-reverse', 'row')
    },
    dailyWorkingHrs: {
      display: "flex", width: "100%",flexDirection:"column"
    },
    topboxstyle2: {
    
      padding: "24px 0px",width:"100%"
    },
  };

  SubHeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "18px ",
    },
    "@media (max-width:899px )": {
      fontSize: "16",
    },
  
    fontFamily: "Poppins",
    fontSize: "20px", fontWeight: 700, 
    padding: "0 40px",
    textAlign:this.getValueBasedOnLanguage('right', 'left')
  });

  PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({
    
    border: `1px solid ${borderColor || "#CBD5E1"}`,
    borderRadius: 8,
    height: 56,
    zIndex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: this.getValueBasedOnLanguage('0', '10px'), 
    "&:hover": {
    borderColor: "#527FE2", 
    },
    "&:focus-within": {
      borderColor: "#527FE2", 
    },
    "& input": {
      border: "none",
    },
    "& input:focus": {
      border: "none",
      outline: "none",
    },
    
    "& .PhoneInputCountry": {
      display: "flex",
      alignItems: "center",
      gap: this.getValueBasedOnLanguage('8px', '0'),
      paddingLeft: this.getValueBasedOnLanguage('10px', '0'), 
    },
  
    "& .PhoneInputInput": {
      color: "#334155",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px",
      fontWeight: 400,
      background: "transparent",
      lineHeight: "24px",
  
    },
    "& input:focus-visible": {
      border: "none",
      outline: "none",
    },
    "& input::placeholder": {
      color: "#94A3B8", opacity: 1,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171",
    },
  
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px", 
      borderRadius: "8px",
    },
  
  }));

  userSchema = () => {
    return Yup.object().shape({
      NationalId: Yup.string().matches(/^\d+$/, "*Please enter a valid National ID number.rrr")
        .required("*Please enter a valid National ID number.rrr"),
      VatRegNo: Yup.string().required("*Please enter a valid VAT Registration number."),
      NationalAddress: Yup.string().required("Please enter a valid National Address"),
      BusinessName: Yup.string().required("*Please enter a valid business name"),
      industryCat: Yup.string().required("*Please select your Industry category ."),
      branchSelection: Yup.string().required("*Please choose eithr Individual Branch or Multiple Branches."),
      branchName: Yup.string().required("*Please enter the Branch Name"),
      branchCode: Yup.string().required("*Please enter the Branch Code"),
      branchLOcation: Yup.string().required("*Please provide the Branch Location"),
      branchContactNo: Yup.string()
        .test('is-valid-phone', '*Please enter valid contact number for the branch', function (value) {
          return isValidPhoneNumber(value || '');
        })
        .required('*Please enter valid contact number for the branch'),

      branchOperatingHours: Yup.string().required("*Please specify the Branch Operating Hours"),
      businessDescription: Yup.string().required("*Please provide brief Business Description"),
    });
  };



  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  }

  getInputProps = (icon:any) => ({
    style: {
      paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
      paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
    },
    startAdornment: this.getValueBasedOnLanguage(null, (
      <InputAdornment position="start">
        <img src={icon} alt="user icon" style={{ width: 20, height: 16 }} />
      </InputAdornment>
    )),
    endAdornment: this.getValueBasedOnLanguage((
      <InputAdornment position="start" style={{ marginRight: '0', marginLeft: '8px' }}>
        <img src={icon} alt="user icon" style={{ width: 20, height: 16 }} />
      </InputAdornment>
    ), null),
  });  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.theme}>
        <this.StyledBox>
        {
          this.state.dialogOpen && (
            <CustomModal 
              dialogOpen={this.state.dialogOpen}
              successError={this.state.successError}
              handleDialogClose={this.handleDialogClose}
              typographyText={
                this.state.successError
                  ? I18n.t("requiredFieldMissing")
                  : I18n.t("businessDetailsSaved")
              }
              buttonName={this.state.successError ? I18n.t("tryAgain") : I18n.t("back")}
            />
          )
        }
        <this.StyledBox style={{ padding: "16px 128px 16px 128px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <img src={helpicon} alt="helpicon" style={{ maxWidth: "160px" }} />
        </this.StyledBox>
        <img src={this.getValueBasedOnLanguage(topdesignArabic, topdesign)} alt="topdesign" style={{ width: "100%", maxHeight: "65vh", objectFit: "cover",position:"relative" }} />

        <this.BoxDiv padding={{ xs: "15px 25px 25px 25px", sm: "16px 80px 16px 80px" }} >
      
          <this.CardBox >
            <this.StyledBox
              id="modal-title"
              mb="20px"
              fontFamily="Poppins"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "32px" }}
              style={{
                textAlign:this.getValueBasedOnLanguage('right', 'left')
              }}
            >
              {I18n.t("generalInfo")}
            </this.StyledBox>

            <form onSubmit={this.handleSubmit} noValidate autoComplete="off">

              <Grid container spacing={3}
              direction={this.getValueBasedOnLanguage("row-reverse", "row")}
              >
                <Grid item sm={6} xs={12} md={6}  >
                  <InputField
                    data-test-id="NationalId"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("nationalId")}
                    value={this.state.NationalId}
                    error={this.state.errors.NationalId}
                    onChange={(event) => {
                      const value = event.target.value;
                      const NospaceValue = value?.replace(/\s/g, ''); 
                      this.onValueChange("NationalId", NospaceValue);
                    }}
                    InputProps={{
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(null, (
                        <InputAdornment position="start">
                          <img src={card} alt="icon" style={{
                            width: 20,
                            height: 16,
                          }} />
                        </InputAdornment>
                      )),
                      endAdornment: this.getValueBasedOnLanguage((
                        <InputAdornment position="start" style={{
                          marginRight:'0',
                            marginLeft:'8px'
                        }}>
                          <img src={card} alt="icon" style={{ 
                            width: 20,
                            height: 16,
                          }} />
                        </InputAdornment>
                      ), null),
                    }}
                  />
                  {
                    this.state.errors.NationalId &&
                      (
                        <span
                          style={{
                            textAlign: this.getValueBasedOnLanguage("right", "left"),
                            direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                          }}
                        >
                          {this.getErrorMessageNonFormik(this.state.errors.NationalId)}
                        </span>
                      )
                  }
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="VatRegNo"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("vatRegNo")}
                    value={this.state.VatRegNo}
                    error={this.state.errors.VatRegNo}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      const NospaceValue = value?.replace(/\s/g, ''); 
                      this.onValueChange("VatRegNo",NospaceValue);
                    }}

                    InputProps={this.getInputProps(vat)}
                    
                  />
                  {
                    this.state.errors.VatRegNo && (
                      <span
                          style={{
                            textAlign: this.getValueBasedOnLanguage("right", "left"),
                            direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                          }}
                        >
                          {this.getErrorMessageNonFormik(this.state.errors.VatRegNo)}
                        </span>
                    )
                      
                  }

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="NationalAddress"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("nationaAddress")}
                    value={this.state.NationalAddress}
                    error={this.state.errors.NationalAddress}
                    onChange={(event: any) => {
                      this.onValueChange(
                        "NationalAddress",
                        event.target.value
                      );
                    }}

                    InputProps={{
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(null, (
                        <InputAdornment position="start">
                          <img src={home} alt="user icon" style={{
                            width: 20,
                            height: 16,
                          }} />
                        </InputAdornment>
                      )),
                      endAdornment: this.getValueBasedOnLanguage((
                        <InputAdornment position="start" style={{
                          marginRight:'0',
                            marginLeft:'8px'
                        }}>
                          <img src={home} alt="user icon" style={{ 
                            width: 20,
                            height: 16,
                          }} />
                        </InputAdornment>
                      ), null),
                    }}
                  />
                  {
                    this.state.errors.NationalAddress && (
                      <span
                          style={{
                            textAlign: this.getValueBasedOnLanguage("right", "left"),
                            direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                          }}
                        >
                          {this.getErrorMessageNonFormik(this.state.errors.NationalAddress)}
                        </span>
                    )

                  }

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="email"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("supportEmail")}
                    value={this.state.email}
                    onChange={(event: any) => {
                      this.onValueChange("email", event.target.value);
                    }}

                    InputProps={{
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(null, (
                        <InputAdornment position="start">
                          <img src={email} alt="user icon" style={{
                            width: 20,
                            height: 16,
                          }} />
                        </InputAdornment>
                      )),
                      endAdornment: this.getValueBasedOnLanguage((
                        <InputAdornment position="start" style={{
                          marginRight:'0',
                            marginLeft:'8px'
                        }}>
                          <img src={email} alt="user icon" style={{ 
                            width: 20,
                            height: 16,
                          }} />
                        </InputAdornment>
                      ), null),
                    }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <this.StyledBox
                    id="modal-title"
                    fontFamily="Poppins"
                    fontWeight="bolder"
                    fontSize={{ xs: "20px", sm: "32px" }}
                    style={{
                      textAlign:this.getValueBasedOnLanguage('right', 'left')
                    }}
                  >
                    {I18n.t("companyInfo")}
                  </this.StyledBox>
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="BusinessName"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("businessName")}
                    value={this.state.BusinessName}
                    error={this.state.errors.BusinessName}

                    onChange={(event: any) => {
                      this.onValueChange(
                        "BusinessName", event.target.value
                      );
                    }}
                    InputProps={this.getInputProps(vat)}
                  />
                  {
                    this.state.errors.BusinessName && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.BusinessName)}
                    </span>
                      )
                  }  

                </Grid>
                <Grid item sm={6} xs={12} md={6}>

                  <InputField
                    data-test-id="TradingLicense"
                    fullWidth
                    variant="outlined"
                    error={this.state.errors.TradingLicense}
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("tradeLicense")}
                    value={this.state.TradingLicense}

                    onClick={this.handleFileInputClick4}
                    InputProps={{
                      readOnly: true,
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="start">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>,
                        <InputAdornment position="start">
                          <img src={card} alt="License" style={{ width: 20, height: 16 }} />
                        </InputAdornment>
                      ),
                      endAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="end">
                          <img src={card} alt="License" style={{ width: 20, height: 16 }} />
                        </InputAdornment>,
                        <InputAdornment position="end">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    
                  />
                  <input
                    type="file"
                    data-test-id="TradingLicenseFile"
                    ref={this.state.fileInput4}
                    style={{ display: 'none' }}
                    accept=".pdf"
                    onChange={this.handleFileChange4}
                  />
                  {
                    this.state.errors.TradingLicense && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.TradingLicense)}
                    </span>
                    )
                    
                  }

                </Grid>

                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="VATtaxidentifire"
                    fullWidth
                    variant="outlined"
                    onClick={this.handleFileInputClick3}
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("vatTaxIdentifier")}
                    value={this.state.VATtaxidentifire}
                    error={this.state.errors.VATtaxidentifire}
                    onChange={(event: any) => {
                      this.onValueChange("VATtaxidentifire", event.target.value);
                    }}

                    InputProps={{
                      readOnly: true,
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="start">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>,
                        <InputAdornment position="start">
                          <img src={taxino} alt="TaxIdentifier" style={{ width: 20, height: 16 }} />
                        </InputAdornment>
                      ),
                      endAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="end">
                          <img src={taxino} alt="TaxIdentifier" style={{ width: 20, height: 16 }} />
                        </InputAdornment>,
                        <InputAdornment position="end">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    data-test-id="VATtaxidentifireFile"
                    ref={this.state.fileInput3}
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange3}
                    accept=".pdf"
                  />
                  {
                    this.state.errors.VATtaxidentifire && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.VATtaxidentifire)}
                    </span>
                    )
                    
                  }

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="authorizedCertificate"
                    fullWidth
                    variant="outlined"
                    error={this.state.errors.authorizedCertificate}
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("authCertificate")}
                    value={this.state.authorizedCertificate}
                    onChange={(event: any) => {
                      this.onValueChange("authorizedCertificate", event.target.value);
                    }}
                    onClick={this.handleFileInputClick2}

                    InputProps={{
                      readOnly: true,
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="start">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>,
                        <InputAdornment position="start">
                          <img src={auth} alt="authCertificate" style={{ width: 20, height: 16 }} />
                        </InputAdornment>
                      ),
                      endAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="end">
                          <img src={auth} alt="authCertificate" style={{ width: 20, height: 16 }} />
                        </InputAdornment>, 
                        <InputAdornment position="end">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    ref={this.state.fileInput2}
                    data-test-id="authorizedCertificateFile"
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange2}
                    accept=".pdf"
                  />
                  {
                    this.state.errors.authorizedCertificate && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.authorizedCertificate)}
                    </span>
                    )
                    
                  }
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="authorizedIDcard"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("authIdCard")}
                    value={this.state.authorizedIDcard}
                    error={this.state.errors.authorizedIDcard}
                    onChange={(event: any) => {
                      this.onValueChange("authorizedIDcard", event.target.value);
                    }}
                    onClick={this.handleFileInputClick1}
                  
                    InputProps={{
                      readOnly: true,
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="start">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>,
                        <InputAdornment position="start">
                          <img src={authId} alt="authidcard" style={{ width: 20, height: 16 }} />
                        </InputAdornment>
                      ),
                      endAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="end">
                        <img src={authId} alt="authIdCard" style={{ width: 20, height: 16 }} />
                      </InputAdornment>,
                         <InputAdornment position="end">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                       </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    ref={this.state.fileInput1}
                    data-test-id="authorizedIDcardFile"
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange1}
                    accept=".pdf"
                  />

                  {
                    this.state.errors.authorizedIDcard && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.authorizedIDcard)}
                    </span>
                    )
                  }

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="ibanCertificate"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      },
                    }}
                    placeholder={I18n.t("ibanCertificate")}
                    error={this.state.errors.ibanCertificate}
                    value={this.state.ibanCertificate}

                    onClick={this.handleFileInputClick}

                    InputProps={{
                      readOnly: true,
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                      },
                      startAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="start">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>,
                        <InputAdornment position="start">
                          <img src={iban} alt="ibanCertificate" style={{ width: 20, height: 16 }} />
                        </InputAdornment>
                      ),
                      endAdornment: this.getValueBasedOnLanguage(
                        <InputAdornment position="end">
                          <img src={iban} alt="ibanCertificate" style={{ width: 20, height: 16 }} />
                        </InputAdornment>,
                        <InputAdornment position="end">
                          <IconButton>
                            <img src={attatchfile} alt="attachFile" style={{ width: 11, height: 22 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    data-test-id="ibanCertificateFile"
                    ref={this.state.fileInput}
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange}
                    accept=".pdf"
                  />

                  {
                    this.state.errors.ibanCertificate && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.ibanCertificate)}
                    </span>
                    )
                      
                  }
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="OtherCertificate"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                        textAlign:this.getValueBasedOnLanguage('right', 'left'),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                        paddingRight: this.getValueBasedOnLanguage('0', '8px')
                      },
                    }}
                    placeholder={I18n.t("otherDoc")}
                    value={this.state.InputFileOther}

                    onClick={this.handleOtherFileInputClick}

                    InputProps={{
                      style:{
                        paddingLeft: this.getValueBasedOnLanguage("4px", "14px"),
                        paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr")
                      },
                      readOnly:true,
                      startAdornment: this.getValueBasedOnLanguage(null, (
                        <InputAdornment position="start">
                          <img src={attatchfile} alt="user icon" style={{
                            width: 11,
                            height: 22,
                          }} />
                        </InputAdornment>
                      )),
                      endAdornment: this.getValueBasedOnLanguage((
                        <InputAdornment position="start" style={{
                          marginRight:'0',
                            marginLeft:'8px'
                        }}>
                          <img src={attatchfile} alt="user icon" style={{ 
                            width: 11,
                            height: 22,
                          }} />
                        </InputAdornment>
                      ), null),
                    }}
                    
                  />
                  <input
                    type="file"
                    data-test-id="OtherCertificateFile"
                    ref={this.state.otherFileInput}
                    style={{ display: 'none' }}
                    onChange={this.OtherhandleFileChange}
                    accept=".pdf"
                  />

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                    <StyledFormControl variant="outlined" fullWidth
                    >
                      <SelectMain
                        data-test-id="industryCat"
                        MenuProps={getCommonMenuProps}
                        name={"industryCat"}
                        value={this.state.industryCat}
                        error={this.state.errors.industryCat}
                        onChange={(event) => {
                          this.onValueChange("industryCat", event.target.value as string);
                        }}
                        displayEmpty
                        IconComponent={CustomDropdownIcon}
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              src={this.state.errors.industryCat ? catogoryRedIcon : categories}
                              alt="dep_icon"
                            />

                          </InputAdornment>
                        }
                        renderValue={(value) => {
                          const selectedCatogoriy = this.state.categoryData.find((option : any) => option.attributes?.id === value);
                          return (
                            <Box
                              style={{
                                color: selectedCatogoriy ? 'currentColor' : '#94A3B8',
                                ...CommonBoxStyles
                              }}
                            >
                              {selectedCatogoriy ? selectedCatogoriy.attributes?.name : I18n.t('categories')}
                            </Box>
                          );
                        }}
                        input={<OutlinedInput />}
                      >
                        {this.state.categoryData.map((optionVal : any, index: number) => (
                          <MenuItemSet
                            style={{
                              borderBottom: '1px solid #E2E8F0',
                              fontFamily: arabicCss("Noto Sans Arabic", "Poppins"),
                            }}
                            className="setMenuItem"
                            key={index}
                            value={optionVal.attributes?.id}
                          >
                            {optionVal.attributes?.name}
                          </MenuItemSet>
                        ))}
                      </SelectMain>
                    </StyledFormControl>
                  {
                    this.state.errors.industryCat && (
                      <span
                      style={{
                        textAlign: this.getValueBasedOnLanguage("right", "left"),
                        direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      }}
                    >
                      {this.getErrorMessageNonFormik(this.state.errors.industryCat)}
                    </span>
                    )
                      
                  }
                </Grid>


                <Grid item sm={6} xs={12} md={6}>
                  <StyledTextarea minRows={10}
                    style={{
                      textAlign:this.getValueBasedOnLanguage('right', 'left'),
                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                    }}
                    value={this.state.businessDescription}
                    data-test-id="businessDescription"
                    onChange={(event: any) => {
                      this.onValueChange("businessDescription", event.target.value);
                    }}
                    onError={this.state.errors.businessDescription}
                    placeholder={I18n.t("businessDescription")}
                    />

                  {
                    <span
                    style={{
                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                    }}
                  >
                    {this.getErrorMessageNonFormik(this.state.errors.businessDescription)}
                  </span>
                    
                  }


                </Grid>

                <Grid item sm={6} xs={12} md={6}>
                    <StyledFormControl variant="outlined" fullWidth
                    >
                      <SelectMain
                        data-test-id="branchSelection"
                        MenuProps={getCommonMenuProps}
                        name={"branchSelection"}
                        value={this.state.branchSelection}
                        error={this.state.errors.branchSelection}
                        onChange={(event) => {
                          this.onValueChange("branchSelection", event.target.value as string);
                        }}
                        displayEmpty
                        IconComponent={CustomDropdownIcon}
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              src={this.state.errors.branchSelection ? branchRedIcon : branch}
                              alt="dep_icon"
                            />

                          </InputAdornment>
                        }
                        renderValue={(value) => {
                          const branchSelection = this.state.branchTypeOptions.find((option) => option.value === value);
                          return (
                            <Box
                              style={{
                                color: branchSelection ? 'currentColor' : '#94A3B8',
                                ...CommonBoxStyles
                              }}
                            >
                              {branchSelection ? branchSelection.label : I18n.t('branchSelection')}
                            </Box>
                          );
                        }}
                        input={<OutlinedInput />}
                      >
                        {this.state.branchTypeOptions.map((optionVal, index) => (
                          <MenuItemSet
                            style={{
                              borderBottom: '1px solid #E2E8F0',
                              fontFamily: arabicCss("Noto Sans Arabic", "Poppins"),
                            }}
                            className="setMenuItem"
                            key={index}
                            value={optionVal.value}
                          >
                            {optionVal.label}
                          </MenuItemSet>
                        ))}
                      </SelectMain>
                    </StyledFormControl>
                  {
                    this.state.errors.branchSelection && (
                      <span
                    style={{
                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                    }}
                  >
                    {this.getErrorMessageNonFormik(this.state.errors.branchSelection)}
                  </span>
                    )
                      
                  }

                </Grid>
                {this.state.branchSelection?<>
                <Grid item xs={12}>
                 
                  <this.StyledBox
                    display="flex"
                    alignItems="center"
                    mb="20px"
                    flexDirection={this.getValueBasedOnLanguage("row-reverse", "row")}
                    justifyContent="space-between"
                  >
                    <this.StyledBox
                      id="modal-title"
                      fontFamily="Poppins"
                      fontWeight="bolder"
                      fontSize={{ xs: "20px", sm: "24px" }}
                    >
                      {I18n.t('branchDetails')}
                    </this.StyledBox>
                    {
                    this.state.branchSelection =="2" ?
                      <this.StyledBox>
                        <Button style={{ display: "flex", 
                        fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
                            flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), fontSize: "16px", textTransform: 'none', color: "#527FE2", fontWeight:700 
                          }} 
                            data-test-id="addfield" 
                            onClick={this.addBranch}
                            startIcon={this.getValueBasedOnLanguage(<AddCircleOutline />, null)} 
                            endIcon={this.getValueBasedOnLanguage(null, <AddCircleOutline />)} 
                          >
                          {I18n.t('addBranch')}
                        </Button>
                      </this.StyledBox>:null
                    }
                  </this.StyledBox>
                </Grid>

                <>
                  {this.state.branches.map((branch: any, index: any) => (
                    <this.StyledBox key={branch.id} mb={4} width={'100%'} padding={'12px'}>
                      <this.StyledBox mb={2} style={{display:"flex",justifyContent:"space-between",
                        flexDirection:this.getValueBasedOnLanguage('row-reverse', 'row')
                      }}>
                        <strong style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),fontSize:"18px"}}>{I18n.t('branch')} {index + 1}</strong>
                        {index >0 ?
                        <Button style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} data-test-id={`delete-${index}`} onClick={()=>this.deleteBranch(index)}><img src={deleteimg} alt="deleteimg" style={{height:"24px",width:"24px",cursor:"pointer"}}/></Button>:null}
                      </this.StyledBox>
                      <Grid container spacing={2} direction={this.getValueBasedOnLanguage("row-reverse", "row")}>
                        
                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchName-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              style: {
                                height: "16px",
                                textAlign:this.getValueBasedOnLanguage('right', 'left'),
                                direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                              },
                            }}
                            placeholder={I18n.t("branchName")}
                            value={this.state.branches.branchName}
                            error={this.state.errors[`branches[${index}].branchName`]}
                            onChange={(event: any) => this.onValueChangeNew(index, "branchName", event.target.value)}
                            
                            InputProps={{
                              style:{
                                paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                              },
                              startAdornment: this.getValueBasedOnLanguage(null, (
                                <InputAdornment position="start">
                                  <img src={card} alt="Branch Name Icon" style={{
                                    width: 20,
                                    height: 16,
                                  }} />
                                </InputAdornment>
                              )),
                              endAdornment: this.getValueBasedOnLanguage((
                                <InputAdornment position="start" style={{
                                  marginRight:'0',
                                    marginLeft:'8px'
                                }}>
                                  <img src={card} alt="Branch Name Icon" style={{ 
                                    width: 20,
                                    height: 16,
                                  }} />
                                </InputAdornment>
                              ), null),
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchName`] && (
                             <span
                             style={{
                               textAlign: this.getValueBasedOnLanguage("right", "left"),
                               direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                             }}
                           >
                            {this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchName`])}
                           </span>
                          )
                            
                          }
                        </Grid>

                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchCode-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{ style: { height: "16px", textAlign:this.getValueBasedOnLanguage('right', 'left'),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),} }}
                            placeholder={I18n.t("branchCode")}
                            value={this.state.branches.branchCode}
                            error={this.state.errors[`branches[${index}].branchCode`]}
                            onChange={(event: any) => this.onValueChangeNew(index, "branchCode", event.target.value)}
                            
                            InputProps={{
                              style:{
                                paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                              },
                              startAdornment: this.getValueBasedOnLanguage(null, (
                                <InputAdornment position="start">
                                  <img src={branchCodeIcon} alt="Branch Code Icon" style={{
                                    width: 20,
                                    height: 16,
                                  }} />
                                </InputAdornment>
                              )),
                              endAdornment: this.getValueBasedOnLanguage((
                                <InputAdornment position="start" style={{
                                  marginRight:'0',
                                    marginLeft:'8px'
                                }}>
                                  <img src={branchCodeIcon} alt="Branch Code Icon" style={{ 
                                    width: 20,
                                    height: 16,
                                  }} />
                                </InputAdornment>
                              ), null),
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchCode`] && (
                            <span
                            style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                            }}
                          >
                            {this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchCode`])}
                          </span>
                          )
                          }
                        </Grid>
  
                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchLocation-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{ style: { height: "16px", textAlign:this.getValueBasedOnLanguage('right', 'left'),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"), } }}
                            placeholder={I18n.t("branchLocation")}
                            value={this.state.branches.branchLocation}
                            error={this.state.errors[`branches[${index}].branchLocation`]}
                            onChange={(event: any) => this.onValueChangeNew(index, "branchLocation", event.target.value)}

                            InputProps={{
                              style:{
                                paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                              },
                              startAdornment: this.getValueBasedOnLanguage(null, (
                                <InputAdornment position="start">
                                  <img src={location} alt="Location Icon" style={{
                                    width: 20,
                                    height: 16,
                                  }} />
                                </InputAdornment>
                              )),
                              endAdornment: this.getValueBasedOnLanguage((
                                <InputAdornment position="start" style={{
                                  marginRight:'0',
                                    marginLeft:'8px'
                                }}>
                                  <img src={location} alt="Location Icon" style={{ 
                                    width: 20,
                                    height: 16,
                                  }} />
                                </InputAdornment>
                              ), null),
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchLocation`] && (
                            <span
                            style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                            }}
                          >
                            {this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchLocation`])}
                          </span>
                          )
                            }
                        </Grid>
               
                        <Grid item sm={6} xs={12} md={6}>
                          <this.PhoneStyle
                            placeholder={I18n.t("branchContactNum")}
                            data-test-id={`branchContactNo-${index}`}
                            value={this.state.branches.branchContactNo}
                            onChange={(value: any) => this.onValueChangeNew(index, "branchContactNo", value)}
                            phone
                            defaultCountry="AE"
                            error={this.state.errors[`branches[${index}].branchContactNo`]}
                            style={{
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                              textAlign: this.getValueBasedOnLanguage("right", "left"), 
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchContactNo`] && (
                            <span
                            style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                            }}
                          >
                            {this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchContactNo`])}
                          </span>
                          )
                            }
                        </Grid>

                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchOperatingHours-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{ style: { height: "16px", textAlign:this.getValueBasedOnLanguage('right', 'left'),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"), } }}
                            placeholder={I18n.t("branchOperatingHrs")}
                            value={branch.branchOperatingHours}
                            onClick={() => this.handleShowTime(index)}
                            error={this.state.errors[`branches[${index}].branchOperatingHours`]}

                            InputProps={{
                              readOnly: true,
                              style:{
                                paddingLeft: this.getValueBasedOnLanguage("0px", "14px"),
                                paddingRight: this.getValueBasedOnLanguage("14px", "0px"),
                              },
                              startAdornment: this.getValueBasedOnLanguage(
                                <InputAdornment position="start" data-test-id="modalOperatingHours">
                                  <IconButton>
                                    <img src={time} alt="Time Icon" 
                                      style={{ width: 20, height: 20, cursor: "pointer" }}
                                      onClick={() => this.handleShowTime(index)}
                                    />
                                  </IconButton>
                                </InputAdornment>,
                                <InputAdornment position="start">
                                  <img src={ophours} alt="Operating Hours Icon" style={{ width: 18, height: 21 }} />
                                </InputAdornment>
                              ),
                              endAdornment: this.getValueBasedOnLanguage(
                                <InputAdornment position="start" style={{
                                  marginRight:'0',
                                  marginLeft:'8px'
                                }}>
                                  <img src={ophours} alt="Operating Hours Icon" style={{ width: 18, height: 21 }} />
                                </InputAdornment>,
                                <InputAdornment position="start" data-test-id="modalOperatingHours">
                                  <IconButton>
                                    <img src={time} alt="Time Icon" 
                                      style={{ width: 20, height: 20, cursor: "pointer" }}
                                      onClick={() => this.handleShowTime(index)}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}

                          />
                          {this.state.errors[`branches[${index}].branchOperatingHours`] && (
                            <span
                            style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                            }}
                          >
                            {this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchOperatingHours`])}
                          </span>
                          )
                            }
                        </Grid>


                      </Grid>
                    </this.StyledBox>
                  ))}

                  {/* Modal for Setting Operating Hours */}
                  <Modal
                            open={this.state.showMOdaltime}
                            onClose={this.handleHideshowTime}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                          >
                            <this.StyledBox style={this.styles.modal}>
                              <this.StyledBox style={this.styles.subModalDiv}>
                                <this.StyledBox style={this.styles.topboxstyle}>
                                  <this.HeadingText style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("branchOpHrs")}</this.HeadingText>
                                  <Close style={{ 
                                      paddingLeft: this.getValueBasedOnLanguage("1rem", "0"), 
                                      paddingRight: this.getValueBasedOnLanguage("0", "1rem") 
                                    }} 
                                    onClick={this.handleHideshowTime} />
                                </this.StyledBox>

                                <this.StyledBox style={this.styles.topboxstyle}>
                                  <this.SubHeadingText style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("workingDays")}</this.SubHeadingText>
                                </this.StyledBox>

                                <this.StyledBox style={this.styles.topboxstyle2}>
                                  <FormGroup
                                    style={{ display: "flex", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), 
                                    padding: "16px 40px 16px 40px", maxWidth:"100%",borderBottom: "1px solid #E2E8F0",justifyContent:"space-between" }}
                                  >
                                    {["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((day) => (
                                      <FormControlLabel
                                        key={day}
                                        control={
                                          <Checkbox
                                          icon={<img src={uncheckedpng} style={{width:"20px",height:"20px",paddingBottom:"6px"}}/>}
                                          checkedIcon={<img src={checkedpng} style={{width:"20px",height:"20px",paddingBottom:"6px"}}/>}
                                            style={{ color: "#527FE2" }}
                                            name={day}
                                            checked={this.state.branches[this.state.modalBranchIndex]?.selectedDays.includes(day)}
                                            onChange={(event) => this.handleCheckboxChange(this.state.modalBranchIndex, day, event.target.checked)}
                                          />
                                        }
                                        label={I18n.t(day.charAt(0).toUpperCase() + day.slice(1))}
                                        data-test-id ={day}
                                        labelPlacement={this.getValueBasedOnLanguage("start", "end")}
                                      />
                                    ))}
                                  </FormGroup>
                                </this.StyledBox>

                                <this.StyledBox style={this.styles.dailyWorkingHrs}>
                                  <this.SubHeadingText>{I18n.t("workingHrs")}</this.SubHeadingText>
                                <this.BoxTimeTop >
                            
                                <this.BoxTime >
                                  <HeadingTime style={{marginRight:"1rem", fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("startTime")}</HeadingTime>
                                  <TimeRange123
                                    value={this.state.branches[this.state.modalBranchIndex]?.startTime.hours}
                                    data-test-id={`starthours-${this.state.modalBranchIndex}`}
                                    onChange={(eve:any)=>this.handleHourChange(eve,this.state.modalBranchIndex)}
                                    placeholder="00"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0, max: 24 }}
                                    style={{
                                      width: 60, fontSize: "20px",
                                      fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                                    }}
                                  />
                                  <TimeRange123
                                    value={this.state.branches[this.state.modalBranchIndex]?.startTime.minutes}
                                    data-test-id={`startminutes-${this.state.modalBranchIndex}`}
                                    onChange={(eve:any)=>this.handleMinuteChange(eve,this.state.modalBranchIndex)}
                                    placeholder="00"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0, max: 59 }}
                                    style={{
                                      width: 60, fontSize: "20px",
                                      fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                                    }}
                                  />
                                    <TimeRange123
                                      select
                                      value={this.state.branches[this.state.modalBranchIndex]?.startTime.period}
                                      onChange={(eve:any)=>this.handlePeriodChange(eve,this.state.modalBranchIndex)}
                                      data-test-id={`startperiod-${this.state.modalBranchIndex}`}
                                    >
                                      <MenuItem style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} value="AM">{I18n.t("am")}</MenuItem>
                                      <MenuItem style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} value="PM">{I18n.t("pm")}</MenuItem>
                                    </TimeRange123>
                                  </this.BoxTime>

                                  <this.BoxTime >
                                  <HeadingTime style={{
                                      fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"),
                                      marginRight:this.getValueBasedOnLanguage("0", "26px")
                                    }}>{I18n.t("endTime")}</HeadingTime>
                                  <TimeRange123
                                    value={this.state.branches[this.state.modalBranchIndex]?.endTime.hours}
                                    data-test-id={`endhours-${this.state.modalBranchIndex}`}
                                    onChange={(eve:any)=>this.handleHourChangeEnd(eve,this.state.modalBranchIndex)}
                                    placeholder="00"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0, max: 24}}
                                    style={{
                                      width: 60, fontSize: "20px",
                                      fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                                    }}
                                  />
                                  <TimeRange123
                                    value={this.state.branches[this.state.modalBranchIndex]?.endTime.minutes}
                                    data-test-id={`endminutes-${this.state.modalBranchIndex}`}
                                    onChange={(eve:any)=>this.handleMinuteChangeEnd(eve,this.state.modalBranchIndex)}
                                    placeholder="00"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0, max: 59 }}
                                    style={{
                                      width: 60, fontSize: "20px",
                                      fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                                    }}
                                  />
                                    <TimeRange123
                                      select
                                      value={this.state.branches[this.state.modalBranchIndex]?.endTime.period}
                                      onChange={(eve:any)=>this.handlePeriodChangeEnd(eve,this.state.modalBranchIndex)}
                                      data-test-id={`endperiod-${this.state.modalBranchIndex}`}
                                    >
                                      <MenuItem style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} value="AM">{I18n.t("am")}</MenuItem>
                                      <MenuItem style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} value="PM">{I18n.t("pm")}</MenuItem>
                                    </TimeRange123>
                                  </this.BoxTime>
                                </this.BoxTimeTop>
                                </this.StyledBox>

                                <this.StyledBox style={{ display: "flex", justifyContent: "flex-end",
                                 padding: "20px 0px", width: "100%", borderTop: "1px solid #CBD5E1" }}>
                                  <this.AcceptBtn style={{fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}} data-test-id="modalsubmit" onClick={this.onHandleSubmitTime}>{I18n.t("submitHrsModal")}</this.AcceptBtn>
                                </this.StyledBox>
                              </this.StyledBox>
                            </this.StyledBox>
                  </Modal>

                </>
                </>:null}
              </Grid>
              <this.BtnBox >
             {this.state.emailValidation? <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {this.state.emailValidation}
      </Typography>:null}
                <this.SubmitButn type="submit" fullWidth>
                {I18n.t("submit")}
                </this.SubmitButn>
                <Typography style={{ fontFamily:this.getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"), fontWeight: 500, fontSize: "20px" }}> {I18n.t("alreadyHaveAccount")}
                  <span data-test-id="login" onClick={this.NavigatetoLogin} style={{ 
                    color: "#527FE2",
                    cursor: "pointer",
                    fontWeight:"bold",
                    [this.getValueBasedOnLanguage("marginRight", "marginLeft")]: "10px"
                  }}>{I18n.t("login")}</span> </Typography>
              </this.BtnBox>
            </form>
            {/* )}}
              </Formik> */}
          </this.CardBox>
        </this.BoxDiv>

      </this.StyledBox>
      </ThemeProvider>
      
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End