// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { DepartmentList, OrgNode, OrgTeamNode, PositionList } from "../../../components/src/types";
import { apiCalling, truthyValue } from "../../../components/src/constant";
import { getStorageData } from ".../../../packages/framework/src/Utilities";
import moment from "moment";
import { I18n } from "../../../components/src/languageJson/i18n";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  currentLanguage : string
  anchorEl: null | HTMLElement,
  activeTab : string
  activeView : string
  orgHierachyData: OrgNode[]
  orgTeamHierachyData : OrgTeamNode[],
  departmentData : DepartmentList[],
  positionData : PositionList[]
  deletableId : string
  deleteModal : boolean
  successModal : boolean,
  deletablePositionId : string
  deletePositionModal : boolean
  successPositionModal : boolean
  messageInfo : {
    msg_ar : string,
    msg_en : string
  }
  errorDepartmentModel: boolean,
  errorPositionModal : boolean,
  errorText : string,
  expandedNodes : {[key: string]: boolean}
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrganisationChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDepartmentsApiCallId: string = ""
  deleteDepartmentsApiCallId: string = ""
  getDepartmentHirechyApiCallId : string = ""
  getPositionsApiCallId : string = ""
  deletePositionsApiCallId : string = ""
  getTeamHierachyApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
        anchorEl: null,
        activeTab: this.initialTabVlues.ORGANISATION_TAB,
        activeView: this.initialViewVlues.ORGANISATION_VIEW,
        orgHierachyData: [],
        departmentData : [],
        positionData: [],
        deletableId: "",
        successModal: false,
        deleteModal: false, 
        messageInfo : {
          msg_ar : "",
          msg_en : ""
        },
        deletablePositionId : "",
        deletePositionModal: false,
        successPositionModal: false,
        orgTeamHierachyData: [],
        errorDepartmentModel: false,
        errorPositionModal : false,
        errorText: "",
        expandedNodes: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      switch(apiRequestCallId) {
        case this.getDepartmentsApiCallId:
           return this.handleGetDepartmentApiResponse(responseJson)
        case this.deleteDepartmentsApiCallId:
           return this.handleDeleteDepartmentApiResponse(responseJson)
        case this.getDepartmentHirechyApiCallId:
           return this.handleGetDepartmentHirechyApiResponse(responseJson)
        case this.getPositionsApiCallId:
          return this.handleGetPositionsApiResponse(responseJson)
        case this.deletePositionsApiCallId:
          return this.handleDeletePositionsApiResponse(responseJson)
        case this.getTeamHierachyApiCallId:
          return this.handleTeamHireachyApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getDepartmentsHierachyData()
    this.getTeamHierachyData()
    this.getDepartmentsData()
    this.getPositionsData()
  }

  handleGetDepartmentApiResponse = (responseJson: any) => {
     if(responseJson && responseJson.data && responseJson.data.length > 0) {
        const receivedArray = truthyValue(responseJson.data)
        const modifiedArray = receivedArray.map((item : any )=> {
          return {
            departmentId : `#${truthyValue(item.attributes.id)}`,
            departmentName : truthyValue(item.attributes.name),
            departmentCreationDate : moment(truthyValue(item.attributes.created_at)).format("D MMM YYYY") ,
            departmentModificationDate : moment(truthyValue(item.attributes.updated_at)).format("D MMM YYYY"),
            parentDepartmentName : truthyValue(item.attributes.parent_department) !== null ? truthyValue(item.attributes.parent_department) : "-"
          }
        })

        this.setState({ departmentData : modifiedArray})
     } else {
        this.setState({ departmentData : []})
     }
  }

  handleGetPositionsApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.length > 0) {
      const modifiedArray = responseJson.map((item: any) => {
        return {
          positionId: `#${truthyValue(item.id)}`,
          positionName: truthyValue(item.name),
          parentpositionName : truthyValue(item.parent) !== null ? truthyValue(item.parent.name) : "-",
          departmentName: truthyValue(item.department_name),
          positionCreationDate: moment(truthyValue(item.created_at)).format("D MMM YYYY"),
          positionModificationDate: moment(truthyValue(item.updated_at)).format("D MMM YYYY"),
        }
      })
      this.setState({ positionData: modifiedArray })
    } else {
      this.setState({ positionData: [] })
    }
 }

  handleGetDepartmentHirechyApiResponse = (responseJson: any) => {
    if(responseJson && responseJson.length > 0) {
      const modifiedArray = this.convertToMockChartData(responseJson)
      this.setState({ orgHierachyData : modifiedArray})
    } else {
       this.setState({ orgHierachyData : []})
    }
 }

  handleDeleteDepartmentApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      const msg_en = truthyValue(responseJson.message.en)
      const msg_ar = truthyValue(responseJson.message.ar)
      this.setState({ messageInfo: { msg_ar, msg_en } })
      this.closeDeleteModal()
      this.getDepartmentsData()
      this.openSuccessModal()
    } else {
      let errMsg = I18n.t("errorModalMsg")
      if (responseJson && responseJson.error) {
        const msg_en = truthyValue(responseJson.error.en)
        const msg_ar = truthyValue(responseJson.error.ar)
        this.setState({ messageInfo: { msg_ar, msg_en } })
      } 
       this.setState({ errorText : errMsg})
       this.closeDeleteModal()
       this.openErrorDepartmentModal()
    }
  }

  handleDeletePositionsApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      const msg_en = truthyValue(responseJson.message.en)
      const msg_ar = truthyValue(responseJson.message.ar)
      this.setState({ messageInfo: { msg_ar, msg_en } })
      this.closeDeletePositionModal()
      this.getPositionsData()
      this.openSuccessPositionModal()
    } else {
      let errMsg = I18n.t("errorModalMsg")
      if (responseJson && responseJson.error) {
        const message_en = truthyValue(responseJson.error.en)
        const message_ar = truthyValue(responseJson.error.ar)
        this.setState({ messageInfo: { msg_ar : message_ar, msg_en: message_en } })
      } 
       this.setState({ errorText : errMsg})
       this.closeDeletePositionModal()
       this.openErrorPositionModal()
    }
  }

  handleTeamHireachyApiResponse = (responseJson: any) => {
    if(responseJson && responseJson.length > 0) {
      const transformedResponse =  this.convertToMockTeamData(responseJson)
      this.setState({ orgTeamHierachyData : transformedResponse})
    } else {
       this.setState({ orgTeamHierachyData : []})
    }
  }


   initialTabVlues = {
      ORGANISATION_TAB : "organization",
      TEAMS_TAB : "team",
      DEPARTMENT_TAB : "departments",
      POSITIONS_TAB : "positions"
   }

   initialViewVlues = {
    ORGANISATION_VIEW : "organization_view",
    TABLE_VIEW : "table_view"
 }
  
  departmentsTableHeaders = [ I18n.t("departmentName"), I18n.t("parentDepartment"),I18n.t("departmentId"), I18n.t("departmentCreationDate"), I18n.t("departmentModificationDate"), ""]
  departmentsTableRowKeys = ["departmentName","parentDepartmentName", "departmentId", "departmentCreationDate", "departmentModificationDate"]

  positionsTableHeaders = [I18n.t("positionId"), I18n.t("positionName"), I18n.t("parentPositionName"), I18n.t("departmentName"), I18n.t("departmentCreationDate"), I18n.t("departmentModificationDate"), ""]
  positionsTableRowKeys = ["positionId","positionName", "parentpositionName", "departmentName", "positionCreationDate", "positionModificationDate"]

  colorsArray = [ "#8833FF", "#2D8CFF", "#4CAF50", "#FF6F61", "#FFB74D" ]

  convertToMockChartData = (responseJson: any[]) => {
    let modifiedArray : OrgNode[] = []

    const createOrgChartData = (response: any[]): OrgNode[] => {
      let colorIndex = 0;
      const buildOrgNode = (department: any): OrgNode => {
        const { id, name, total_employees, name_ar } = department.department.data.attributes;
        
        // Recursively get children
        const children = department.children
          .map((child: any) => buildOrgNode(child))
          .filter((child:any) => child); // Filter out empty children

        // Assign a color from the colorsArray (cycling through the colors)
      const color = this.colorsArray[colorIndex];

      // Increment colorIndex and cycle it back to 0 if it exceeds the length of the color array
      colorIndex = (colorIndex + 1) % this.colorsArray.length;
        
        return {
          id: id.toString(),
          title: this.state.currentLanguage === "ar"? name_ar : name,
          peopleCount: total_employees,
          color: color,  // Default color for children
          expanded: children.length > 0, // Expanded if it has children
          children: children
        };
      };
    
      // Create the org chart data
      return response
        .map(department => buildOrgNode(department))
        .filter(department => department); // Filter out empty departments
    };

    modifiedArray = createOrgChartData(responseJson)

    return modifiedArray
  
   
  };
  
  convertToMockTeamData = (data: any[]): OrgTeamNode[] => {
    return data.map(person => ({
        id: truthyValue(String(person.id)),
        name: truthyValue(person.name),
        title: truthyValue(person.designation),
        department:  truthyValue(person.department),
        photoUrl: truthyValue(person.profile_picture),
        reportCount: truthyValue(person.reporting_count),
        children: truthyValue(person.reports.length > 0) ? this.convertToMockTeamData(truthyValue(person.reports)) : [],
        expanded: truthyValue(person.reports.length > 0) ? true : false
    }));
};

  
  getPositionsData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.getPositionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.listPositionApiEndPoint,
      method: configJSON.httpGetMethod,
    })
  }

  deletePositionData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.deletePositionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.httpDeleteMethod,
      endPoint: `${configJSON.deletePositionsApiEndPoint}/${this.state.deletablePositionId}`,
    })
  }

  getDepartmentsData = async () => {
    const tokenvalue = await getStorageData('token')
     const header = {
       token : tokenvalue
     }

     this.getDepartmentsApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.httpGetMethod,
          endPoint: configJSON.getDepartmentsApiEndPoint,
     })
  }

  getDepartmentsHierachyData = async () => {
    const tokenvalue = await getStorageData('token')
     const header = {
       token : tokenvalue
     }

     this.getDepartmentHirechyApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.httpGetMethod,
          endPoint: configJSON.getDepartmentHierachyData,
     })
  }

  getTeamHierachyData = async () => {
    const tokenvalue = await getStorageData('token')
     const header = {
       token : tokenvalue
     }

     this.getTeamHierachyApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.httpGetMethod,
          endPoint: configJSON.getTeamHierachyApiEndPoint,
     })
  }

  deleteDepartmentData = async () => {

    const tokenvalue = await getStorageData('token')
     const header = {
       token : tokenvalue
     }

     this.deleteDepartmentsApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.httpDeleteMethod,
          endPoint: `${configJSON.deleteDepartmentsApiEndPoint}/${this.state.deletableId}`,
     })
  }
  
  setFlexDirectionCSS = () => {
    return this.state.currentLanguage === "ar" ? "row-reverse" : "row";
  };

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClose = () => {
    this.setState({ anchorEl: null });
  };

  handleTabChange = (tab: string) => {
    this.setState({ activeTab: tab });
  };

  handleViewChange = (view: string, tab: string) => {
    this.setState({ activeView: view, activeTab: tab });
  };

  toggleNode = (id: string, defaultExpanded: boolean) => {
    this.setState((prevState) => {
      const currentState = prevState.expandedNodes[id] ?? defaultExpanded;
      return {
        expandedNodes: {
          ...prevState.expandedNodes,
          [id]: !currentState,
        },
      };
    });
  };

  toggleTeamNode = (targetEmployee: OrgTeamNode) => {
    const updateNodes = (nodes: OrgTeamNode[]): OrgTeamNode[] => {
      return nodes.map((node) => {
        if (node.id === targetEmployee.id) {
          return { ...node, expanded: !node.expanded };
        }
        if (node.children) {
          return {
            ...node,
            children: updateNodes(node.children),
          };
        }
        return node;
      });
    };
  
    this.setState((prevState) => ({
      orgTeamHierachyData: updateNodes(prevState.orgTeamHierachyData),
    }));
  };

  navigateToAddDepartmentPage = () => {
    this.props.navigation.navigate("AddDepartment")
  }

  navigateToAddPositionPage = () => {
    this.props.navigation.navigate("AddPosition")
  }

  navigateToEditDepartmentPage = (item : DepartmentList) => {
    const navId = item.departmentId.replace("#", "")
    this.props.navigation.navigate("EditDepartment", {id : navId.trim() })
  }

  navigateToEditPositionPage = (item : PositionList) => {
    const navId = item.positionId.replace("#", "")
    this.props.navigation.navigate("EditPosition", {id : navId.trim() })
  }

  deleteDepartmentClick = (item: DepartmentList) => {
    const departmentId = item.departmentId.replace("#", "")
    this.setState({ deletableId: departmentId.trim()})
    this.openDeleteModal()
  }

  deletePositionClick = (item: PositionList) => {
    const positionId = item.positionId.replace("#", "")
    this.setState({ deletablePositionId: positionId.trim()})
    this.openDeletePositionModal()
  }

  openDeleteModal = () => {
    this.setState({ deleteModal : true})
  }

  closeDeleteModal = () => {
    this.setState({ deleteModal : false})
  }

  openSuccessModal = () => {
    this.setState({ successModal : true})
  }

  closeSuccessModal = () => {
    this.setState({ successModal : false})
  }

  openDeletePositionModal = () => {
    this.setState({ deletePositionModal : true})
  }

  closeDeletePositionModal = () => {
    this.setState({ deletePositionModal : false})
  }

  openSuccessPositionModal = () => {
    this.setState({ successPositionModal : true})
  }

  closeSuccessPositionModal = () => {
    this.setState({ successPositionModal : false})
  }

  // error modal
  openErrorPositionModal = () => {
    this.setState({ errorPositionModal : true})
  }

  closeErrorPositionModal = () => {
    this.setState({ errorPositionModal : false})
  }

  openErrorDepartmentModal = () => {
    this.setState({ errorDepartmentModel : true})
  }

  closeErrorDepartmentModal = () => {
    this.setState({ errorDepartmentModel : false})
  }

  // Customizable Area End
}

// Customizable Area End