// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { apiCalling, truthyValue } from "../../../components/src/constant";
import { PositionFormErrorFields, PositionFormFields, DepartmetOptionTypes, PositionsOptionTypes } from "../../../components/src/types";
import { getStorageData } from ".../../../packages/framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  savedLanguage : string
  formFields : PositionFormFields,
  formErrorFields : PositionFormErrorFields,
  parent_position_id : string,
  departmentOptionsArr : DepartmetOptionTypes[],
  errorPositionModal: boolean,
  successPositionModal : boolean,
  position_msg_en : string,
  position_msg_ar : string,
  parentPositionOptions : PositionsOptionTypes[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddPositionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addPositionApiCallId : string = ""
  listDepartmentApiCallId : string = ""
  listPositionSApiCallId: string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        savedLanguage : localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
        formFields: this.initialFormFields,
        formErrorFields: this.initialFormErrorFields,
        parent_position_id : "",
        departmentOptionsArr: [],
        errorPositionModal: false,
        successPositionModal: false,
        position_msg_en: "",
        position_msg_ar: "",
        parentPositionOptions: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      switch(apiRequestCallId) {
          case this.listDepartmentApiCallId:
              return this.handleDepartmentOptionsApiResponse(responseJson)
          case this.listPositionSApiCallId:
              return this.handleParentPositionOptionsApiResponse(responseJson)
          case this.addPositionApiCallId:
              return this.handleAddPositionApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
      this.getDepartmentOptionData()
      this.getPositionsOptionData()
  }

  handleDepartmentOptionsApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.departments && responseJson.departments.length > 0) {
      const receivedArr = truthyValue(responseJson.departments)
      const updatedArray = receivedArr.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })
      this.setState({ departmentOptionsArr: updatedArray })
    } else {
      this.setState({ departmentOptionsArr: [] })
    }
  }

  handleParentPositionOptionsApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.length > 0) {
      const positionArray = responseJson.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })
      this.setState({ parentPositionOptions: positionArray })
    } else {
      this.setState({ parentPositionOptions: [] })
    }
  }

  handleAddPositionApiResponse = (responseJson: any) => {
    if(responseJson && responseJson.message) {
      const msg_en = truthyValue(responseJson.message.en)
      const msg_ar = truthyValue(responseJson.message.ar)
      this.setState({ position_msg_ar:  msg_ar, position_msg_en:  msg_en})
      this.openPositionSuccessModal()
    } else {
       this.openPositionErrorModal()
    }
}

  getDepartmentOptionData = async () => {
      const tokenvalue = await getStorageData('token')
      const header = {
          token: tokenvalue
      }

      this.listDepartmentApiCallId = apiCalling({
          header: JSON.stringify(header),
          endPoint: configJSON.dropdownOptionsDepartmentApiEndPoint,
          method: configJSON.httpGetMethod,
      })
  }

  getPositionsOptionData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
        token: tokenvalue
    }

    this.listPositionSApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.listPositionApiEndPoint,
        method: configJSON.httpGetMethod,
    })
}

  addPositionData = async () => {
      if (!this.handleCheckOnSubmit()) {
          return false
      }

      const tokenvalue = await getStorageData('token')
      const header = {
          'Content-Type': 'application/json',
          token: tokenvalue,
      }

      const httpBody = {
          "designation": {
              "name_ar": this.state.formFields.position_name_ar,
              "name": this.state.formFields.position_name_en,
              "description_ar": this.state.formFields.position_desc_ar,
              "description_en": this.state.formFields.position_desc_en,
              ...(this.state.parent_position_id !== "" && { "parent_id": this.state.parent_position_id })
          }
      }

      this.addPositionApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.httpPostMethod,
          endPoint: configJSON.addPositionApiEndPoint(this.state.formFields.department_id),
          body: JSON.stringify(httpBody)
      })
  }

  initialFormFields: PositionFormFields = {
      position_name_en : "",
      position_name_ar : "",
      position_desc_en : "",
      position_desc_ar : "",
      department_id: "",
    }
  
   initialFormErrorFields: PositionFormErrorFields = {
      position_name_en : false,
      position_name_ar : false,
      position_desc_en : false,
      position_desc_ar : false,
      department_id : false
    }
 
  setPageLayoutCSS = (firstValue: any, secondValue: any) => {
    return this.state.savedLanguage === "ar" ? firstValue : secondValue;
  };

  handleInputChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    if(value.trim() === "") {
      this.setState({ formErrorFields: {...this.state.formErrorFields, [name] : true}})
    } else {
      this.setState({ formErrorFields: {...this.state.formErrorFields, [name] : false}})
    }

    this.setState({ formFields : {...this.state.formFields, [name]: value}})
  }

  handleSelectDepartmentChange = (event : React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string

    if(value.trim() === "") {
      this.setState({ formErrorFields: {...this.state.formErrorFields, "department_id" : true}})
    } else {
      this.setState({ formErrorFields: {...this.state.formErrorFields, "department_id" : false}})
    }

    this.setState({ formFields : {...this.state.formFields, "department_id": value}})
  }

  handleSelectParentPositionChange = (event : React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string
    this.setState({ parent_position_id: val})
  }

  handleCheckOnSubmit = () => {
    let allFields = {
        position_name_en : false,
        position_name_ar : false,
        position_desc_en : false,
        position_desc_ar : false,
        department_id : false
    }
     
    if (this.state.formFields.position_name_en.trim() === "") {
      allFields.position_name_en = true;
    }
    if (this.state.formFields.position_name_ar.trim() === "") {
      allFields.position_name_ar = true;
    }
    if (this.state.formFields.position_desc_en.trim() === "" ) {
      allFields.position_desc_en = true;
    }
    if (this.state.formFields.position_desc_ar.trim() === "") {
      allFields.position_desc_ar = true;
    }
    if (this.state.formFields.department_id.trim() === "") {
        allFields.department_id = true;
      }

    this.setState({ formErrorFields: allFields})

    return Object.values(allFields).every((value) => value === false);
    
  }

  openPositionSuccessModal = () => {
    this.setState({ successPositionModal : true})
  }

  closePositionSuccessModal = () => {
    this.setState({ successPositionModal : false}, () => this.navToOrgPage())
  }

  openPositionErrorModal = () => {
    this.setState({ errorPositionModal : true})
  }

  closePositionErrorModal = () => {
    this.setState({ errorPositionModal : false})
  }

  navToOrgPage = () => {
    this.props.navigation.navigate("OrganisationHierarchy")
  }


  // Customizable Area End
}

// Customizable Area End