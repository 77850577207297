export const card = require("../assets/card.svg");
export const vat = require("../assets/vat.svg");
export const home = require("../assets/home.svg");
export const email = require("../assets/email.svg");
export const auth = require("../assets/auth.svg");
export const taxino = require("../assets/taxino.svg");
export const authId = require("../assets/authId.svg");
export const iban = require("../assets/Iban.svg");
export const branch = require("../assets/branch.svg");
export const categories = require("../assets/categories.svg");
export const location = require("../assets/location.svg");
export const ophours = require("../assets/ophours.svg");
export const topdesign = require("../assets/topdesign.png");
export const topdesignArabic = require("../assets/topdesignArabic.png");
export const attatchfile = require("../assets/attatchfile.svg");
export const helpicon = require("../assets/helpIcon.png");
export const time = require("../assets/time.svg");
export const deleteimg = require("../assets/delete.png");
export const checkedpng = require("../assets/checked.png");
export const uncheckedpng = require("../assets/unchecked.png");
export const cancleRoundRed = require("../assets/RoundCancle.svg");
export const checkedRound = require("../assets/checkedRound.svg");
export const branchCodeIcon = require("../assets/branchCode.svg");
export const catogoryRedIcon = require("../assets/catogoryRed.svg");
export const branchRedIcon = require("../assets/branchRed.svg");