import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Breadcrumbs,
  Card,
  Modal,
  TextField,
  InputAdornment,
  Popover,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { I18n } from "../../../components/src/languageJson/i18n";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { dropdownArrow, closebtn, clockIcon, cardDatepick, attendeesIcon, datePickerIcon, deleteIcon, modalSuccessIcon, modalErrorIcon } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
interface Event {
  title: string;
  start: Date;
  end: Date;
}
import AppNavigation from '../../../components/src/AppNavigation.web';
// Customizable Area End

import AppoinmentsControllerweb, {
  Props,
} from "./AppoinmentsControllerweb";
export default class Appointments extends AppoinmentsControllerweb {
  public inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.inputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  useStyles = {
    statusProgess: {
      height: "22px",
      width: "111px",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "18px",
      borderRadius: "40px",
      color: "#527FE2",
      backgroundColor: "#BFDBFE",
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    Cancelbtn: {
      marginTop: "16px",
      fontWeight: 600,
      width: '400px',
      fontFamily: 'Poppins',
      border: "none",
      color: '#ffff',
      padding: '12px',
      borderRadius: "8px",
      backgroundColor: '#527FE2',
    },
    statusPendding: {
      height: "22px",
      width: "111px",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "18px",
      borderRadius: "40px",
      color: "#D97706",
      backgroundColor: "#FEF3C7",
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusFinished: {
      height: "22px",
      width: "111px",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "18px",
      color: "#527FE2",
      borderRadius: "40px",
      backgroundColor: "#BFDBFE",
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableRow: {
      margin: "5px 0 5px 0",
      display: "flex",
      flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
      width: "100%",
    },
    tableHead: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFFFFF",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      whiteSpace: "nowrap" as "nowrap",
    },
    tableBody: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableBodyStatus: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      display: "flex",
      gap: 20,
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    subOption: {
      fontSize: "14px", lineHeight: "24px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontWeight: 400,
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    options: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 700,
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    navLinks: {
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      color: "#000000",
      textDecoration: "none",
    },
  }


  CardBox = styled(Card)({
    maxHeight: "65%",
    overflowY: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #CBD5E1",
    width: "100%",
    padding: "30px",
    maxWidth: "95%",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",

    scrollbarWidth: "thin",
    scrollbarColor: "#527FE2 #f1f1f1",

    "&::-webkit-scrollbar": {
      width: "8px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#527FE2",
      borderRadius: "10px",
      border: "3px solid transparent",
      backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
      borderRadius: "10px",
    },

    "@media (max-width: 560px)": {
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  });

  BreadcrumbsCust = styled(Breadcrumbs)({
    display: 'flex',
    flexWrap: 'nowrap',
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });
  weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  months = Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("en-IN", { month: "long" })
  );

  hours = Array.from({ length: 14 }, (_, i) => i + 10)

  handleIconClick = () => {
    if (this.inputRef.current) {
      (this.inputRef.current as any).showPicker?.();
    }
  };
  BoxDiv = () => {
    return (
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
            padding: "20px 20px",
          }}
        >
          <Typography
            noWrap
            variant="h6"
            style={{
              fontWeight: "700",
              fontFamily: "Poppins",
              textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
            }}
          >
            {I18n.t("appoinmentheadingText")}
          </Typography>
          <button
            style={{
              color: "#527FE2",
              border: "1px solid #527FE2",
              fontWeight: "700",
              padding: '12px',
              width: '213px',
              borderRadius: "8px",
              fontFamily: "Poppins",
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
            }}
          >
            {I18n.t("setAvailability")}
          </button>
        </div>
      </Box>
    )
  }
  breadscrum = () => {
    return (
      <this.BreadcrumbsCust
        separator={this.getValueBasedOnLanguage(<NavigateBeforeIcon />, <NavigateNextIcon />)}
        style={{
          color: "#000000",
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
        }}
      >
        <a
          href="#"
          style={{
            ...this.useStyles.navLinks,
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
          }}
        >
          {I18n.t("home")}
        </a>
        <a
          href="#"
          style={{
            ...this.useStyles.navLinks,
            fontWeight: "700",
            color: "#3f51b5",
            textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
          }}
        >
          {I18n.t("appointmentmanagementText")}
        </a>
      </this.BreadcrumbsCust>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { currentDate, dayOffset } = this.state;
    const weekDates = this.getWeekDates(this.state.currentDate);
    const times = this.generateTimes();
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <ToastContainer style={{ width: "fit-content"}} />
      <div style={{
        display: 'flex', flexDirection: this.state.currentLanguage === 'ar' ? "row-reverse" : "row", overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }} data-testid="language-container">
        <AppNavigation />
        <div className={this.state.currentLanguage === "ar" ? "arabic-text" : "default-text"} style={{ flex: 1, height: '100%', marginRight: this.state.currentLanguage === 'ar' ? "325px" : undefined }}>
          <ThemeProvider theme={theme}>
            <div style={{ backgroundColor: '#F1F5FC', padding: '50px 20px' }}>
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px",
                }}
              >
                {this.breadscrum()}
              </div>
              <div
                style={{
                  marginTop: '30px',
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {this.BoxDiv()}
              </div>

              <this.CardBox style={{ borderRadius: "16px" }}>
                <div className="calendar">
                  <div className="calendar-header">

                    <TextField
                      className="custom-textfield"
                      size="small"
                      type="month"
                      inputRef={this.inputRef}
                      value={`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}`}
                      onChange={this.handleMonthChange}
                      variant="outlined"
                      InputProps={{
                        classes: { notchedOutline: "remove-border" },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={datePickerIcon} alt="Calendar" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              data-testid="dropdown-arrow"
                              src={dropdownArrow}
                              alt="Open Picker"
                              onClick={this.handleIconClick}
                              style={{ cursor: "pointer" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />

                  </div>
                  <table className="calendar-table" style={{ border: "1px solid black" }}>
                    <thead style={{ backgroundColor: "#527FE2", color: "white" }}>
                      <tr style={{ width: "100%" }}>
                        <th style={webStyle.handleFunctions}>
                          <NavigateBeforeIcon onClick={this.handlePreviousDays} />
                        </th>
                        {Array.from({ length: 5 }, (_, index) => (
                          <th style={{ width: "16.8%" }} key={index}>{this.getFormattedDate(this.state.currentDate, this.state.dayOffset, index)}</th>
                        ))}
                        <th style={webStyle.handleFunctions}>
                          <NavigateNextIcon onClick={this.handleNextDays} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.hours.map((hour) => (
                        <tr key={hour}>
                          {this.state.currentLanguage === 'ar' ? (
                            <td className="time"></td>
                          ) : (
                            <td className="time">
                              {new Date(0, 0, 0, hour)
                                .toLocaleTimeString("en-IN", { hour: "2-digit", hour12: true })
                                .replace("am", "AM")
                                .replace("pm", "PM")}
                            </td>
                          )}

                          {Array.from({ length: 5 }, (_, dayIndex) => {
                            const dateTime = new Date(currentDate);
                            dateTime.setDate(currentDate.getDate() + dayOffset + dayIndex);
                            dateTime.setHours(hour, 0, 0);
                            console.log(dateTime, "aa", hour);
                            console.log("appoint", this.state.appointments)
                            return (
                              <td key={dayIndex} onClick={() => this.navigateTo(dateTime)} className="appointments" data-test-id={`navigateFunction${dayIndex}`}
                              >
                                {this.state.appointments
                                  .map((event: any, index: any) => {
                                    const startDate = new Date(event.start);
                                    const endDate = new Date(event.end);

                                    if (
                                      startDate.toDateString() === dateTime.toDateString() &&
                                      startDate.getHours() === dateTime.getHours()
                                    ) {

                                      return (
                                        <div
                                        aria-describedby={id}
                                        key={index}
                                        className="event"
                                        style={{ direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr" }}
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          this.handleClick(e, event);
                                        }}
                                      >
                                        <Box style={{ margin: this.state.currentLanguage === "ar" ? "0 0 0 3%" : "0 3% 0 0" }}>
                                          <img
                                            src={event.color}
                                            alt="iconImage"
                                            width="40px"
                                            height="40px"
                                            style={{ alignSelf: "center", borderRadius: "50%" }}
                                          />
                                        </Box>
                                        <Box>
                                          <Typography style={webStyle.EventCardTitle}>
                                            {event.title || "No Title"}
                                          </Typography>
                                      
                                          <Typography style={webStyle.EventCardTime}>
                                            {I18n.t("from")}{" "}
                                            {startDate.toLocaleTimeString("en-IN", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: true,
                                            })}{" "}
                                            {I18n.t("to")}{" "}
                                            {endDate.toLocaleTimeString("en-IN", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: true,
                                            })}
                                          </Typography>
                                        </Box>
                                      </div>
                                      
                                      );
                                    }
                                    return null;
                                  })
                                  .filter(Boolean)}

                              </td>
                            );
                          })}
                          {this.state.currentLanguage === 'en' ? (
                            <td className="time"></td>
                          ) : (
                            <td className="time">
                              {new Date(0, 0, 0, hour)
                                .toLocaleTimeString("en-IN", { hour: "2-digit", hour12: true })
                                .replace("am", "صباحًا")
                                .replace("pm", "مساءً")}
                            </td>
                          )}

                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                    PaperProps={{
                      style: {
                        borderRadius: this.state.currentLanguage === "ar" 
                          ? "24px 0px 24px 24px" 
                          : "0px 24px 24px 24px",
                      },                    }}
                  >
                    <div
                      id="popup"
                      dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                      style={{
                        width: "350px",
                        background: "white",
                        fontFamily: "poppins",
                        position: "relative",
                        padding: "20px",
                        borderRadius: "0px 10px",
                        textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ margin: 0, fontSize: "16px" }}>
                          {this.state.popoverData.title}
                        </h3>
                        <button
                          id="closePopupBtn"
                          style={{
                            background: "none",
                            border: "none",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={closebtn}
                            alt="Close"
                            style={{
                              width: "16px",
                              height: "16px",
                              marginLeft: this.state.currentLanguage === "ar" ? "6px" : "0px",
                              marginRight: this.state.currentLanguage === "ar" ? "0px" : "6px",
                            }}
                            onClick={this.handleClose}
                          />
                        </button>
                      </div>

                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                        <img
                          src={this.state.popoverData.color}
                          alt="Profile"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginLeft: this.state.currentLanguage === "ar" ? "8px" : "0px",
                            marginRight: this.state.currentLanguage === "ar" ? "0px" : "8px",
                          }}
                        />
                        <span>
                          {I18n.t("with")} <b>{this.state.popoverData.groupId}</b>
                        </span>
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid #E5E7EB",
                          marginTop: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "6px",
                            paddingBottom: "10px",
                            marginRight: this.state.currentLanguage === "ar" ? "0%" : "0px",
                            marginLeft: this.state.currentLanguage === "ar" ? "0px" : "0%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0 3% 0 3%",
                              paddingInline: "2px",
                              width: "50%"
                            }}
                          >
                            <img
                              src={cardDatepick}
                              alt="Date"
                              style={{
                                width: "24px",
                                height: "24px",
                                marginLeft: this.state.currentLanguage === "ar" ? "7px" : "0px",
                                marginRight: this.state.currentLanguage === "ar" ? "0px" : "7px",
                              }}
                            />
                            <div>
                              <span style={{ fontSize: "14px", color: "#475569" }}>
                                {I18n.t("date")}
                              </span>
                              <br />
                              <span style={{ fontSize: "14px" }}>
                                {new Date(this.state.popoverData.start).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }).replace(",", "")}
                              </span>
                            </div>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", margin: "0 4%", width: "50%" }}>
                            <img
                              src={clockIcon}
                              alt="Time"
                              style={{
                                width: "24px",
                                height: "24px",
                                marginLeft: this.state.currentLanguage === "ar" ? "7px" : "0px",
                                marginRight: this.state.currentLanguage === "ar" ? "0px" : "7px",
                              }}
                            />
                            <div>
                              <span style={{ fontSize: "14px", color: "#475569" }}>
                                {I18n.t("time")}
                              </span>
                              <br />
                              <span style={{ fontSize: "14px" }}>
                                {new Date(this.state.popoverData.start).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", margin: "0 4%" }}>
                          <img
                            src={attendeesIcon}
                            alt="Attendees"
                            style={{
                              width: "28px",
                              height: "28px",
                              marginLeft: this.state.currentLanguage === "ar" ? "7px" : "0px",
                              marginRight: this.state.currentLanguage === "ar" ? "0px" : "7px",
                            }}
                          />
                          <div>
                            <span style={{ fontSize: "14px", color: "#475569" }}>
                              {I18n.t("attendees")}
                            </span>
                            <br />
                            <span style={{ fontSize: "14px" }}>{this.state.popoverData.allow || "-"}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid #E5E7EB",
                          marginTop: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
                          <button
                            id="rescheduleButton"
                            style={{
                              background: "#2563EB",
                              color: "white",
                              border: "none",
                              padding: "16px 24px",
                              borderRadius: "8px",
                              fontWeight: "700",
                              cursor: "pointer",
                              fontSize: "16px",
                              fontFamily: "poppins",
                              width: "48%",
                            }}
                            onClick={this.navigateToEdit}
                          >
                            {I18n.t("reschedule")}
                          </button>
                          <button
                            id="cancelButton"
                            style={{
                              width: "48%",
                              background: "transparent",
                              color: "#527FE2",
                              border: "1px solid #527FE2",
                              padding: "8px 6px",
                              borderRadius: "8px",
                              cursor: "pointer",
                              fontWeight: "700",
                              fontSize: "14px",
                              fontFamily: "poppins",
                            }}
                            onClick={this.handleOpenDeleteModal}
                          >
                            {I18n.t("cancelAppointment")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popover>


                  <Modal
                    open={this.state.openDeleteModal}
                    onClose={this.handleCloseDeleteModal}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                  >

                    <Box
                      dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                      style={{
                        padding: "30px",
                        background: "#F1F5FC",
                        borderRadius: "8px",
                        width: "525px",
                        textAlign: "center",
                        top: "50%",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <img src={deleteIcon} style={{ display: "inline-block", justifyContent: "center", padding: "5px", width: "80px" }} />
                      <Typography id="success-modal-description" style={{ padding: "5px", fontWeight: 700, fontFamily: "Poppins", color: "#1E293B", fontSize: "1rem" }}>
                       {I18n.t("confirmDeleteAppointment")}
                      </Typography>
                      <Typography style={{ padding: "5px", fontFamily: "Poppins", color: "#94A3B8", fontSize: "1rem", marginBottom: "8px" }}>
                      {I18n.t("deleteWarning")}
                      </Typography>
                      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <button
                          style={{
                            padding: "10px 20px",
                            minWidth: "120px",
                            fontSize: "1rem",
                            backgroundColor: "#DC2626",
                            color: "#fff",
                            border: "none",
                            borderRadius: "8px",
                            marginRight: "10px",
                            cursor: "pointer",
                            fontFamily: "Poppins",
                            fontWeight: 700
                          }}
                          data-test-id="delete-button"
                          onClick={this.handleDeletion}
                        >
                          {I18n.t("Delete")}
                        </button>

                        <button
                          onClick={this.handleCloseDeleteModal}
                          style={{
                            padding: "10px 20px",
                            minWidth: "120px",
                            fontSize: "1rem",
                            backgroundColor: "#527FE2",
                            color: "#fff",
                            border: "none",
                            borderRadius: "8px",
                            marginRight: "10px",
                            cursor: "pointer",
                            fontFamily: "Poppins",
                            fontWeight: 700
                          }}
                          data-test-id="cancel-button"
                        >
                           {I18n.t("Cancel")}
                        </button>
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    aria-describedby="success-modal-description"
                    onClose={this.handleCloseModal}
                    open={this.state.openSuccessModal}
                    aria-labelledby="success-modal-title"
                  >
                    <Box
                      dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                      style={{
                        top: "50%",
                        padding: "16px 24px",
                        position: "absolute",
                        background: "#fff",
                        borderRadius: "8px",
                        transform: "translate(-50%,-50%)",
                        width: "400px",
                        textAlign: "center",
                        left: "50%",
                      }}
                    >
                      <img alt="Success Icon" src={modalSuccessIcon} />
                      <Typography variant="h6" id="success-modal-description" style={{ fontFamily: "Poppins", fontSize: '18px', fontWeight: 600, marginTop: '10px' }}>
                        {I18n.t("deleteApoinmentSuccessmsg")}
                      </Typography>
                      <button
                        data-test-id="success-modal-close-button"
                        onClick={this.handleCloseModal} style={{
                          ...this.useStyles.Cancelbtn,
                        }} >
                        {I18n.t("AddAppoinmentcancelBtnText")}
                      </button>
                    </Box>
                  </Modal>
                  <Modal
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                    open={this.state.openErrorModal}
                    onClose={this.handleCloseModal}
                  >
                    <Box
                      style={{
                        top: "50%",
                        textAlign: "center",
                        margin: "auto",
                        padding: "20px",
                        position: "absolute",
                        width: '400px',
                        background: "#fff",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "8px",
                      }}
                      dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                    >
                      <img src={modalErrorIcon} />
                      <Typography variant="h6" id="error-modal-description" style={{ fontWeight: 600, padding: "16px", fontFamily: "Poppins", fontSize: '14px' }}>
                        {I18n.t("deleteApoinmentErrormsg")}
                      </Typography>
                      <div
                        style={{
                          flexDirection: "column",
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={{
                            width: "400px",
                            padding: "10px",
                            fontWeight: 600,
                            backgroundColor: "#527FE2",
                            fontFamily: "Poppins",
                            marginBottom: "10px",
                            border: "none",
                            borderRadius: "8px",
                            color: "#FFFFFF",
                          }}
                          data-test-id="error-modal-try-again-button"
                          onClick={this.handleTryAgain}
                        >
                          {I18n.t("AddAppoinmentTryAgain")}
                        </button>
                        <button
                          style={{
                            width: "400px",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            backgroundColor: "#FFFFFF",
                            border: '1px solid #527FE2',
                            borderRadius: "8px",
                            padding: "10px",
                            color: "#527FE2",
                          }}
                          onClick={this.handleCloseModal}
                          data-test-id="error-modal-cancel-button"
                        >
                          {I18n.t("AddAppoinmentcancelBtnText")}
                        </button>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </this.CardBox>
            </div>
          </ThemeProvider>
        </div>
      </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const webStyle = {
  EventCardTitle: {
    color: "#0F172A",
    fontSize: 16,
    fontWeight: "700"
  },
  EventCardTime: {
    color: "#475569",
    fontSize: 10,
    fontWeight: "400",
    width: "100%"
  },
  handleFunctions: {
    width: "8%",
  },
  appointmentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
  },

};
// Customizable Area End