Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.appointmentApiContentType = "application/json";

exports.appointmentAPiEndPoint = "appointment_management/availabilities";
exports.appointmentListAPiEndPoint = "bx_block_appointment_management/availabilities/merchant_index";
exports.addAppointmentAPiMethod = "POST";
exports.deleteAllAppointmentsAPiMethod = "DELETE";
exports.getAppointmentListAPiMethod = "GET";

exports.format = "DD/MM/YY";
exports.serverFormat = "YY/MM/DD";
exports.webFormat = "dd/MM/yyyy";
exports.timeFormat = "HH:mm A";
exports.minDate = "01/01/20";
exports.maxDate = "01/01/25";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.availableDate = "Available Date";
exports.availableFrom = "Available From";
exports.availableTo = "Available To";
exports.viewAllAppointments = "View all Appointments";
exports.addAppointment = "Add Appointment";
exports.confirm = "Confirm";
exports.cancel = "Cancel";
exports.datePickerPlaceholder = "Select Date";
exports.datePickerPlaceholderFormat = "DD/MM/YY";
exports.timePickerPlaceholder = "Select Time";
exports.getList = "Get List";
exports.slot = "Slot";
exports.getCustomerAPIEndPoint = "account_block/get_all_customers";
exports.getEmployeesAPIEndPoint = "bx_block_fullteamvisibility/employees/employees/get_active_employees_web"
exports.getMethod = "GET";
exports.createAppointmentAPIEndPoint = "bx_block_appointment_management/availabilities/merchant_create";
exports.EditAppointmentAPIEndPoint = "bx_block_appointment_management/availabilities"
exports.deleteAppointmentEndpoint="bx_block_appointment_management/availabilities"
exports.deleteAppointmentAPiMethod = "DELETE"
exports.editAppointmentAPiMethod = "PUT"
// Customizable Area End
