// Customizable Area Start
import React from "react";
import OrganisationChartController, {
    Props,
  } from "./OrganisationChartController";
import AppNavigation from "../../../components/src/AppNavigation.web";
import { Box, styled, Typography, Button, MenuItem, Menu, InputAdornment, IconButton } from "@material-ui/core";
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { FilterList, SearchOutlined
} from "@material-ui/icons";
import { chartGrayIcon, tableGrayIcon, tableWhiteIcon, chartWhiteIcon, upIcon, downIcon } from "./assets";
import { OrgNode, OrgTeamNode } from "../../../components/src/types";
import PersonIcon from '@material-ui/icons/Person';
import DataTable from "../../../components/src/DataTable.web";
import DeleteModal from "../../../components/src/DeleteModal.web";
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { CustomScrollbarStyle, getValueBasedOnLanguage as getCssValues, SearchField } from "../../../components/src/constant";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddIcon from '@material-ui/icons/Add';
import { Tree, TreeNode } from 'react-organizational-chart';

class OrganisationChart extends OrganisationChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  adornmentStyle = {
    order: this.getValueBasedOnLanguage(1, 0),
    marginRight: this.getValueBasedOnLanguage(8, 0),
    marginLeft: this.getValueBasedOnLanguage(0, 8),
  };

  renderCommonCardUIChartComponent = (node : OrgNode) => {
    const hasChildren = node.children && node.children.length > 0;
    const isExpanded = this.state.expandedNodes[node.id] ?? node.expanded;

    const styles = {
      nodeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      } as React.CSSProperties,
      nodeContent: {
        backgroundColor: '#F8FAFC',
        borderRadius: '0.5rem',
        padding: '16px 12px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        minWidth: '220px',
      } as React.CSSProperties,
    };

    return (
      <div style={styles.nodeWrapper}>
      <div
        style={{
          ...styles.nodeContent,
          borderRight: I18n.locale === 'ar' ? `4px solid ${node.color}` : 'none',
          borderLeft: I18n.locale !== 'ar' ? `4px solid ${node.color}` : 'none',
          display: 'flex',
          flexDirection: I18n.locale === 'ar' ? 'row-reverse' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h4
            style={{
              fontWeight: 700,
              fontFamily: getCssValues("Noto Sans Arabic", "Poppins"),
              fontSize: "20px",
              textAlign: I18n.locale === "ar" ? "right" : "left",
              margin: 0,
              paddingBottom: "8px"
            }}
          >
            {node.title}
          </h4>
          <p
            style={{
              color: "#64748B",
              fontFamily: getCssValues("Noto Sans Arabic", "Poppins"),
              fontSize: "14px",
              textAlign: I18n.locale === "ar" ? "right" : "left",
              margin: 0
            }}
          >
            {I18n.t("PeopleCount", { count: node.peopleCount })}
          </p>
        </div>
        {hasChildren && (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent unwanted event propagation
              this.toggleNode(node.id, isExpanded);
            }}
            data-test-id="toggleChartNode"
            style={{
              display: "flex",
              background: "#F8FAFC",
              cursor: "pointer",
              border: "none",
              padding: "0",
              margin: "0",
              outline: "none",
            }}
          >
            {isExpanded ? <img src={upIcon} style={{ height: 24, width: 24 }} /> : <img src={downIcon} style={{ height: 24, width: 24 }} />}
          </button>
        )}
      </div>
      </div>
    )
  }

  renderTree = (node: any) => {
    const isExpanded = this.state.expandedNodes[node.id] ?? node.expanded;

    return (
      <TreeNode
        key={node.id}
        label={
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                left: "49.95%",
                top: "-5px",
                transform: "translateX(-50%)",
                width: "0px",
                height: "0px",
                borderLeft: "6px solid transparent",
                borderRight: "6px solid transparent",
                borderTop: "6px solid rgb(100, 181, 246)",
              }}
            ></div>
            
            {this.renderCommonCardUIChartComponent(node)}

          </div>
        }
      >
        {isExpanded && node.children.length > 0
          ? node.children.map(this.renderTree)
          : null}
      </TreeNode>
    );
  };

  renderOrganisationChartSection = ( node : OrgNode) => {
    const isRootExpanded = this.state.expandedNodes[node.id] ?? node.expanded;
      return (
      <Box>
          <Tree
            lineWidth="2px"
            lineHeight="50px"
            lineColor={"rgb(100, 181, 246)"}
            lineBorderRadius="0px"
            data-test-id="orgChartTree"
            label={this.renderCommonCardUIChartComponent(node)}
          >
            {isRootExpanded && node.children ? node.children.map(this.renderTree) : null}
          </Tree>
      </Box>
    )
  }

  renderCommonProfileCardComponent = (employee : OrgTeamNode) => {
    const hasReports = employee.children && employee.children.length > 0;

    const styles = {
      nodeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      } as React.CSSProperties,
      profileCard: {
        backgroundColor: '#F8FAFC',
        borderRadius: '8px',
        padding: '8px 16px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
      } as React.CSSProperties,
      imageContainer: {
        display: 'flex',
        justifyContent: 'center',
      } as React.CSSProperties,
      profileImage: {
        width: '96px',
        height: '96px',
        borderRadius: '50%',
        objectFit: 'cover',
        border: '4px solid white',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      } as React.CSSProperties,
      name: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        color: '#111827',
        margin: '0',
      } as React.CSSProperties,
      title: {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#64748B',
        margin: '0',
      } as React.CSSProperties,
      details: {
        fontWeight: 400,
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#94A3B8',
        margin: '0',

      } as React.CSSProperties,
      verticalLine: {
        position: 'absolute',
        left: '50%',
        width: '2px',
        height: '20px',
        backgroundColor: '#64B5F6',
        transform: 'translateX(-50%)',
      } as React.CSSProperties,
      arrow: {
        position: 'absolute',
        bottom: '-6px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '6px solid #64B5F6',
      } as React.CSSProperties,
      reportButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '96px',
        height: '42px',
        backgroundColor: 'white',
        border: '1px solid #64B5F6',
        borderRadius: '42px',
        cursor: 'pointer',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        padding: '0 8px',
        marginTop: "58px"
      } as React.CSSProperties,
      expandedContent: {
        marginTop: '24px',
        width: '100%',
        position: 'relative',
      } as React.CSSProperties,
      childrenContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '32px',
        marginTop: '68px',
        position: 'relative',
      } as React.CSSProperties,
      nodeContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      } as React.CSSProperties,
    };
    
    return (
      <div style={styles.nodeWrapper}>
        <div style={styles.profileCard}>
          <div style={styles.imageContainer}>
            <img
              src={employee.photoUrl}
              alt={employee.name}
              style={styles.profileImage}
            />
          </div>
          <div>
            <p style={styles.name}>{employee.name}</p>
            <p style={styles.title}>{employee.title}</p>
            <div style={styles.details}>
              {employee.department}
            </div>
          </div>

        </div>

        {hasReports && (
          <div style={{ position: 'relative' }}>
            <div style={{ ...styles.verticalLine, top: '0', height: '50px' }}>
              <div style={styles.arrow} />
            </div>
            <div style={styles.reportButton}>
              <Typography
                variant="body2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: (I18n.locale === 'ar') ? 'row-reverse' : 'row',
                }}
              >
                <PersonIcon style={{ marginRight: '4px', marginLeft: (I18n.locale === 'ar') ? '4px' : '0' }} />
                {employee.reportCount}
              </Typography>
              <IconButton
                size="small"
                onClick={() => this.toggleTeamNode(employee)}
                data-test-id="toggleTeamNode"
                style={{
                  padding: '4px',
                  order: (I18n.locale === 'ar') ? -1 : 1,
                }}
                className={employee.expanded ? 'expanded' : ''}
              >
                {employee.expanded ? <img src={upIcon} style={{ height: 24, width: 24 }} /> : <img src={downIcon} style={{ height: 24, width: 24 }} />}
              </IconButton>
            </div>

          </div>
        )}
      </div>
    )
  }

  renderTeamTree = (node: OrgTeamNode) => {
    const isExpanded = this.state.expandedNodes[node.id] ?? node.expanded;

    return (
      <TreeNode
        key={node.id}
        label={
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                left: "49.95%",
                top: "-5px",
                transform: "translateX(-50%)",
                width: "0px",
                height: "0px",
                borderLeft: "6px solid transparent",
                borderRight: "6px solid transparent",
                borderTop: "6px solid rgb(100, 181, 246)",
              }}
            ></div>
            
            {this.renderCommonProfileCardComponent(node)}

          </div>
        }
      >
        {isExpanded && node.children && node.children.length > 0
          ? node.children.map(this.renderTeamTree)
          : null}
      </TreeNode>
    );
  };


  renderOrganisationTeamsSection = (employee: OrgTeamNode, isRoot: boolean = false) => {
    const isRootExpanded = this.state.expandedNodes[employee.id] ?? employee.expanded;

    return (
      <Box>
        <Tree
          lineWidth="2px"
          lineHeight="50px"
          lineColor={"rgb(100, 181, 246)"}
          lineBorderRadius="0px"
          label={this.renderCommonProfileCardComponent(employee)}
        >
          {isRootExpanded && employee.children ? employee.children.map(this.renderTeamTree) : null}
        </Tree>


      </Box>
    )
  }

  renderTabs = () => {
    return (
      this.state.activeView === this.initialViewVlues.TABLE_VIEW ?
        <div
          style={{
            display: "flex",
            flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
            gap: '15px',
            alignItems: "center",
            padding: "16px",
          }}>
          <button
            className={`tabButton ${this.state.activeTab === this.initialTabVlues.DEPARTMENT_TAB && "tabButtonActive"}`}
            data-test-id="departmentTab"
            onClick={() => this.handleTabChange(this.initialTabVlues.DEPARTMENT_TAB)}
          >
            {I18n.t("Departments")}  
          </button>
          <button
            className={`tabButton ${this.state.activeTab === this.initialTabVlues.POSITIONS_TAB && "tabButtonActive"}`}
            data-test-id="positionTab"
            onClick={() => this.handleTabChange(this.initialTabVlues.POSITIONS_TAB)}
          >
            {I18n.t("Positions")}
          </button>
        </div>
        : <div
          style={{
            display: "flex",
            flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
            gap: '15px',
            alignItems: "center",
            padding: "16px",
          }}>
          <button
            className={`tabButton ${this.state.activeTab === this.initialTabVlues.ORGANISATION_TAB && "tabButtonActive"}`}
            data-test-id="orgTab"
            onClick={() => this.handleTabChange(this.initialTabVlues.ORGANISATION_TAB)}
          >
            {I18n.t("Organization")}
          </button>
          <button
            className={`tabButton ${this.state.activeTab === this.initialTabVlues.TEAMS_TAB && "tabButtonActive"}`}
            data-test-id="teamTab"
            onClick={() => this.handleTabChange(this.initialTabVlues.TEAMS_TAB)}
          >
            {I18n.t("Team")}
          </button>
        </div>
    )
  }

  renderOrganisationDepartmentSection = () => {
    return(
      <div>
         <DataTable 
            tableHeaders={this.departmentsTableHeaders}
            tableData={this.state.departmentData}
            tableKeys={this.departmentsTableRowKeys}
            erorMsg={I18n.t("noDepartmentFoundMsg")}
            data-test-id="departmentDataTable"
            onEdit={(item) => this.navigateToEditDepartmentPage(item)}
            onDelete={(item) => this.deleteDepartmentClick(item)}
         />

        {this.state.departmentData.length === 0 &&
          <div style={{ display: "flex", justifyContent: "center", cursor: "pointer"}}>
            <CommonButton
               onClick={this.navigateToAddDepartmentPage}
               data-test-id="addNewDepartmentBtn"
            >
              {I18n.t("addNewDepartmentBtn")}
            </CommonButton>
          </div>
        }

         <DeleteModal
            open={this.state.deleteModal}
            handleClose={this.closeDeleteModal}
            handleDelete={this.deleteDepartmentData}
            msgTitle={I18n.t("deleteMsgHeader")}
            data-test-id="deleteModal"
         />

        <SuccessModal
          open={this.state.successModal}
          handleClose={this.closeSuccessModal}
          msg_en={this.state.messageInfo.msg_en}
          msg_ar={this.state.messageInfo.msg_ar}
          data-test-id="successModal"
        />

        <ErrorModal
          open={this.state.errorDepartmentModel}
          handleClose={this.closeErrorDepartmentModal}
          msg_en={this.state.messageInfo.msg_en}
          msg_ar={this.state.messageInfo.msg_ar}
          data-test-id="errorModal"
        />
      </div>
    )
  }

  renderOrganisationPositionsSection = () => {
    return(
      <div>
        <DataTable 
            tableHeaders={this.positionsTableHeaders}
            tableData={this.state.positionData}
            tableKeys={this.positionsTableRowKeys}
            erorMsg={I18n.t("noPositionFoundMsg")}
            data-test-id="positionsDataTable"
            onDelete={(item) => this.deletePositionClick(item)}
            onEdit={item => this.navigateToEditPositionPage(item)}
         />

        {this.state.positionData.length === 0 &&
          <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <CommonButton
              onClick={this.navigateToAddPositionPage}
              data-test-id="addNewPositionBtn"
            >
              {I18n.t("addNewPositionBtn")}
            </CommonButton>
          </div>
        }

        <DeleteModal
          open={this.state.deletePositionModal}
          handleClose={this.closeDeletePositionModal}
          handleDelete={this.deletePositionData}
          msgTitle={I18n.t("deletePositionMsgHeader")}
          data-test-id="deletePositionModal"
        />

        <SuccessModal
          open={this.state.successPositionModal}
          handleClose={this.closeSuccessPositionModal}
          msg_en={this.state.messageInfo.msg_en}
          msg_ar={this.state.messageInfo.msg_ar}
          data-test-id="successPositionModal"
        />

        <ErrorModal
          open={this.state.errorPositionModal}
          handleClose={this.closeErrorPositionModal}
          msg_en={this.state.messageInfo.msg_en}
          msg_ar={this.state.messageInfo.msg_ar}
          data-test-id="errorPositionModal"
        />
      </div>
    )
  }
  
  render() {
    // Customizable Area Start

    return (
      <div style={{display:'flex', flexDirection: this.getValueBasedOnLanguage('row-reverse', "row")}}>
        <AppNavigation />
        <ChartWrapper className={this.getValueBasedOnLanguage("arabic-text", "default-text")}
          style={{
            marginRight:this.getValueBasedOnLanguage("325px", undefined)
          }}
        >
          <div style={{ display: 'flex', flexDirection: this.setFlexDirectionCSS() }}>
            <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("organizationManagement")]} />
          </div>

          {/* header section */}
          <Box className="titleBox">
            <Typography style={{ 
              fontFamily : getCssValues("Noto Sans Arabic", "Poppins"),
              fontWeight: 700,
              fontSize: "32px"
            }}>
              {I18n.t("OrganizationHierarchy")}
            </Typography>

            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                variant="outlined"
                className="branchManagementBtn"
              >
                {I18n.t("BranchManagement")}
              </Button>

              <Button
                variant="outlined"
                className="organisationManagementBtn"
                data-test-id="manageOrganisationBtn"
                onClick={this.handleMenuItemClick}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#527FE2" , height: 24, width: 24}} />}
              >
                {I18n.t("ManageOrganization")}
              </Button>

              <Menu
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                data-test-id="manageOrganisationMenu"
                onClose={this.handleMenuItemClose}
                style={{
                  marginTop: "55px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CustomMenuItem
                  onClick={this.navigateToAddDepartmentPage}
                  data-test-id="navToDepartmentPage"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "1px solid #d1d5db",
                  }}
                >
                  <AddIcon style={{ height: 24, width: 24, color: "#0F172A" }} />
                  <p style={{
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    color: "#0F172A",
                    margin: 0
                  }}>
                     {I18n.t("Add New Department")} 
                  </p>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={this.navigateToAddPositionPage}
                  data-test-id="navToPositionPage"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <AddIcon style={{ height: 24, width: 24, color: "#0F172A" }} />
                  <p style={{
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    color: "#0F172A",
                    margin: 0
                  }}>
                     {I18n.t("addPosition")} 
                  </p>
                </CustomMenuItem>
              </Menu>
            </div>
          </Box>

          {/* search box */}
          <Box className="searchBoxDIv">

            <div style={{ display: "flex", flexDirection: this.setFlexDirectionCSS(), justifyContent: "center", alignSelf: "center", width: "80%", padding: "1.5rem", borderRadius: "0.5rem", marginTop: "1rem", }}>
              <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "60%", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row") }}>

                <SearchField
                  type="search"
                  id="search"
                  data-test-id="searchinput"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={this.adornmentStyle}
                      >
                        <SearchOutlined style={{ color: "#94A3B8" }} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={I18n.t("search")}
                  variant="outlined"
                />
              </div>
              <Button data-test-id="searchField"
                variant="outlined"
                style={{
                  fontSize: '18px', fontWeight: 700, textTransform: "none", backgroundColor: "#527FE2", borderRadius: this.getValueBasedOnLanguage("25px 0 0 25px", "0 25px 25px 0"),
                  fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
                }}>
                <FilterList style={{ color: "ffffff" }} />
              </Button>
            </div>
          </Box>

          {/* Tab & View Section */} 
          <Box className="tabBox" style={{ flexDirection : this.getValueBasedOnLanguage("row-reverse", "row")}}>

             {this.renderTabs()}

            <div
              style={{ display: "flex", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row") }}>
              <button
                className={`toogleButton ${this.state.activeView === this.initialViewVlues.TABLE_VIEW && "toogleButtonActive"}`}
                onClick={() => this.handleViewChange(this.initialViewVlues.TABLE_VIEW, this.initialTabVlues.DEPARTMENT_TAB)}
                data-test-id="tableView"
              >
                <div style={{ display: "flex", gap: "8px"}}>
                  <img src={this.state.activeView === this.initialViewVlues.TABLE_VIEW ? tableWhiteIcon: tableGrayIcon} />
                  <p> {I18n.t("TableView")}</p>
                </div>
              </button>
              <button
                className={`toogleButton toogleButtonRight ${this.state.activeView === this.initialViewVlues.ORGANISATION_VIEW && "toogleTeamButton toogleButtonActive"}`}
                onClick={() => this.handleViewChange(this.initialViewVlues.ORGANISATION_VIEW, this.initialTabVlues.ORGANISATION_TAB)}
                data-test-id="orgView"
              >
                 <div style={{ display: "flex", gap: "8px"}}>
                  <img src={this.state.activeView === this.initialViewVlues.ORGANISATION_VIEW ? chartWhiteIcon: chartGrayIcon} />
                  <p> {I18n.t("OrgChart")}</p>
                </div>
              </button>
            </div>

          </Box>

          {/* Organisation chart section */}
          {this.state.activeTab === this.initialTabVlues.ORGANISATION_TAB && this.state.activeView === this.initialViewVlues.ORGANISATION_VIEW
             && this.state.orgHierachyData.length > 0 &&
              <div className="chartManinSection">
               {this.state.orgHierachyData.map((node) => this.renderOrganisationChartSection(node))}
             </div> 
          }

          {/* Organisation teams section */}
          {this.state.activeTab === this.initialTabVlues.TEAMS_TAB && this.state.activeView === this.initialViewVlues.ORGANISATION_VIEW
            &&  this.state.orgTeamHierachyData.length > 0 &&
             <div className="teamsMainSection">
              {this.state.orgTeamHierachyData.map(item => this.renderOrganisationTeamsSection(item, true))}
            </div> 
          }

          {/* Organisation Department section */}
          {this.state.activeTab === this.initialTabVlues.DEPARTMENT_TAB && this.state.activeView === this.initialViewVlues.TABLE_VIEW
            && this.renderOrganisationDepartmentSection()
          }

          {/* Organisation Positions section */}
          {this.state.activeTab === this.initialTabVlues.POSITIONS_TAB && this.state.activeView === this.initialViewVlues.TABLE_VIEW
            && this.renderOrganisationPositionsSection()
          }

        </ChartWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default OrganisationChart;

const ChartWrapper = styled(Box)({
    flex:1,
    backgroundColor:"#F1F5FC", 
    padding:'50px 20px', 
    minHeight:'calc(100vh - 100px)',
    maxWidth: getCssValues('calc(100vw - 370px)', 'calc(100vw - 380px)'),
    "& .chartManinSection": {
      display: "flex", 
      maxWidth: "100%", 
      overflow: "auto", 
      background: "white", 
      margin: "30px 0", 
      padding: "30px 0",  
      justifyContent: "space-evenly", 
      borderRadius: "16px", 
      gap: "30px", 
      maxHeight: 750,
      ...CustomScrollbarStyle
    },
    "& .teamsMainSection": {
      display: "flex",
      maxWidth: "100%", 
      overflow: "auto", 
      background: "#fff", 
      borderRadius: "16px", 
      padding: 30, 
      justifyContent: "space-evenly",
      maxHeight: 750, 
      gap : "30px",
      margin: "30px 0",
      ...CustomScrollbarStyle 
    },
    "& .titleBox": {
      display: "flex",
      justifyContent: "space-between", 
      alignItems: "center", 
      marginBottom: "20px",
      direction: getCssValues("rtl", "ltr")
    },
    "& .btnBoxs": {
      display: "flex",
      gap: "10px",
    },
    "& .branchManagementBtn":{
      fontSize: "16px",
      fontFamily: "Poppins",
      color: "#527FE2",
      border: "1px solid #527FE2",
      fontWeight: 700,
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
    },
    "& .organisationManagementBtn" : {
      color: "#527FE2",
      border: "1px solid #527FE2",
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "16px",
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
    },
    "& .searchBoxDIv": {
        width: "100%", 
        display: "flex", 
        justifyContent: "center", 
        marginBottom: "20px"
    },
    "& .tabButton": {
      border: "none", 
      borderRadius: "20px",
      backgroundColor: "transparent", 
      color: "black", 
      padding: "8px 16px",
      fontSize: "16px",
      fontWeight: "500",
      cursor: "pointer",
      transition: "all 0.3s ease",
    },
    "& .tabButtonActive": {
      border: "1px solid #d1e2f4", 
      borderRadius: "20px",
      padding: "8px 16px",
      backgroundColor: "#e6f2ff",
      color: "#004aad",
      borderColor: "#004aad",
    },
    "& .toogleButton": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 48,
      width: "154px",
      fontSize: "16px",
      fontWeight: "bold",
      border: "1px solid #d1d5db",
      borderRadius: getCssValues("0 8px 8px 0", "8px 0 0 8px"),
      backgroundColor: "#f9fafb",
      color: "#6b7280",
      cursor: "pointer",
      outline: "none",
      transition: "all 0.3s ease",
    },
    "& .toogleTeamButton": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 48,
      width: "154px",
      fontSize: "16px",
      fontWeight: "bold",
      border: "1px solid #d1d5db",
      borderRadius: getCssValues("0 8px 8px 0", "8px 0 0 8px"),
      backgroundColor: "#2563eb",
      color: "#ffffff",
      cursor: "pointer",
      outline: "none",
      transition: "all 0.3s ease",
    },
    "& .toogleButtonActive": {
      backgroundColor: "#2563eb",
      color: "#ffffff",
      border: "1px solid #2563eb",
    },
    "& .toogleButtonRight": {
      borderRadius : getCssValues("8px 0 0 8px", "0 8px 8px 0")
    },
    "& .tabBox": {
      display : "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
})

const CommonButton = styled(Button)({
  color: "white",
  textTransform: "none",
  backgroundColor: "#527FE2",
  borderRadius: "8px",
  marginTop: "20px",
  fontFamily: getCssValues("Noto Sans Arabic", "Poppins"),
  fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  height: "48px",
  width: "306px",
  "&:hover": {
    color: "white", backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",padding: "10px 16px 10px 16px",
  },
  
});

const CustomMenuItem = styled(MenuItem)({
    padding: '10px',
    minWidth: getCssValues("100%", "15.5rem"),
    direction: getCssValues("rtl", "ltr"),
    '&:hover': {
      backgroundColor: '#DBEAFE',
    },
})

const StyledNode = styled('div')({
  padding: "5px",
  borderRadius: "8px",
display: "inline-block",
border: "1px solid red"
})

// Customizable Area End   
