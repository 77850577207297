Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrganisationHierarchy";
exports.labelBodyText = "OrganisationHierarchy Body";
exports.loginBodyType = "email_account";

exports.postApiMethodType = "POST";
exports.loginURLEndPoint = "bx_block_login/logins";
exports.getAllTasks = "bx_block_tasks/tasks";
exports.getEmployeeTask = "bx_block_tasks/employee_tasks";
exports.getEmployees = "bx_block_employee/employees";
exports.getEmployeeHierarchy = "bx_block_employee/employees/get_hierarchy";
exports.email = "buraktest@gmail.com";
exports.securedText = "123456";

exports.btnExampleTitle = "CLICK ME";
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
exports.httpDeleteMethod = "DELETE"
exports.httpPatchMethod = "PATCH"
exports.getDepartmentsApiEndPoint = "bx_block_fullteamvisibility/departments/department_listing"
exports.addDepartmentsApiEndPoint = "bx_block_fullteamvisibility/departments"
exports.deleteDepartmentsApiEndPoint = "bx_block_fullteamvisibility/departments"
exports.dropdownOptionsDepartmentApiEndPoint = "bx_block_fullteamvisibility/departments/department_listing_dropdown"
exports.editDepartmentApiEndPoint= "bx_block_fullteamvisibility/departments"
exports.getDepartmentHierachyData= "bx_block_fullteamvisibility/departments/department_listing_with_children"
exports.addPositionApiEndPoint = (id) => `bx_block_fullteamvisibility/departments/${id}/designations`
exports.listPositionApiEndPoint = "bx_block_fullteamvisibility/designations/position_listing"
exports.deletePositionsApiEndPoint = "bx_block_fullteamvisibility/designations"
exports.getTeamHierachyApiEndPoint = "bx_block_fullteamvisibility/employees/people_hierarchy"
exports.updatePositionsApiEndPoint = "bx_block_fullteamvisibility/designations"
// Customizable Area End
