// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { apiCalling, truthyValue } from "../../../components/src/constant";
import { PositionFormErrorFields, PositionFormFields, DepartmetOptionTypes, PositionsOptionTypes } from "../../../components/src/types";
import { getStorageData } from ".../../../packages/framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  selectedAppLanguage : string
  editFormFields : PositionFormFields
  editErrorFormFields : PositionFormErrorFields,
  positionId : string,
  selected_parent_position : string
  departmentOptions : DepartmetOptionTypes[],
  positionsOptions : PositionsOptionTypes[],
  editPositonSuccessModal : boolean
  editPositionErrorModal :  boolean,
  edit_position_msg_en : string,
  edit_position_msg_ar : string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditPositionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listDepartmentApiCallId : string = ""
  listPositionsApiCallId: string =""
  updatePositionApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        selectedAppLanguage : localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
        editFormFields :  this.initialEditFormFields,
        editErrorFormFields: this.initialEditFormErrorFields,
        positionId: "",
        selected_parent_position : "",
        departmentOptions: [],
        positionsOptions: [],
        editPositonSuccessModal : false,
        editPositionErrorModal : false,
        edit_position_msg_ar : "",
        edit_position_msg_en : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
         case this.listDepartmentApiCallId:
           return this.handleDepartmentDropdownApiResponse(responseJson)
          case this.listPositionsApiCallId:
            return this.handleListPositionsApiResponse(responseJson)
          case this.updatePositionApiCallId:
            return this.handleUpdatePositionApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
      const positionId = this.props.navigation.getParam("id")
      this.setState({ positionId })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.positionId !== this.state.positionId) {
      this.getDepartmentDropdownOptionData()
      this.getPositionsData()
    }
  }

  handleDepartmentDropdownApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.departments && responseJson.departments.length > 0) {
      const receivedArr = truthyValue(responseJson.departments)
      const updatedArray = receivedArr.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })
      this.setState({ departmentOptions: updatedArray })
    } else {
      this.setState({ departmentOptions: [] })
    }
  }

  handleListPositionsApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.length > 0) {
      const positionDropdownArray = responseJson.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })

      const findItem = responseJson.find((item:any) => item.id.toString() === this.state.positionId)

      if(findItem) {
        const findDepartmentId = this.state.departmentOptions.filter(item => item.name === truthyValue(findItem.department_name)).map(item => item.id)
         const positionObject = {
          position_name_en : truthyValue(findItem.name),
          position_name_ar : truthyValue(findItem.name_ar),
          position_desc_en : truthyValue(findItem.description_en),
          position_desc_ar : truthyValue(findItem.description_ar),
          department_id: findDepartmentId.toString(),
         }

         const parentPositionId = truthyValue(findItem.parent_id) !== null ? truthyValue(findItem.parent_id) : ""

         this.setState({ editFormFields : positionObject, selected_parent_position: parentPositionId.toString()})
      }
      this.setState({ positionsOptions: positionDropdownArray })
    } else {
      this.setState({ positionsOptions: [] })
    }
  }

  handleUpdatePositionApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      const msg_en = truthyValue(responseJson.message.en)
      const msg_ar = truthyValue(responseJson.message.ar)
      this.setState({ edit_position_msg_ar: msg_ar, edit_position_msg_en: msg_en })
      this.openEditPositionSuccessModal()
    } else {
      this.openEditPositionErrorModal()
    }
  }

  getDepartmentDropdownOptionData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.listDepartmentApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dropdownOptionsDepartmentApiEndPoint,
    })
  }

  getPositionsData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.listPositionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.listPositionApiEndPoint,
      method: configJSON.httpGetMethod,
    })
  }
  

  initialEditFormFields: PositionFormFields = {
      position_name_en : "",
      position_name_ar : "",
      position_desc_en : "",
      position_desc_ar : "",
      department_id: "",
    }
  
   initialEditFormErrorFields: PositionFormErrorFields = {
      position_name_en : false,
      position_name_ar : false,
      position_desc_en : false,
      position_desc_ar : false,
      department_id : false
    }
 
  handleEditInputChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    if(value.trim() === "") {
      this.setState({ editErrorFormFields: {...this.state.editErrorFormFields, [name] : true}})
    } else {
      this.setState({ editErrorFormFields: {...this.state.editErrorFormFields, [name] : false}})
    }

    this.setState({ editFormFields : {...this.state.editFormFields, [name]: value}})
  }

  handleEditSelectDepartmentChange = (event : React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string

    if(value.trim() === "") {
      this.setState({ editErrorFormFields: {...this.state.editErrorFormFields,"department_id" : true}})
    } else {
      this.setState({ editErrorFormFields: {...this.state.editErrorFormFields,"department_id" : false}})
    }

    this.setState({ editFormFields : {...this.state.editFormFields,"department_id": value}})
  }

  handleDropdownParentPositionChange = (event : React.ChangeEvent<{ value : unknown}>) => {
    const val = event.target.value as string
    this.setState({ selected_parent_position: val})
  }

  handleAllValidationOnSubmit = () => {
    let allFields = {
        position_name_en : false,
        position_name_ar : false,
        position_desc_en : false,
        position_desc_ar : false,
        department_id : false
    }
     
    if (this.state.editFormFields.position_name_en.trim() === "") {
      allFields.position_name_en = true;
    }
    if (this.state.editFormFields.position_name_ar.trim() === "") {
      allFields.position_name_ar = true;
    }
    if (this.state.editFormFields.position_desc_en.trim() === "" ) {
      allFields.position_desc_en = true;
    }
    if (this.state.editFormFields.position_desc_ar.trim() === "") {
      allFields.position_desc_ar = true;
    }
    if (this.state.editFormFields.department_id.trim() === "") {
        allFields.department_id = true;
      }

    this.setState({ editErrorFormFields: allFields})
    return Object.values(allFields).every((value) => value === false);
  }

  updatePositionsData = async () => {
     if(!this.handleAllValidationOnSubmit()) {
         return false
     }

    const token = await getStorageData('token')
    const header = {
      'Content-Type': 'application/json',
      token: token,
    }

    const httpBody = {
      "designation": {
        "name": this.state.editFormFields.position_name_en,
        "name_ar": this.state.editFormFields.position_name_ar,
        "description_ar": this.state.editFormFields.position_desc_ar,
        "description_en": this.state.editFormFields.position_desc_en,
        ...(this.state.selected_parent_position !== "" && { "parent_id": this.state.selected_parent_position })
      }
    }

    this.updatePositionApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.httpPatchMethod,
      endPoint: `${configJSON.updatePositionsApiEndPoint}/${this.state.positionId}`,
      body: JSON.stringify(httpBody)
    })
  }

  openEditPositionSuccessModal = () => {
    this.setState({ editPositonSuccessModal : true})
  }

  closeEditPositionSuccessModal = () => {
    this.setState({ editPositonSuccessModal : false}, () => this.navToOrgHireachyPage())
  }

  openEditPositionErrorModal = () => {
    this.setState({ editPositionErrorModal : true})
  }

  closeEditPositionErrorModal = () => {
    this.setState({ editPositionErrorModal : false})
  }

  navToOrgHireachyPage = () => {
    this.props.navigation.navigate("OrganisationHierarchy")
  }

  // Customizable Area End
}

// Customizable Area End