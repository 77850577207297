import React from "react";
import {
  Box,
  Typography,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { unchecked, checked } from "./imgsrc";
import {I18n} from "../../components/src/languageJson/i18n"

interface BranchOperatingHoursModalProps {
  open: boolean;
  onClose: () => void;
  modalBranchIndex: number;
  branchData: any;
  handleCheckboxChange: (index: number, day: string, checked: boolean) => void;
  handleHourChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleMinuteChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handlePeriodChange: (event: React.ChangeEvent<{ value: unknown }>, index: number) => void;
  handleHourChangeEnd: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleMinuteChangeEnd: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handlePeriodChangeEnd: (event: React.ChangeEvent<{ value: unknown }>, index: number) => void;
  handleSubmit: (modalBranchIndex: any) => void;
}

const getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
  return localStorage.getItem("GET_SELECTED_LANGUAGE") === "ar" ? firstValue : secondValue;
};

const BranchOperatingHoursModal: React.FC<BranchOperatingHoursModalProps> = ({
  open,
  onClose,
  modalBranchIndex,
  branchData,
  handleCheckboxChange,
  handleHourChange,
  handleMinuteChange,
  handlePeriodChange,
  handleHourChangeEnd,
  handleMinuteChangeEnd,
  handlePeriodChangeEnd,
  handleSubmit,
}) => {

  return (
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box style={Modalstyles.modal}>
      <Box style={Modalstyles.subModalDiv}>
        <Box style={{...Modalstyles.topboxstyle, direction: getValueBasedOnLanguage('rtl', 'ltr')}}>
          <HeadingText style={{fontFamily: getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif"), }}>
          {I18n.t("branchOpHrs")}
          </HeadingText>
          <Close style={{ 
            paddingRight: getValueBasedOnLanguage('0', '1rem'),
            paddingLeft: getValueBasedOnLanguage('1rem', '0')
          }} onClick={onClose} />
        </Box>

        <Box style={{...Modalstyles.topboxstyle, direction: getValueBasedOnLanguage('rtl', 'ltr')}}>
          <SubHeadingText style={{
            fontFamily: getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("workingDays")}</SubHeadingText>
        </Box>

        <Box style={Modalstyles.topboxstyle2}>
          <FormGroup
            style={{
              display: "flex", flexDirection: "row",
              padding: "16px 40px 16px 40px", maxWidth: "100%", borderBottom: "1px solid #E2E8F0", justifyContent: "space-between"
            }}
          >
            {[I18n.t('Saturday'), I18n.t('Sunday'), I18n.t('Monday'), I18n.t('Tuesday'), I18n.t('Wednesday'), I18n.t('Thursday'), I18n.t('Friday')].map((day) => (
              <FormControlLabel
                key={day}
                control={<Checkbox
                  icon={<img src={unchecked} style={{ width: "20px", height: "20px", paddingBottom: "6px" }} />}
                  checkedIcon={<img src={checked} style={{ width: "20px", height: "20px", paddingBottom: "6px" }} />}
                  style={{ color: "#527FE2" }}
                  name={I18n.t(day)}
                  onChange={(event) => handleCheckboxChange(modalBranchIndex, day, event.target.checked)} />}
                label={day.charAt(0).toUpperCase() + day.slice(1)}
                data-test-id={day} 
                labelPlacement={getValueBasedOnLanguage("start", "end")}
                />
            ))}
          </FormGroup>
        </Box>

        <Box style={Modalstyles.dailyWorkingHrs}>
          <SubHeadingText style={{direction: getValueBasedOnLanguage('rtl', 'ltr'), fontFamily: getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("workingHrs")}</SubHeadingText>
          <BoxTimeTop style={{
            direction: getValueBasedOnLanguage('rtl', 'ltr')
          }}>
            <BoxTime>
              <HeadingTime style={{ 
                marginRight: getValueBasedOnLanguage('0', '1rem'), 
                fontFamily: getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("startTime")}</HeadingTime>
              <TimeRange123
                value={branchData.startTime?.hours}
                data-test-id={`starthours-${modalBranchIndex}`}
                onChange={(e) => handleHourChange(e as React.ChangeEvent<HTMLInputElement>, modalBranchIndex)}
                placeholder="00"
                variant="outlined"
                type="number"
                style={{
                  width: 60, 
                  fontSize: "20px",
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }} />
              <TimeRange123
                value={branchData.startTime?.minutes}
                data-test-id={`startminutes-${modalBranchIndex}`}
                onChange={(e) => handleMinuteChange(e as React.ChangeEvent<HTMLInputElement>, modalBranchIndex)}
                placeholder="00"
                variant="outlined"
                type="number"
                inputProps={{ min: 0, max: 59 }}
                style={{
                  width: 60,
                  fontSize: "20px",
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }} />
              <TimeRange123
                select
                onChange={(e) => handlePeriodChange(e as React.ChangeEvent<HTMLInputElement>, modalBranchIndex)}
                defaultValue={"AM"}
                data-test-id={`startperiod-${modalBranchIndex}`}
                style={{
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }}
              >
                <MenuItem value="AM" style={{fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("am")}</MenuItem>
                <MenuItem value="PM" style={{fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("pm")}</MenuItem>
              </TimeRange123>
            </BoxTime>

            <BoxTime>
              <HeadingTime style={{ marginRight: getValueBasedOnLanguage('0', '1rem'), fontFamily: getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif") }}>{I18n.t("endTime")}</HeadingTime>
              <TimeRange123
                 value={branchData.endTime?.hours}
                data-test-id={`endhours-${modalBranchIndex}`}
                onChange={(e) => handleHourChangeEnd(e as React.ChangeEvent<HTMLInputElement>, modalBranchIndex)}
                placeholder="00"
                variant="outlined"
                type="number"
                inputProps={{ min: 0, max: 24 }}
                style={{
                  width: 60,
                  fontSize: "20px",
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }} />
              <TimeRange123
                value={branchData.endTime?.minutes}
                data-test-id={`endminutes-${modalBranchIndex}`}
                onChange={(e) => handleMinuteChangeEnd(e as React.ChangeEvent<HTMLInputElement>, modalBranchIndex)}
                placeholder="00"
                variant="outlined"
                type="number"
                inputProps={{ min: 0, max: 59 }}
                style={{
                  width: 60, 
                  fontSize: "20px",
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }} />
              <TimeRange123
                select
                value={branchData.endTime?.period}
                defaultValue={"PM"}
                onChange={(e) => handlePeriodChangeEnd(e as React.ChangeEvent<{ value: unknown }>, modalBranchIndex)}
                data-test-id={`endperiod-${modalBranchIndex}`}
                style={{
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }}
              >
                <MenuItem value="AM" style={{fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("am")}</MenuItem>
                <MenuItem value="PM" style={{fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")}}>{I18n.t("pm")}</MenuItem>
              </TimeRange123>
            </BoxTime>
          </BoxTimeTop>
        </Box>

        <Box style={{
          display: "flex", justifyContent: "flex-end",
          padding: "20px 0px", width: "100%", borderTop: "1px solid #CBD5E1"
        }}>
          <AcceptBtn 
            style={{
                  fontFamily:getValueBasedOnLanguage("'Noto Sans Arabic', sans-serif", "Poppins, sans-serif")
                }}
            data-test-id="modalsubmit" onClick={() => handleSubmit(modalBranchIndex)}>{I18n.t("submitHrsModal")}</AcceptBtn>
        </Box>
      </Box>
    </Box>
  </Modal>
  );
};

const BoxTime = styled(Box)({
  display:"flex",alignItems:"center",width:"50%",gap:"0.5rem",
  "@media (max-width:700px )": {
   width:"100%",
  },
});

const Modalstyles: { [key: string]: React.CSSProperties } = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: getValueBasedOnLanguage('8px 8px 8px 32px', '8px 8px 32px 8px'),
    width: "1024px",
    maxWidth: "98vw",
  },
  subModalDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxHeight: "98vh",
    overflow: "auto",
  },
  topboxstyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 0px",
  },
  topboxstyle2: {
    padding: "24px 0px",
    width: "100%",
  },
  dailyWorkingHrs: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 40px 16px 40px",
    maxWidth: "100%",
    borderBottom: "1px solid #E2E8F0",
    justifyContent: "space-between",
  },
  timeRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.5rem 40px",
  },
  timeLabel: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "16px",
    marginRight: "1rem",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 0px",
    width: "100%",
    borderTop: "1px solid #CBD5E1",
  },
  headingText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "25px",
    padding: "0 40px",
  },
  subHeadingText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    padding: "0 40px",
  },
  submitButton: {
    maxWidth: "42.5%",
    marginRight: "24px",
    background: "#527FE2",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    fontFamily: "Poppins",
    fontSize: "1em",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
  },
};

const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "20px ",
  },
  "@media (max-width:899px )": {
    fontSize: "18",
  },

  fontFamily: "Poppins", lineHeight: "25px",
  fontSize: "24px", fontWeight: 700,
   padding: "0 40px"

});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },

  fontFamily: "Poppins",
  fontSize: "20px", fontWeight: 700, 
  padding: "0 40px"

});

const AcceptBtn = styled(Button)({
  maxWidth: "42.5%",
  marginRight:"24px",
  "@media (max-width:1050px )": {
    fontSize: "14px ",
    padding: "12px"
  },
  "@media (max-width:899px )": {
    fontSize: "12",
    padding: "8px"
  },

  background:
    "#527FE2",
  width: "100%",
  padding: "16px",
  gap: "8px",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Poppins ",
  fontSize: "1em",
  fontWeight: 600,
  lineHeight: "24px",
  cursor: "pointer",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  }

});

const HeadBox = styled(Box)({
  display:"flex",
  "@media (max-width:990px )": {
    flexDirection:"column"
  },
              
});
const SubHeadBox = styled(Box)({
  width:"50%",
  "@media (max-width:990px )": {
    width:"100%",
  },
              
});

const BoxTimeTop = styled(Box)({
  padding: "1.5rem 40px 1.5rem 40px",display:"flex",justifyContent:"space-between"  ,
  "@media (max-width:700px )": {
   flexDirection:"column"
  },
        
});

const HeadingTime = styled(Typography)({
  fontFamily:"Poppins",
              fontWeight:"bold",
              fontSize:"16px"
              
});

const TimeRange123 = styled(TextField)({

  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
    fontFamily: "Poppins",
    fontSize:"20px",fontWeight:700
  },

  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155", fontSize: "16px",fontWeight:400
  },

  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', 
    },
    '&:hover fieldset': {
      border: 'none', 
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },

  "& .MuiInputBase-root": {
    color: "black",fontFamily:"Poppins",fontSize:"1rem",paddingLeft:"5px"
  },

  "& .MuiOutlinedInput-input": {
    fontSize:"16px",fontWeight:400,padding:"0px"
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },

  "@media (max-width: 990px)": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});



export default BranchOperatingHoursModal;
