import { State } from "react-phone-number-input";
import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Notes } from "@material-ui/icons";
import moment from "moment"

interface FormValues {
  date: Date | null;
  time: string;
  attendee: string;
  notes: string;
  customer: string;
  personToMeet: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  currentLanguage: string;
  open: boolean;
  date: string,
  time: string,
  isDropdownVisible: boolean;
  isDropdownVisible1: boolean;
  selectedPerson: any;
  selectedCustomer: any;
  displaySelectedPerson: any;
  displaySelectedCustomer: any;
  attendee: string;
  openSuccessModal: boolean;
  openErrorModal: boolean;
  selectedDate: any,
  selectedUpdateDate: any,
  allCustomerDetails: any,
  allEmployeeDetails: any,
  notes: string,
  isDatePickerOpen:boolean,
  editData: any,
  datePickerAnchorEl:any
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAppointmentControllerweb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomersApiCallId: any;
  createAppointmentApiCallId: any;
  editAppointmentApiCallId: any
  getEmployeeApiCallId: any;
  // get

  // Customizable Area End

  constructor(props: Props) {
    super(props);


    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    const storedDate = localStorage.getItem("dateTime");
    const formattedTime = moment(storedDate).format("HH:mm").toString();
    // Customizable Area End



    this.state = {
      // Customizable Area Start
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      open: false,
      date: "",
      time: formattedTime,
      isDropdownVisible: false,
      isDropdownVisible1: false,
      selectedPerson: "",
      selectedCustomer: "",
      displaySelectedPerson: '',
      displaySelectedCustomer: '',
      attendee: "",
      openSuccessModal: false,
      openErrorModal: false,
      selectedDate: localStorage.getItem("dateTime") || new Date(),
      selectedUpdateDate: new Date(),
      allCustomerDetails: [],
      allEmployeeDetails: [],
      notes: "",
      editData: "",
      isDatePickerOpen:false,
      datePickerAnchorEl:null,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  // Customizable Area Start




  async componentDidMount() {
    this.getCustomerDetails()
    this.getEmployeeDetails()
  }
  async receive(from: string, message: Message) {
    console.log("mmm", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.getCustomersApiCallId) {
        this.setState({
          allCustomerDetails: responseJson.data,
        }, () => {
          this.getEventData()
        });
      }

      if (apiRequestCallId === this.getEmployeeApiCallId) {
        this.setState({
          allEmployeeDetails: responseJson.employees,
        });
      }
      if (apiRequestCallId === this.createAppointmentApiCallId) {
        if (responseJson?.success === true) {
          this.setState({
            openSuccessModal: true, selectedPerson: "",
            selectedCustomer: "",
            displaySelectedPerson: '',
            displaySelectedCustomer: '',
            attendee: "", selectedDate: new Date(), time: "", notes: ""
          });
        } else {
          this.setState({ openErrorModal: true });
        }
      }
      if (apiRequestCallId === this.editAppointmentApiCallId) {
        if (responseJson?.success === true) {
          this.setState({
            openSuccessModal: true,
            selectedPerson: "",
            selectedCustomer: "",
            displaySelectedPerson: '',
            displaySelectedCustomer: '',
            attendee: "", selectedDate: new Date(), time: "", notes: "",
            editData: ""
          });
          localStorage.setItem("eventData", "")
        } else {
          this.setState({ openErrorModal: true });
        }
      }

    }
    // Customizable Area End
  }
  getEventData() {
    const data_: string | null = localStorage.getItem('eventData');
    const parsedData = data_ ? JSON.parse(data_) : null;
    const urlParts = window.location.pathname.split("/");
    const isEdit = urlParts.length > 2 && !isNaN(Number(urlParts[2]));

    if (isEdit) {
      const customerObj = this.state.allCustomerDetails?.length > 0 && this.state.allCustomerDetails.find((item:any) => item.id == parsedData.customerId);

      if (parsedData) {
        this.setState({
          selectedDate: parsedData.start ? parsedData.start.split("T")[0] : null,
          time: parsedData.start ? parsedData.start.split("T")[1] : "",
          attendee: parsedData.allow,
          notes: parsedData.title || "",
          displaySelectedCustomer: customerObj?.attributes.full_name,
          selectedCustomer: parsedData.customerId,
          displaySelectedPerson: parsedData.personToMeetName,
          selectedPerson: parsedData.personToMeetId,
          editData: parsedData
        });
      }
    }
  }

  // Customizable Area End



  // Customizable Area Start

  // Customizable Area End


  // Customizable Area Start
  convertTo12HourFormat = (time24: string): string => {
    const [hours, minutes] = time24.split(":").map(Number);
    const hours12 = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";
    return `${hours12}:${minutes?.toString().padStart(2, "0")} ${period}`;
  };
  openDatePickerAdd = (): void => {
    this.setState({ isDatePickerOpen: true }); 
  };

  handleFormSubmit = (values: FormValues): void => {
    const urlParts = window.location.pathname.split("/");
    const isEdit = urlParts.length > 2 && !isNaN(Number(urlParts[2]));

    isEdit ? this.handleEditScreen(values) : this.handleOpen(values);
  };
  handleOpen = (values:FormValues) => {
    const formattedTime = this.convertTo12HourFormat(this.state.time);
    const token = localStorage.getItem("token")
    const formData = new FormData();
    formData.append("booked_slot[attendees]", values.attendee);
    formData.append("booked_slot[booking_date]", this.state.selectedDate);
    formData.append("booked_slot[service_provider_employee_id]", this.state.selectedPerson);
    formData.append("booked_slot[service_provider_id]", this.state.selectedCustomer);
    formData.append("booked_slot[start_time]", formattedTime);
    formData.append("booked_slot[notes]", values.notes);
    // console.log(data,'datadatadata')
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAppointmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAppointmentAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  convertTo12HourFormat2 = (dateString?: string): string => {
    if (!dateString) return ""; 
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; 
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    const ampm: string = hours >= 12 ? "PM" : "AM";
  
    hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`; // Ensure two-digit minutes
  
    return `${hours}:${formattedMinutes} ${ampm}`;
  };
  
  handleEditScreen = (values: FormValues): boolean => {
    const { editData } = this.state;
    const formattedTime = this.convertTo12HourFormat(values.time || this.state.time);
    const formattedDate = this.formatDate(values.date || this.state.selectedDate);
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
  
    formData.append("booked_slot[start_time]", formattedTime);
    formData.append("booked_slot[booking_date]", formattedDate);
    formData.append("booked_slot[service_provider_employee_id]",this.state.selectedPerson);
    formData.append("booked_slot[attendees]", values.attendee);
    formData.append("booked_slot[service_provider_id]", this.state.selectedCustomer);
    formData.append("booked_slot[notes]", values.notes);
  
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token,
    };
  
    const id = editData.id;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.editAppointmentApiCallId = requestMessage.messageId;
  
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.EditAppointmentAPIEndPoint}/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.editAppointmentAPiMethod);
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
  

  handleInputChange = (field: "date" | "time", value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  handleClose = () => {
    this.setState({
      openSuccessModal: false,
      openErrorModal: false,
    })
    this.props.navigation.navigate('AppoinmentManagement');
  };
  handleTryAgain = () => {
    this.setState({ openErrorModal: false });
  };
  formatDate = (isoString: string | number | Date) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  handleDateChange = (date: any) => {
    const formattedDate = this.formatDate(date);
    this.setState({ selectedDate: formattedDate });
  };

  toggleDropdown = (): void => {
    this.setState(prevState => ({ isDropdownVisible: !prevState.isDropdownVisible, isDropdownVisible1: false }));
  };
  toggleDropdown1 = (): void => {
    this.setState(prevState => ({ isDropdownVisible1: !prevState.isDropdownVisible1, isDropdownVisible: false }));
  };
  selectPerson = (personName: any): void => {
    this.setState({ selectedPerson: personName.id, isDropdownVisible: false, displaySelectedPerson: personName.name });
  };
  selectCustomer = (CustomerName: any): void => {
    this.setState({ selectedCustomer: CustomerName.id, isDropdownVisible1: false, displaySelectedCustomer: CustomerName.attributes.full_name });
  };
  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };
  getCustomerDetails = () => {
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomerAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getEmployeeDetails = () => {
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmployeeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmployeesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
