// Customizable Area Start
import React from "react";
import AppNavigation from "../../../components/src/AppNavigation.web";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { I18n } from "../../../components/src/languageJson/i18n";
import { Box, styled, Typography, Card, Button, Grid, InputAdornment, OutlinedInput } from "@material-ui/core"
import { grayDepartMentIcon, redDepartMentIcon } from "./assets";
import { InputTypesFields } from "../../../components/src/types"
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { getValueBasedOnLanguage, InputField, StyledFormControl,SelectMain, MenuItemSet, TextAreaField, getCommonMenuProps, CommonBoxStyles } from "../../../components/src/constant";
import CustomDropdownIcon from "../../../components/src/CustomDropdownIcon";



import AddDepartmentController, {
    Props,
  } from "./AddDepartmentController";


class AddDepartment extends AddDepartmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderErrorDiv = (errorMsg : string) => {
    return (
        <Typography
            style={{
                marginTop: "2px", fontSize: "14px", color: "#DC2626", 
                textAlign: this.setCSSBasedOnLanguage("right", "left"),
                fontFamily: this.setCSSBasedOnLanguage("Noto Sans Arabic", "Poppins")
            }}
        >
            {errorMsg}
        </Typography>
    )
  }

  renderInputSection = (fields : InputTypesFields) => {
    const { name, placeholder,value,errorKey, errorMsg } = fields
     return (
         <Box>
             <InputField
                 data-test-id={name}
                 fullWidth
                 variant="outlined"
                 placeholder={placeholder}
                 value={value}
                 name={name}
                 error={errorKey}
                 onChange={this.handleChange}
                 style={{
                     direction: this.setCSSBasedOnLanguage("rtl", "ltr"),
                     textAlign: this.setCSSBasedOnLanguage("right", "left"),
                 }}
                 InputProps={{
                     startAdornment: <InputAdornment position="start">
                         <img
                             src={errorKey ? redDepartMentIcon : grayDepartMentIcon}
                             alt="dep_icon"
                         />

                     </InputAdornment>,
                 }}
             />
             {errorKey && this.renderErrorDiv(errorMsg)}
         </Box>
     )
  }

  renderTextAreaSection = (fields : InputTypesFields) => {
    const { name, placeholder,value,errorKey, errorMsg } = fields
     return (
         <Box>
             <TextAreaField
                 data-test-id={name}
                 placeholder={placeholder}
                 variant="outlined"
                 fullWidth
                 multiline
                 minRows={2}
                 maxRows={4}
                 onChange={this.handleChange}
                 value={value}
                 name={name}
                 error={errorKey}
                 style={{
                     textAlign: this.setCSSBasedOnLanguage("right", "left"),
                     direction: this.setCSSBasedOnLanguage("rtl", "ltr"),
                 }}
             />
             {errorKey && this.renderErrorDiv(errorMsg)}
         </Box>
     )
  }



  render() {
    // Customizable Area Start
    
    return (
      <div style={{display:'flex', flexDirection: getValueBasedOnLanguage('row-reverse', "row")}}>
        <AppNavigation />
         <AddDepartmentWrapper className={getValueBasedOnLanguage("arabic-text", "default-text")}>

                 {/* BreadCrumbs */}
                <Box style={{ paddingRight: "20px", display: 'flex', flexDirection: this.setCSSBasedOnLanguage("row-reverse", "row") }}>
                    <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("organizationManagement"), I18n.t("Add New Department")]} pathToNavigate="OrganisationHierarchy" navigation={this.props.navigation} />
                </Box>

                {/* Add Department Form */}
                <Box style={{ maxWidth: "100vw", display: "flex",flexDirection:"column",gap:"1.5rem", justifyContent: "center",padding:"1.5rem 1.5rem 1.5rem 0" }}>

                    <Card style={{
                        maxWidth: "100vw",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                        justifyContent: "center",
                        padding: "1.5rem 1.5rem",
                        borderRadius: this.setCSSBasedOnLanguage("0 24px 24px 0", "24px 0 0 24px"),
                        border: "solid 1px #E2E8F0",
                    }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                marginBottom: "16px",
                                textAlign: this.setCSSBasedOnLanguage("right", "left"),
                                alignSelf: this.setCSSBasedOnLanguage("flex-end", "flex-start"),
                                color: "#1a1a1a",
                                fontFamily: this.setCSSBasedOnLanguage("Noto Sans Arabic", "Poppins")

                            }}
                        >
                            {I18n.t("Add New Department")}
                        </Typography>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderInputSection({
                                name: "department_name_en",
                                value: this.state.departmentFormFields.department_name_en,
                                errorKey: this.state.departmentFormErrorFields.department_name_en,
                                placeholder: I18n.t("Department name (EN)*"),
                                errorMsg: I18n.t("Please enter a department name in english")
                            })}

                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderInputSection({
                                name: "department_name_ar",
                                value: this.state.departmentFormFields.department_name_ar,
                                errorKey: this.state.departmentFormErrorFields.department_name_ar,
                                placeholder: I18n.t("Department name (AR)*"),
                                errorMsg: I18n.t("Please enter a department name in arabic")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12} >
                            {this.renderTextAreaSection({
                                name: "department_desc_en",
                                value: this.state.departmentFormFields.department_desc_en,
                                errorKey: this.state.departmentFormErrorFields.department_desc_en,
                                placeholder: I18n.t("Description (EN)*"),
                                errorMsg: I18n.t("Please enter department description in english")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}   >
                            {this.renderTextAreaSection({
                                name: "department_desc_ar",
                                value: this.state.departmentFormFields.department_desc_ar,
                                errorKey: this.state.departmentFormErrorFields.department_desc_ar,
                                placeholder: I18n.t("Description (AR)*"),
                                errorMsg: I18n.t("Please enter department description in arabic")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}   >
                            <StyledFormControl variant="outlined" fullWidth
                            >
                                <SelectMain
                                    data-test-id="parent_department"
                                    MenuProps={getCommonMenuProps}
                                    name={"parent_department"}
                                    value={this.state.parent_department}
                                    onChange={this.handleSelectChange}
                                    displayEmpty
                                    IconComponent={CustomDropdownIcon}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img
                                                src={grayDepartMentIcon}
                                                alt="dep_icon"
                                            />

                                        </InputAdornment>
                                    }
                                    renderValue={(value) => {
                                        const selectedDepartment = this.state.departmentsOptions.find((option) => option.id === value);
                                        return (
                                            <Box
                                                style={{
                                                    color: selectedDepartment ? 'currentColor' : '#94A3B8',
                                                    ...CommonBoxStyles
                                                }}
                                            >
                                                {selectedDepartment ? selectedDepartment.name : I18n.t('parentDepartment')}
                                            </Box>
                                        );
                                    }}
                                    input={<OutlinedInput />}
                                >
                                    {this.state.departmentsOptions.map((optionVal, index) => (
                                        <MenuItemSet
                                            style={{
                                                borderBottom: '1px solid #E2E8F0',
                                                fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                            }}
                                            className="setMenuItem"
                                            key={index}
                                            value={optionVal.id}
                                        >
                                            {optionVal.name}
                                        </MenuItemSet>
                                    ))}
                                </SelectMain>
                            </StyledFormControl>
                        </Grid>
                    </Card>
            </Box>

                {/* Save Button Section */}
                <Box style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
                    <SaveButton data-test-id="saveBtn" onClick={this.saveDepartmentInfo}> {I18n.t("Save")}</SaveButton>
                </Box>

                {/* Success Modal */}
                <SuccessModal 
                    open={this.state.successModal}
                    handleClose={this.closeSuccessModal}
                    msg_en={this.state.msg_en}
                    msg_ar={this.state.msg_ar}
                    data-test-id="successModal"
                />

                {/* Error Modal */}
                <ErrorModal 
                    open={this.state.errorModal}
                    handleClose={this.closeErrorModal}
                    data-test-id="errorModal"
                />
         </AddDepartmentWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default AddDepartment;

const AddDepartmentWrapper = styled(Box)({
    flex:1, 
    backgroundColor:"#F1F5FC", 
    minHeight: "calc(100vh - 100px)",
    padding:'50px 0 50px 20px', 
    marginRight: getValueBasedOnLanguage("325px", 0)
})

const SaveButton = styled(Button)({
    color: "white",
    borderRadius: "8px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    padding: "10px 16px 10px 16px",
    maxWidth: "306px",
    width: "306px",
    lineHeight: "24px",
    height: "44px",
    "&:hover": {
        color: "white",
        backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
        fontSize: "18px",
        padding: "10px 16px 10px 16px",
    },
});

// Customizable Area End