// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { apiCalling, truthyValue } from "../../../components/src/constant";
import { DepartmentFormFields, DepartmentFormErrorFields, DepartmetOptionTypes } from "../../../components/src/types";
import { getStorageData } from ".../../../packages/framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  currentLanguage : string,
  selectedLanguage : string,
  departmentFormFields : DepartmentFormFields,
  departmentFormErrorFields : DepartmentFormErrorFields,
  successModal : boolean,
  errorModal : boolean,
  msg_en : string,
  msg_ar: string
  departmentsOptions : DepartmetOptionTypes[],
  parent_department: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddDepartmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addDepartmentApiCallId : string = ""
  listDepartmentOptionsApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
        selectedLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || "en",
        departmentFormFields : this.emptyFormFields,
        departmentFormErrorFields : this.emptyErrorFields,
        successModal : false,
        errorModal: false,
        msg_ar : "",
        msg_en : "",
        departmentsOptions: [],
        parent_department: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      switch(apiRequestCallId) {
        case this.addDepartmentApiCallId:
          return this.handleAddDepartmentApiResponse(responseJson)
        case this.listDepartmentOptionsApiCallId:
          return this.handleListDepartmentOptionApiResponse(responseJson)
        default:
           break
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
     this.listDepartmentOptionsData()
  }

  handleAddDepartmentApiResponse = (responseJson: any) => {
      if(responseJson && responseJson.message) {
        const msg_en = truthyValue(responseJson.message.en)
        const msg_ar = truthyValue(responseJson.message.ar)
        this.setState({ msg_ar, msg_en})
        this.openSuccessModal()
      } else {
         this.openErrorModal()
      }
  }

  handleListDepartmentOptionApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.departments && responseJson.departments.length > 0) {
      const receivedArray = truthyValue(responseJson.departments)
      const modifiedArray = receivedArray.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })

      this.setState({ departmentsOptions: modifiedArray })

    } else {
      this.setState({ departmentsOptions: [] })
    }
  }

  emptyFormFields: DepartmentFormFields = {
    department_name_en : "",
    department_name_ar : "",
    department_desc_en : "",
    department_desc_ar : "",
  }

  emptyErrorFields: DepartmentFormErrorFields = {
    department_name_en : false,
    department_name_ar : false,
    department_desc_en : false,
    department_desc_ar : false,
  }
 
  setCSSBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.selectedLanguage === "ar" ? firstValue : secondValue;
  };

  handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    if(value.trim() === "") {
      this.setState({ departmentFormErrorFields: {...this.state.departmentFormErrorFields, [name] : true}})
    } else {
      this.setState({ departmentFormErrorFields: {...this.state.departmentFormErrorFields, [name] : false}})
    }

    this.setState({ departmentFormFields : {...this.state.departmentFormFields, [name]: value}})
  }

  handleAllValidation = () => {
    let errorFields = {
      department_name_en : false,
      department_name_ar : false,
      department_desc_en : false,
      department_desc_ar : false,
    }
     
    if (this.state.departmentFormFields.department_name_en.trim() === "") {
      errorFields.department_name_en = true;
    }
    if (this.state.departmentFormFields.department_name_ar.trim() === "") {
      errorFields.department_name_ar = true;
    }
    if (this.state.departmentFormFields.department_desc_en.trim() === "" ) {
      errorFields.department_desc_en = true;
    }
    if (this.state.departmentFormFields.department_desc_ar.trim() === "") {
      errorFields.department_desc_ar = true;
    }

    this.setState({ departmentFormErrorFields: errorFields})

    return Object.values(errorFields).every((value) => value === false);
    
  }

  saveDepartmentInfo = async () => {
    if(!this.handleAllValidation()) {
      return false;
   }

    const tokenvalue = await getStorageData('token')
    const header = {
      'Content-Type': 'application/json',
      token: tokenvalue,
    }

    const httpBody = {
      "department": {
        "name_ar": this.state.departmentFormFields.department_name_ar,
        "name": this.state.departmentFormFields.department_name_en,
        "description_ar": this.state.departmentFormFields.department_desc_ar,
        "description_en": this.state.departmentFormFields.department_desc_en,
        ...(this.state.parent_department !== "" && { "parent_id" : this.state.parent_department})
      }
    }

    this.addDepartmentApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.httpPostMethod,
      endPoint: configJSON.addDepartmentsApiEndPoint,
      body: JSON.stringify(httpBody)
    })
  }

  listDepartmentOptionsData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.listDepartmentOptionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.httpGetMethod,
      endPoint: configJSON.dropdownOptionsDepartmentApiEndPoint,
    })
  }

  handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string
    this.setState({ parent_department: val})

  }
  openSuccessModal = () => {
    this.setState({ successModal : true})
  }

  closeSuccessModal = () => {
    this.setState({ successModal : false}, () => this.navToOrgChartType())
  }

  openErrorModal = () => {
    this.setState({ errorModal : true})
  }

  closeErrorModal = () => {
    this.setState({ errorModal : false})
  }

  navToOrgChartType = () => {
    this.props.navigation.navigate("OrganisationHierarchy")
  }

  // Customizable Area End
}

// Customizable Area End