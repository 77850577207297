export const anonymousPng = require("../assets/anonim.jpeg");
export const chartWhiteIcon = require("../assets/chartWhite.svg")
export const chartGrayIcon = require("../assets/chartGray.svg")
export const tableWhiteIcon = require("../assets/tableWhite.svg")
export const tableGrayIcon = require("../assets/tableGray.svg")
export const grayDepartMentIcon = require("../assets/grayDepartMentIcon.svg")
export const redDepartMentIcon = require("../assets/redDepartMentIcon.svg")
export const user_ceo_Icon = require("../assets/UserImages/1-ceo.png")
export const user_cmo_Icon = require("../assets/UserImages/2-cmo.png")
export const user_cso_Icon = require("../assets/UserImages/3-cso.png")
export const user_cfo_Icon = require("../assets/UserImages/4-cfo.png")
export const user_coo_Icon = require("../assets/UserImages/5-coo.png")
export const errorIcon = require("../assets/errorIcon.png")
export const grayPositionIcon = require("../assets/grayPositionIcon.png")
export const redPositionIcon = require("../assets/redPositionIcon.png")
export const upIcon = require("../assets/upIcon.svg")
export const downIcon = require("../assets/downIcon.svg")



