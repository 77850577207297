import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { switchLanguage, I18n } from "../../../components/src/languageJson/i18n";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { DepartmetOptionTypes, FilterHttpBodyProps } from "../../../components/src/types";
import { apiCalling, truthyValue } from "../../../components/src/constant";

interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  receivedRequests: IRequest[];
  rejectText: string;
  rejectTextError: string | null;
  selectedId: string | null;
  viewRequest: IRequest | null;
  filterKey: string;
  currentLanguage: string;
  selected:any;
  selectedSubOption:any;
  ticketData:any;
  getSearch: string;
  selectedTicketID: string;
  setCount:number;
  page:number;
  per_page: number
  filterModal : boolean,
  departmentOptionArray : DepartmetOptionTypes[]
  employeeOptionsArray : DepartmetOptionTypes[],
  appliedFilters : Partial<FilterHttpBodyProps>,
  errorMsg : string,
  searchInput : string,
  selectedCatogoryFilter : string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllReceivedRequestCallId: string = "";
  updateRequestReviewCallId: string = "";
  deleteRequestCallId: string = "";
  currentLanguage!:string;
  getTicketCallId:string = "";
  getDepartmentsOptionsApiCallId: string = ""
  getEmployeeOptionsApiCallId: string = ""
  applyFiltersApiCallId : string = ""
  getFiltersApiCallId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      receivedRequests: [],
      rejectText: "",
      rejectTextError: null,
      selectedId: null,
      viewRequest: null,
      filterKey: '',
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      selected:0,
      selectedSubOption:0,
      ticketData:[],
      getSearch: "",
      selectedTicketID: "",
      setCount:24,
      page:1,
      per_page: 12,
      filterModal: false,
      departmentOptionArray: [],
      employeeOptionsArray : [],
      appliedFilters: {},
      errorMsg: "",
      searchInput : "",
      selectedCatogoryFilter : this.catogoryFilters.all
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({ token: token }, () => {
          this.getAllReceivedRequest();
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getDepartmentsOptionsApiCallId:
          return this.handleDropdownListResponse(responseJson)
        case this.getEmployeeOptionsApiCallId:
          return this.handleEmployeeDropdownListResponse(responseJson)
        case this.applyFiltersApiCallId:
          return this.handleAppliedFilteredResponse(responseJson)
        case this.getFiltersApiCallId:
          return this.handleCommonAppliedFilteredResponse(responseJson)
        default:
          return this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleApiResponse = (apiRequestCallId : any,responseJson: any) => {
    if (
      apiRequestCallId === this.getAllReceivedRequestCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.setState({ receivedRequests: responseJson.data });
    } else if (
      apiRequestCallId === this.updateRequestReviewCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.setState({ selectedId: null, rejectText: "" });
      this.getAllReceivedRequest();
    } else if (
      apiRequestCallId === this.deleteRequestCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.getAllReceivedRequest();
    } else if (
      apiRequestCallId === this.getTicketCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.setState({
        ticketData: responseJson.data,
        setCount:responseJson.total_count
      })
    } else if(
      apiRequestCallId === this.getTicketCallId &&
      responseJson !== undefined &&
      responseJson.message   
    ) {
      this.setState({ticketData: []})
    } 
  }

  handleEmployeeDropdownListResponse = (responseJson: any) => {
    if (responseJson && responseJson.employees && responseJson.employees.length > 0) {
      const receivedArr = truthyValue(responseJson.employees)
      const modifiedArray = receivedArr.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })
      this.setState({ employeeOptionsArray: modifiedArray })
    } else {
      this.setState({ employeeOptionsArray: [] })
    }
  }  

  handleDropdownListResponse = (responseJson: any) => {
    if (responseJson && responseJson.departments && responseJson.departments.length > 0) {
      const receivedArr = truthyValue(responseJson.departments)
      const modifiedArray = receivedArr.map((item: any) => {
        return {
          id: truthyValue(item.id),
          name: truthyValue(item.name)
        }
      })
      this.setState({ departmentOptionArray: modifiedArray })
    } else {
      this.setState({ departmentOptionArray: [] })
    }
  }

  handleAppliedFilteredResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const receivedArr = truthyValue(responseJson.data)
      const totalCount = truthyValue(responseJson.total_count)
      this.setState({
        ticketData: receivedArr,
        setCount: totalCount,
        filterModal : false
      })
    } else {
      this.setState({
        ticketData: [],
        filterModal : false
      })
    }
  }

  handleCommonAppliedFilteredResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const receivedArray = truthyValue(responseJson.data)
      const totalPageCount = truthyValue(responseJson.total_count)
      this.setState({
        ticketData: receivedArray,
        setCount: totalPageCount,
      })
    } else {
      this.setState({
        ticketData: [],
      })
    }
  }

  gotToNextPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TicketDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  componentDidMount = async () => {
      this.getCommonSearchFiltersData()
      this.getAllDepartments()
      this.getAllEmployees()
      this.getToken();

      if (this.isPlatformWeb() === false) {
          this.props.navigation.addListener("willFocus", () => {
              this.getToken();
          });
      }
    
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  handleNavigation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AddEmployee");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value})
      this.setState({ page: value }, () => {
        this.handleTickets();
      });
    this.setState({ page:value });
  };

  getAllDepartments = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.getDepartmentsOptionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.getDepartmentApiEndPoint,
      method: configJSON.getGroupsApiMethod,
    })
  }

  getAllEmployees = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    this.getEmployeeOptionsApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.getEmployeesOptionsApiEndPoint,
      method: configJSON.getGroupsApiMethod,
    })
  }

  getAllReceivedRequest = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReceivedRequestCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReceivedRequestApiEndpoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  updateRequestReview = (requestReviewId: string, is_accepted: boolean) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    if (!is_accepted && this.state.rejectText.length < 1) {
      this.setState({ rejectTextError: configJSON.rejectTextFieldIsRequired });
      return;
    }

    const httpBody = {
      is_accepted,
      ...(!is_accepted && {
        rejection_reason: this.state.rejectText,
      }),
    };

    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRequestReviewCallId = updateRequestReviewMsg.messageId;

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRequestApiEndpointStart +
        requestReviewId +
        configJSON.updateRequestApiEndpointEnd
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);
  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const deleteRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRequestCallId = deleteRequestMsg.messageId;

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRequestApiEndpoint + deleteRequestId
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRequestApiMethod
    );

    runEngine.sendMessage(deleteRequestMsg.id, deleteRequestMsg);
  };

  handleTickets = async () => {
    const tokenL  =  await getStorageData('token')
    const perPage = `per_page=${this.state.per_page}&page=${this.state.page}`;
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTicketCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/requests?${perPage}`
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };
  
  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputGot= event.target.value.toLowerCase();
    this.setState({ searchInput : inputGot, page: 1}, ()=>{
      this.getCommonSearchFiltersData()
    })    
  }

  handleRowClick = (ticketId: string) => {
    localStorage.setItem('selectedTicketID', ticketId);
    this.setState({
      selectedTicketID:ticketId ,
    }, () => {
      this.gotToNextPage();
      //this.handleTicketDetails(ticketId)
    });
  

  };
  

  onChangeTextRejectText = (rejectText: string) => {
    this.setState({ rejectText });
  };

  setSelectedId = (selectedId: string | null) => {
    this.setState({ selectedId, rejectText: "", rejectTextError: null });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SentRequest");

    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    navigationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: IRequest) => {
    this.setState({viewRequest})
  }

  closeViewModal = () => {
    this.setState({viewRequest: null})
  }


  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  changeLanguage = (language: string) => {
    this.setState({ currentLanguage: language });
    switchLanguage(language);
  };

  switchToEnglish = () => {
    switchLanguage("en");
  };

  switchToArabic = () => {
    switchLanguage("ar");
  };

  handleOptionSelect = (index:any) => {
    this.setState({ selected: index });
    if( index=== 0 ){
     this.handleTickets();
    }
  };

  formatCategory = (category:string) => {
    const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);
    return capitalizedCategory;
  }

  dateFormat = (inputDate:any) => {
    const date = new Date(inputDate);

    const day: string = String(date.getDate()).padStart(2, '0');
    const monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month: string = monthNames[date.getMonth()];
    const year: number = date.getFullYear();
    let hours: number = date.getHours();
    const minutes: string = String(date.getMinutes()).padStart(2, '0');
    const ampm: string = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; 

    const formattedDate: string = `${day} ${month} ${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    return formattedDate
  }

  handleCatogoriesFilters = (value : string) => {
    this.setState({ selectedCatogoryFilter : value, page: 1}, () => this.getCommonSearchFiltersData())
  } 

  openFilterModal = () => {
    this.setState({ filterModal: true})
  }

  closeFilterModal = () => {
    this.setState({ filterModal: false})
  }

  applyFilters = async (filterValues : Partial<FilterHttpBodyProps>) => {
    this.setState({ appliedFilters : filterValues, selectedCatogoryFilter: this.catogoryFilters.all, searchInput : ""})

    if(Object.keys(filterValues).length === 0) {
       return false
    }

    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    let queryParams = `page=${this.state.page}&per_page=${this.state.per_page}`;

    const filterParams = this.convertToQueryParams(filterValues)

    queryParams += filterParams ? `&${filterParams}` : "";

    this.applyFiltersApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.applyFiltersApiEndPoint}?${queryParams}`,
      method: configJSON.getGroupsApiMethod,
    })

  }

  getCommonSearchFiltersData = async () => {
    const tokenvalue = await getStorageData('token')
    const header = {
      token: tokenvalue
    }

    let queryParams = `page=${this.state.page}&per_page=${this.state.per_page}`;

    queryParams += this.state.searchInput !== "" ? `&search=${this.state.searchInput}` : ""

    queryParams += this.state.selectedCatogoryFilter !== this.catogoryFilters.all ? `&request_type=${this.state.selectedCatogoryFilter}` : ""

  
    
    this.getFiltersApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.applyFiltersApiEndPoint}?${queryParams}`,
      method: configJSON.getGroupsApiMethod,
    })

  }

  findNameFromArray = (data : DepartmetOptionTypes[], id : string) => {
    let name = ""
    const findName = data.find(item => item.id === parseInt(id))
    
    if(findName) {
      name = findName.name
    } 
    return name
  }

  makeArrayofStr = (value : any) => {
    let arrVal : string[] = []
    if(value){
      arrVal = value.split(",") 
    }
    return arrVal
  }

  handleChipTitle = (title: string, key: keyof FilterHttpBodyProps) => {

    switch (key) {
      case "department_id":
        return this.findNameFromArray(this.state.departmentOptionArray, title)
      case "assigned_to":
        return this.findNameFromArray(this.state.employeeOptionsArray, title)
      default:
        return title
    }
  }

  removeFilters = (value :  string | number , key : keyof FilterHttpBodyProps, arrayValues : string[]) => {
    const modifiedArray = arrayValues.filter(item => item !== value)

    this.setState((prevState) => ({
      appliedFilters: {
        ...prevState.appliedFilters,
        [key]: modifiedArray.toString(),
      },
    }), () => this.applyFilters(this.state.appliedFilters));
  }

  convertToQueryParams = (filters: Partial<FilterHttpBodyProps>): string => {
    const params = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined) {
        if (typeof value === "string" && value.includes(",")) {
          value.split(",").forEach((val) => {
            params.append(`${key}[]`, val);
          });
        } else {
          params.append(key, String(value));
        }
      }
    });
  
    return params.toString();
  };

  reqCatogoriesData = [ { key :`${I18n.t("service")}`, value: "service"}, { key: `${I18n.t("suggestion")}`, value: "suggestion"}, { key :`${I18n.t("complaint")}`, value: "complaint"}]

  reqStatusData = [ { key :`${I18n.t("pendingStatusText")}`, value: "Pending"}, { key: `${I18n.t("progressStatusText")}`, value: "In_Progress"}, { key :`${I18n.t("finishedStatusText")}`, value: "Finished"}, { key : `${I18n.t("reviewingStatusText")}`, value : "Reviewing"}]

  catogoryFilters = {
    all : "all",
    service : "service",
    suggestion : "suggestion",
    complaint : "complaint"
  }

  catogoriesFiltersOptions = [ 
    { label :`${I18n.t("all")}`, value: this.catogoryFilters.all},
    { label :`${I18n.t("service")}`, value: this.catogoryFilters.service},
    { label: `${I18n.t("suggestion")}`, value: this.catogoryFilters.suggestion}, 
    { label :`${I18n.t("complaint")}`, value: this.catogoryFilters.complaint}
  ]


  // Customizable Area End
}