// Customizable Area Start
import React from "react";
import AppNavigation from "../../../components/src/AppNavigation.web";
import { getValueBasedOnLanguage } from "../../../components/src/constant";


import NewDashboardController, {
    Props,
  } from "./NewDashboardController";
  


class NewDashboard extends NewDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div style={{display:'flex', flexDirection: getValueBasedOnLanguage('row-reverse', "row")}}>
         <AppNavigation />
      </div>
    );
    // Customizable Area End
  }
}

export default NewDashboard;

// Customizable Area End