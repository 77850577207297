// Customizable Area Start
import React from "react";
import AppNavigation from "../../../components/src/AppNavigation.web";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { I18n } from "../../../components/src/languageJson/i18n";
import { Box, styled, Typography, Card, Button, Grid, InputAdornment, OutlinedInput } from "@material-ui/core"
import { grayDepartMentIcon, grayPositionIcon, redDepartMentIcon, redPositionIcon } from "./assets";
import { InputTypesFields } from "../../../components/src/types"
import SuccessModal from "../../../components/src/SuccessModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { InputField, TextAreaField, getValueBasedOnLanguage, StyledFormControl,MenuItemSet, SelectMain, getCommonMenuProps, CommonBoxStyles } from "../../../components/src/constant";
import CustomDropdownIcon from "../../../components/src/CustomDropdownIcon";


import AddPositionController, {
    Props,
  } from "./AddPositionController";


class AddPosition extends AddPositionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderAlertMsg = (message : string) => {
      return (
          <Typography
              style={{
                  fontFamily: this.setPageLayoutCSS("Noto Sans Arabic", "Poppins"),
                  marginTop: "2px", fontSize: "14px", color: "#DC2626",
                  textAlign: this.setPageLayoutCSS("right", "left"),
              }}
          >
              {message}
          </Typography>
      )
  }

  renderTitleSection = (input : InputTypesFields) => {
      return (
          <Box>
              <InputField
                  data-test-id={input.name}
                  placeholder={input.placeholder}
                  fullWidth
                  variant="outlined"
                  value={input.value}
                  name={input.name}
                  error={input.errorKey}
                  onChange={this.handleInputChange}
                  style={{
                      direction: this.setPageLayoutCSS("rtl", "ltr"),
                      textAlign: this.setPageLayoutCSS("right", "left"),
                  }}
                  InputProps={{
                      startAdornment: <InputAdornment position="start">
                          <img
                              src={input.errorKey ? redPositionIcon : grayPositionIcon}
                              alt="dep_icon"
                          />

                      </InputAdornment>,
                  }}
              />
              {input.errorKey && this.renderAlertMsg(input.errorMsg)}
          </Box>
      )
  }

  renderDescriptionSection  = (desc : InputTypesFields) => {
     return (
         <Box>
             <TextAreaField
                 data-test-id={desc.name}
                 placeholder={desc.placeholder}
                 variant="outlined"
                 fullWidth
                 minRows={2}
                 multiline
                 maxRows={4}
                 onChange={this.handleInputChange}
                 value={desc.value}
                 name={desc.name}
                 error={desc.errorKey}
                 style={{
                     textAlign: this.setPageLayoutCSS("right", "left"),
                     direction: this.setPageLayoutCSS("rtl", "ltr"),
                 }}
             />
             {desc.errorKey && this.renderAlertMsg(desc.errorMsg)}
         </Box>
     )
  }

  renderDepartmentSelectSection = () => {
      return (
          <Box>
              <StyledFormControl variant="outlined" fullWidth
              >
                  <SelectMain
                      data-test-id="department_id"
                      IconComponent={CustomDropdownIcon}
                      name={"department_id"}
                      error={this.state.formErrorFields.department_id}
                      value={this.state.formFields.department_id}
                      onChange={this.handleSelectDepartmentChange}
                      displayEmpty
                      startAdornment={
                          <InputAdornment position="start">
                              <img
                                  src={this.state.formErrorFields.department_id ? redDepartMentIcon : grayDepartMentIcon}
                                  alt="dep_icon"
                              />

                          </InputAdornment>
                      }
                      renderValue={(value) => {
                          const selcetedVal = this.state.departmentOptionsArr.find((option) => option.id.toString() === value as string);
                          return (
                              <Box
                                  style={{
                                      color: selcetedVal ? 'currentColor' : '#94A3B8',
                                      ...CommonBoxStyles 
                                  }}
                              >
                                  {selcetedVal ? selcetedVal.name : I18n.t('Department *')}
                              </Box>
                          );
                      }}
                    MenuProps={getCommonMenuProps}
                    input={<OutlinedInput  />}
                  >
                      {this.state.departmentOptionsArr.map((arrayVal, index) => (
                          <MenuItemSet
                              style={{
                                  fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                  borderBottom: '1px solid #E2E8F0',
                              }}
                              className="setMenuItem"
                              key={index}
                              value={arrayVal.id.toString()}
                          >
                              {arrayVal.name}
                          </MenuItemSet>
                      ))}
                  </SelectMain>
              </StyledFormControl>
              {this.state.formErrorFields.department_id && this.renderAlertMsg(I18n.t("positionErrorDepartmentField"))}

          </Box>
      )
  }

    renderParentPositionSelectSection = () => {
        return (
            <Box>
                <StyledFormControl variant="outlined" fullWidth
                >
                    <SelectMain
                        data-test-id={"parent_position_id"}
                        MenuProps={getCommonMenuProps}
                        fullWidth
                        variant="outlined"
                        name={"parent_position_id"}
                        value={this.state.parent_position_id}
                        onChange={this.handleSelectParentPositionChange}
                        displayEmpty
                        IconComponent={CustomDropdownIcon}
                        startAdornment={
                            <InputAdornment position="start">
                                <img
                                    src={grayDepartMentIcon}
                                    alt="dep_icon"
                                />

                            </InputAdornment>
                        }
                        renderValue={(value) => {
                            const selcetedParentDepartmentVal = this.state.parentPositionOptions.find((option) => option.id.toString() === value as string);
                            return (
                                <Box
                                    style={{
                                        color: selcetedParentDepartmentVal ? 'currentColor' : '#94A3B8',
                                        ...CommonBoxStyles
                                    }}
                                >
                                    {selcetedParentDepartmentVal ? selcetedParentDepartmentVal.name : I18n.t('Parent position')}
                                </Box>
                            );
                        }}
                        input={<OutlinedInput />}
                    >
                        {this.state.parentPositionOptions.map((arrayVal, index) => (
                            <MenuItemSet
                                style={{
                                    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                    borderBottom: '1px solid #E2E8F0',
                                }}
                                className="setMenuItem"
                                key={index}
                                value={arrayVal.id.toString()}
                            >
                                {arrayVal.name}
                            </MenuItemSet>
                        ))}
                    </SelectMain>
                </StyledFormControl>
            </Box>
        )
    }

  render() {
    // Customizable Area Start
    
    return (
      <div style={{display:'flex', flexDirection: getValueBasedOnLanguage('row-reverse', "row")}}>
        <AppNavigation />
         <AddPositionWrapper className={getValueBasedOnLanguage("arabic-text", "default-text")}>
                 {/* BreadCrumbs */}
                <Box style={{ paddingRight: "20px", display: 'flex', flexDirection: this.setPageLayoutCSS("row-reverse", "row") }}>
                    <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("organizationManagement"), I18n.t("addPosition")]} navigation={this.props.navigation} pathToNavigate="OrganisationHierarchy"/>
                </Box>

                
                {/* Add Position Form  */}
                <Box className="parentBox">

                    <Card className="formBox">
                        <Typography
                            className="headerText"
                            style={{
                                alignSelf: this.setPageLayoutCSS("flex-end", "flex-start"),
                                textAlign: this.setPageLayoutCSS("right", "left"),
                                fontFamily: this.setPageLayoutCSS("Noto Sans Arabic", "Poppins")
                            }}
                        >
                            {I18n.t("addPosition")}
                        </Typography>

                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderTitleSection({
                                name: "position_name_en",
                                value: this.state.formFields.position_name_en,
                                errorKey: this.state.formErrorFields.position_name_en,
                                placeholder: I18n.t("Job Title (EN)*"),
                                errorMsg: I18n.t("positionErrorNameEnglish")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderTitleSection({
                                name: "position_name_ar",
                                value: this.state.formFields.position_name_ar,
                                errorKey: this.state.formErrorFields.position_name_ar,
                                placeholder: I18n.t("Job Title (AR)*"),
                                errorMsg: I18n.t("positionErrorNameArabic")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderDescriptionSection({
                                name: "position_desc_en",
                                value: this.state.formFields.position_desc_en,
                                errorKey: this.state.formErrorFields.position_desc_en,
                                placeholder: I18n.t("Job Description (EN)*"),
                                errorMsg: I18n.t("positionErrorDescriptionEnglish")
                            })}
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderDescriptionSection({
                                name: "position_desc_ar",
                                value: this.state.formFields.position_desc_ar,
                                errorKey: this.state.formErrorFields.position_desc_ar,
                                placeholder: I18n.t("Job Description (AR)*"),
                                errorMsg: I18n.t("positionErrorDescriptionArabic")
                            })}
                        </Grid>

                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderDepartmentSelectSection()}
                        </Grid>

                        <Grid item sm={12} xs={12} md={12}  >
                            {this.renderParentPositionSelectSection()}
                        </Grid>
                    </Card>
                </Box>


                {/* Save Button Section */}
                <Box style={{ display: "flex", justifyContent: "center", margin: "2rem" }}>
                    <AddPositionButton data-test-id="addDepartmentBtn" onClick={this.addPositionData}> {I18n.t("Save")}</AddPositionButton>
                </Box>

                {/* Success Modal */}
                <SuccessModal 
                    open={this.state.successPositionModal}
                    handleClose={this.closePositionSuccessModal}
                    msg_en={this.state.position_msg_en}
                    msg_ar={this.state.position_msg_ar}
                    data-test-id="successModal"
                />

                {/* Error Modal */}
                <ErrorModal 
                    open={this.state.errorPositionModal}
                    handleClose={this.closePositionErrorModal}
                    data-test-id="errorModal"
                />
         </AddPositionWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default AddPosition;

const AddPositionWrapper = styled(Box)({
    flex:1, 
    backgroundColor:"#F1F5FC", 
    minHeight: "calc(100vh - 100px)",
    padding:'50px 0 50px 20px',
    marginRight: getValueBasedOnLanguage("325px", 0),
    "& .parentBox": {
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        justifyContent: "center",
        padding: "1.5rem 1.5rem 1.5rem 0"
    },
    "& .formBox" : {
        display: "flex",
        gap: "1.5rem",
        borderRadius: getValueBasedOnLanguage("0 24px 24px 0", "24px 0 0 24px"),
        flexDirection: "column",
        padding: "1.5rem",
        border: "solid 1px #E2E8F0",
    },
    "& .headerText" : {
        fontSize: "20px",
        fontWeight: 600,
        marginBottom: "16px",
        color: "#1a1a1a",
    }
})

const AddPositionButton = styled(Button)({
    color: "white",
    padding: "10px 16px 10px 16px",
    fontSize: "16px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    maxWidth: "306px",
    width: "306px",
    borderRadius: "8px",
    height: "44px",
    "&:hover": {
        color: "white",
        backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
        fontSize: "18px",
        padding: "10px 16px 10px 16px",
    },
});

// Customizable Area End